import { createSlice } from '@reduxjs/toolkit';

// Define initial state
const initialState = {
  systemInfo: [],
  systemMessage: [],
};

// Create a slice
const systemSlice = createSlice({
  name: 'system',
  initialState,
  reducers: {
    // Reducer function for setting system info
    setSystemInfo(state, action) {
      state.systemInfo = action.payload;
    },
    // Reducer function for setting system messages
    setSystemMessage(state, action) {
      state.systemMessage = action.payload;
    },
  },
});

// Export reducers and action creators
export const { setSystemInfo, setSystemMessage } = systemSlice.actions;

// Export reducer function
export default systemSlice.reducer;
