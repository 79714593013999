import { createSlice } from '@reduxjs/toolkit';

// Define initial state
const initialState = {
  controlPlans: [],
  controlPlansOnCase: null,
  currentControlPlan: null,
};

// Create a slice for control plans
const controlPlansSlice = createSlice({
  name: 'controlPlans',
  initialState,
  reducers: {
    // Reducer function for setting control plans
    setControlPlans(state, action) {
      state.controlPlans = action.payload;
    },
    // Reducer function for setting the current control plan
    setCurrentControlPlan(state, action) {
      state.currentControlPlan = action.payload;
    },
    // Reducer function for setting control plans on case
    setControlPlansOnCase(state, action) {
      state.controlPlansOnCase = action.payload;
    },
  },
});

// Export reducers and action creators
export const {
  setControlPlans,
  setControlPlansOnCase,
  setCurrentControlPlan,
} = controlPlansSlice.actions;

// Export reducer functions
export default controlPlansSlice.reducer;
