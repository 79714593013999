/**
 * @module actions/common
 */
import { API_URL, POSTAL_API } from '../constants';
import axios from 'axios';
import { StringIsEmpty } from './../../utils/common';
import {
  setUserBranding,
  handleTermsOfAgreement,
  setUserFilters,
  setAgreementSchemaSettings,
  setHideModalSettings,
  setImageHash,
} from '../slices/commonSlice';

/**
 * @description Sets the branding information
 * @return {Promise} Promise
 */
export function GetBranding(customerId) {
  return dispatch => {
    return axios.get(API_URL + '/branding/' + customerId).then(res => {
      let brand = JSON.stringify(res.data);
      const emptyBrand = StringIsEmpty(brand);

      if (!emptyBrand) {
        window.localStorage.setItem('user_branding', brand);
      }

      dispatch(setUserBranding(res.data));
      return res;
    });
  };
}

/**
 * @description Get city by postal code
 * @return {Promise} Promise
 */
export function getCity(postalCode) {
  return dispatch => {
    return axios.get(POSTAL_API + `postnumre?nr=${postalCode}`);
  };
}

/**
 * @description Get terms of agreements
 * @return {Promise} Promise
 */
export function getTermsOfAgreement() {
  return dispatch => {
    let url = API_URL + '/policy/terms';
    return axios.get(url);
  };
}

/**
 * @description Get terms of agreements for users
 * @return {Promise} Promise
 */
export function getUserTermsOfAgreement() {
  return dispatch => {
    let url = API_URL + '/policy/terms/user';
    return axios.get(url);
  };
}

/**
 * @description Get terms of agreements for logged in user
 * @return {Promise} Promise
 */
export function getAcceptedUserTermsOfAgreement() {
  return dispatch => {
    let url = API_URL + '/policy/terms/user/accepted';
    return axios.get(url);
  };
}

/**
 * @description Add or update terms of agreement
 * @return {Promise} Promise
 */
export function addUpdateTermsOfAgreement(data) {
  return dispatch => {
    let url = API_URL + '/policy/terms';
    return axios.post(url, data);
  };
}

/**
 * @description Accept user terms of agreement
 * @return {Promise} Promise
 */
export function acceptTermsOfAgreement(data) {
  return dispatch => {
    let url = API_URL + '/policy/terms/user';
    return axios.post(url, data);
  };
}

/**
 * @description Recall accepted terms of agreements
 * @return {Promise} Promise
 */
export function recallAcceptedTermsOfAgreement(data) {
  return dispatch => {
    let url = API_URL + '/policy/terms/user/recall';
    return axios.post(url, data);
  };
}

/**
 * @description Handled user terms of agreement
 * @return {Promise} Promise
 */
export function handledTermsOfAgreement(value = true) {
  return dispatch => {
    dispatch(handleTermsOfAgreement(value));
  };
}

/**
 * @description Get personal data policy
 * @return {Promise} Promise
 */
export function getPersonalDataPolicy() {
  return dispatch => {
    let url = API_URL + '/policy/datapolicy';
    return axios.get(url);
  };
}

/**
 * @description Add or update personal data policy
 * @return {Promise} Promise
 */
export function addUpdatePersonalDataPolicy(data) {
  return dispatch => {
    let url = API_URL + '/policy/datapolicy';
    return axios.post(url, data);
  };
}

export function setRoadmapFilterInLocalstorage(filterStorage) {
  return dispatch => {
    //console.log('set roadmap filter');
    window.localStorage.setItem(
      'roadmapFilterStorage',
      JSON.stringify(filterStorage),
    );
  };
}

export function getRoadmapFilterFromLocalStorage() {
  return dispatch => {
    const filter = window.localStorage.getItem('roadmapFilterStorage');
    if (!filter) return {};

    var parsed = JSON.parse(filter);

    return {
      ...parsed,
      filterStartDate: parsed.filterStartDate
        ? new Date(parsed.filterStartDate)
        : null,
      filterEndDate: parsed.filterEndDate
        ? new Date(parsed.filterEndDate)
        : null,
    };
  };
}

export function setRoadmapMaterialsFilterInLocalstorage(filterStorage) {
  return dispatch => {
    //console.log('set roadmap filter');
    window.localStorage.setItem(
      'roadmapMaterialsFilterStorage',
      JSON.stringify(filterStorage),
    );
  };
}

export function getRoadmapMaterialsFilterFromLocalStorage() {
  return dispatch => {
    const filter = window.localStorage.getItem('roadmapMaterialsFilterStorage');
    if (!filter) return {};

    var parsed = JSON.parse(filter);

    return {
      ...parsed,
      filterStartDate: parsed.filterStartDate
        ? new Date(parsed.filterStartDate)
        : null,
      filterEndDate: parsed.filterEndDate
        ? new Date(parsed.filterEndDate)
        : null,
    };
  };
}

/**
 * @description Get agreement schema modal settings
 * @return {Promise} Promise
 */
export function getAgreementSchemaModalSetting() {
  return dispatch => {
    let url = API_URL + '/usersettings/ags';
    return axios.get(url).then(res => {
      dispatch(setAgreementSchemaSettings(res.data));

      return res;
    });
  };
}

/**
 * @description Update agreement schema modal setting
 * @return {Promise} Promise
 */
export function updateAgreementSchemaModalSetting(data) {
  return dispatch => {
    let url = API_URL + '/usersettings/ags';
    return axios.post(url, data).then(res => {
      dispatch(setAgreementSchemaSettings(res.data));

      return res;
    });
  };
}

/**
 * @description Get User Filters
 * @return {Promise} Promise
 */
export function getUserFilters() {
  return dispatch => {
    let url = API_URL + '/usersettings/getUserFilters';
    return axios.get(url).then(res => {
      dispatch(setUserFilters(res.data));

      return res;
    });
  };
}

/**
 * @description Update User Filters
 * @return {Promise} Promise
 */
export function updateUserFilters(data) {
  return dispatch => {
    let url = API_URL + '/usersettings/updateUserFilters';
    return axios.post(url, data).then(res => {
      dispatch(setUserFilters(data));
      return res;
    });
  };
}

/**
 * @description Get Hide Modal Settings
 * @return {Promise} Promise
 */
export function getHideModalSettings(id) {
  return dispatch => {
    let url = API_URL + '/usersettings/hideModal/' + id;
    return axios.get(url).then(res => {
      dispatch(setHideModalSettings(res.data));

      return res;
    });
  };
}

/**
 * @description Update hide modal setting
 * @return {Promise} Promise
 */
export function updateHideModalSetting(data) {
  return dispatch => {
    let url = API_URL + '/usersettings/updateHideModalSetting';
    return axios.post(url, data).then(res => {
      dispatch(setHideModalSettings(res.data));

      return res;
    });
  };
}

/**
 * Refreshes an image hash hopefully causing images using it to refresh/reload.
 * @returns void
 */
export function refreshImageHash() {
  return dispatch => {
    dispatch(setImageHash(Date.now()));
  };
}
