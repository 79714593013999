import React, { useState, useEffect, useRef } from 'react';
import { Layer, Stage } from 'react-konva';
import { v4 as uuidv4 } from 'uuid';
import { connect } from 'react-redux';
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';
import { isMobile } from 'react-device-detect';

// Actions
import { updateReferenceInfo } from '../../redux/actions/markings';

// Util
import { createMarkingObjectsFromReferences } from '../../utils/componentUtils';
import { makeWritable } from '../../utils/common';

// Components
import { Circle, Polygon } from './KonvaForms';

const KonvaView = props => {
  const { currentFile, isSaving, currentRefs } = props;
  const [imageLoaded, setImageLoaded] = useState(false);
  const [imgDimensions, setImgDimensions] = useState({
    width: 0,
    height: 0,
    naturalWidth: 0,
    naturalHeight: 0,
    r: 0,
  });
  const [markings, setMarkings] = useState([]);
  const [groups, setGroups] = useState([]);
  const [imageHash] = useState(Date.now());

  const [bindings, setBindings] = useState({});

  const imgRef = useRef(null);

  useEffect(() => {
    window.addEventListener('resize', handleImageResize);

    return () => window.removeEventListener('resize', handleImageResize);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    let tmpMarkings = [];
    let tmpGroups = [];
    let tmpBindings = {};
    if (!!currentRefs && imageLoaded) {
      currentRefs.forEach(currentRef => {
        const ref = makeWritable(currentRef);
        const result = createMarkingObjectsFromReferences(
          currentFile,
          imgDimensions,
          ref,
          uuidv4(),
          `${ref._overrideIndex}.`,
        );

        tmpMarkings = [...tmpMarkings, ...result.markings];
        tmpGroups = [...tmpGroups, ...result.groups];
        tmpBindings = { ...tmpBindings, ...result.bindings };
      });

      // console.log(result);
    }
    setMarkings(tmpMarkings);
    setGroups(tmpGroups);
    setBindings(tmpBindings);

    // eslint-disable-next-line
  }, [currentRefs, imageLoaded]);

  //////////////// Image Processing ////////////////

  const handleImageResize = () => {
    const img = imgRef.current;

    const tmpImgDimensions = {
      width: img.width,
      height: img.height,
      naturalWidth: img.naturalWidth,
      naturalHeight: img.naturalHeight,
      loaded: true,
    };

    setImgDimensions(tmpImgDimensions);
    setImageLoaded(true);
  };

  const renderImage = () => {
    let hash = imageHash;
    if (isSaving) {
      hash += new Date();
    }

    let classNames = 'imgMarker__img';
    if (imgDimensions.loaded) {
      classNames += ' absolute';
    }

    return (
      <img
        className={classNames}
        src={`${currentFile.rawFileUrl}?${hash}`}
        alt={currentFile.fileTitle}
        onLoad={handleImageResize}
        ref={imgRef}
        key={imageHash}
      />
    );
  };

  //////////////// Markings Processing ////////////////

  const renderMarking = (marking, key) => {
    if (marking.hidden) return null;
    const displayId = bindings[marking.guid]?.displayId ?? null;

    // const calcDimen = (imgLength, percent) => {
    //   return imgLength * (percent / 100);
    // };

    const calRadius = (r, viewHeight) => {
      const scaling = viewHeight / imgDimensions.naturalHeight;
      // console.log(
      //   `r: ${r} - Scaling: ${r *
      //     scaling} - Height: ${viewHeight} - ActualHeight: ${
      //     imgDimensions.naturalHeight
      //   }`,
      // );
      return r * scaling;
    };

    const selectMarking = () => {
      const binding = bindings[marking.guid];
      if (binding) {
        if (binding.refIds?.length > 0) {
          props.onMarkingSelect(binding.refIds[0]);
        }
      }
    };

    // let width = calcDimen(imgDimensions.width, marking.width);
    // let height = calcDimen(imgDimensions.height, marking.height);
    let r = calRadius(marking.r, imgDimensions.height);
    const scaling = imgDimensions.height / imgDimensions.naturalHeight;
    let strokeWidth = 6 * scaling;
    if (strokeWidth < 1) strokeWidth = 1;

    return (
      <Circle
        x={marking.x * scaling}
        y={marking.y * scaling}
        radius={r}
        stroke={'rgba(245, 66, 66, 1)'}
        strokeWidth={strokeWidth}
        outerFill={'rgba(245, 66, 66, 0.3)'}
        innerFill={'rgba(245, 66, 66, 1)'}
        onClick={selectMarking}
        key={`${key}_frag`}
        keyIndex={key}
        draggable={false}
        isSelected={false}
        text={displayId}
        clickable
      />
    );
  };

  const renderGroup = (group, index) => {
    if (group.length === 1) {
      return renderMarking(group[0], index);
    }
    if (group.length === 2) {
      return (
        <>
          {renderMarking(group[0], 'a-' + index)}
          {renderMarking(group[1], 'b-' + index)}
        </>
      );
    }

    let displayId;
    if (group.length > 0) {
      displayId = bindings[group[0].guid]?.displayId ?? null;
    }

    const scaling = imgDimensions.height / imgDimensions.naturalHeight;
    let strokeWidth = 6 * scaling;
    if (strokeWidth < 1) strokeWidth = 1;

    const coordinates = [];

    group.forEach(marking => {
      coordinates.push(marking.x * scaling);
      coordinates.push(marking.y * scaling);
    });

    const selectMarking = () => {
      // console.log(group[0], bindings[group[0].guid]);
      //   setCurrentMarking(group[0].id);
      const binding = bindings[group[0].guid];
      if (binding) {
        if (binding.refIds?.length > 0) {
          props.onMarkingSelect(binding.refIds[0]);
        }
      }
    };

    return (
      <Polygon
        key={`g-${index}`}
        points={coordinates}
        fill={'rgba(245, 66, 66, 0.3)'}
        stroke={'rgba(245, 66, 66, 1)'}
        strokeWidth={strokeWidth}
        onClick={selectMarking}
        text={displayId}
        clickable
        closed
      />
    );
  };

  //////////////// RENDER ////////////////

  const renderContainer = () => {
    if (isMobile) {
      return (
        <div className='imgMarker__container'>
          <TransformWrapper>
            <TransformComponent>
              {renderImage()}
              <Stage width={imgDimensions.width} height={imgDimensions.height}>
                <Layer>
                  {markings.map(renderMarking)}
                  {groups.map(renderGroup)}
                </Layer>
              </Stage>
            </TransformComponent>
          </TransformWrapper>
        </div>
      );
    }

    return (
      <div className='imgMarker__container'>
        {renderImage()}
        <Stage width={imgDimensions.width} height={imgDimensions.height}>
          <Layer>
            {markings.map(renderMarking)}
            {groups.map(renderGroup)}
          </Layer>
        </Stage>
      </div>
    );
  };

  return <div className='konva-container'>{renderContainer()}</div>;
};

function mapStateToProps({ markings }) {
  return {
    markings,
  };
}

export default connect(mapStateToProps, { updateReferenceInfo })(KonvaView);
