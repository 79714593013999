import { createSlice } from '@reduxjs/toolkit';

// Define initial state
const initialState = {
  data: [],
  settings: null,
  notice: null,
};

// Create a slice
const notificationsSlice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    // Reducer function for getting notifications
    setNotifications(state, action) {
      state.data = action.payload;
    },
    // Reducer function for getting notification settings or updating them
    setNotificationSettings(state, action) {
      state.settings = action.payload;
    },
    setNotice(state, action) {
      state.notice = action.payload;
    },
  },
});

// Export reducers and action creators
export const {
  setNotifications,
  setNotificationSettings,
  setNotice,
} = notificationsSlice.actions;

// Export reducer function
export default notificationsSlice.reducer;
