import React, { useState, useEffect } from 'react';
import { Button, Modal, ModalBody, ModalHeader } from 'reactstrap';
import { connect } from 'react-redux';
import i18next from '../../i18n';

// Actions
import {
  togglePausedModal,
  handledPausedModal,
  setUserPermissions,
} from '../../redux/actions/auth';
import { getCustomerStatus } from '../../redux/actions/customers';

// Utils
import { CUSTOMER_STATUS } from '../../redux/constants';
import { isDevEnvironment } from '../../utils/routeHandling';

// Components
import { Content, Header, Sidebar } from './';
import TermsOfAgreementModal from '../Custom/TermsOfAgreementModal';
import Typography from '../Typography';

const MainLayout = props => {
  const { children } = props;

  const [originalPermissionSet, setOriginalPermissionSet] = useState(null);
  const [sideBarOpen, setSideBarOpen] = useState(false);
  const [currentRoute, setCurrentRoute] = useState('/');

  // useEffect(() => {
  //   checkBreakpoint(props.breakpoint);
  // }, [props.breakpoint]);

  useEffect(() => {
    // checkBreakpoint(props.breakpoint);
    setOriginalPermissionSet(props.auth?.user?.user?.permission);
    checkCustomerStatus();
    // eslint-disable-next-line
  }, []);

  //////////////////////////////////////////////////////////////////////////////////////////////

  const checkCustomerStatus = () => {
    const { customerStatus } = props.customers;
    props.getCustomerStatus().then(res => {
      if (
        customerStatus === CUSTOMER_STATUS.Active &&
        res === CUSTOMER_STATUS.Paused
      ) {
        props.setUserPermissions({
          projectCopy: false,

          projectCreate: false,

          projectDelete: false,
        });
      } else if (
        customerStatus === CUSTOMER_STATUS.Paused &&
        res === CUSTOMER_STATUS.Active
      ) {
        props.setUserPermissions(originalPermissionSet);
        props.handledPausedModal(false);
      }
    });

    if (!isDevEnvironment()) {
      // setTimeout(() => {
      //   this.checkCustomerStatus();
      // }, 10000); // 10000 = 10 seconds
      setTimeout(() => {
        checkCustomerStatus();
      }, 60000); // 60000 = 1 minutes
      // setTimeout(() => {
      //   this.checkCustomerStatus();
      // }, 600000); // 600000 = 10 minutes
    }
  };

  // const checkBreakpoint = breakpoint => {
  //   switch (breakpoint) {
  //     case 'xs':
  //     case 'sm':
  //     case 'md':
  //       return setSideBarOpen(false);

  //     case 'lg':
  //     case 'xl':
  //     default:
  //       return setSideBarOpen(true);
  //   }
  // };

  // close sidebar if sidebar is open and screen size is less than `md`
  const handleContentClick = event => {
    if (
      sideBarOpen &&
      (props.breakpoint === 'xs' ||
        props.breakpoint === 'sm' ||
        props.breakpoint === 'md')
    ) {
      setSideBarOpen(false);
    }
  };

  //////////////////////////////////////////////////////////////////////////////////////////////

  const renderPausedModal = () => {
    const { auth } = props;
    if (!auth.showPausedModal) return null;

    const modalToggle = () => {
      props.togglePausedModal(!auth.showPausedModal);
      props.handledPausedModal(true);
    };

    return (
      <Modal
        isOpen={auth.showPausedModal && !auth.handledPausedModal}
        toggle={modalToggle}
        fade={false}
        size='lg'
        centered
        backdrop='static'
      >
        <ModalHeader toggle={modalToggle}>{i18next.t(1492)}</ModalHeader>
        <ModalBody>
          <div className='terms__wrapper'>
            <div className='terms__container'>
              <div className='term__item'>
                <Typography type='h2'>{i18next.t(1493)}</Typography>
                <div className='content sm'>{i18next.t(1491)}</div>
              </div>
            </div>
            <Button
              onClick={modalToggle}
              color='btnPrimary'
              className='float-right margin-top-10px'
            >
              {i18next.t(1494)}
            </Button>
          </div>
        </ModalBody>
      </Modal>
    );
  };

  //////////////////////////////////////////////////////////////////////////////////////////////
  return (
    <>
      <div className='custom-app bg-light'>
        <Header history={props.history} setRoute={setCurrentRoute} />
        <Content fluid onClick={handleContentClick}>
          <Sidebar
            history={props.history}
            sideBarOpen={sideBarOpen}
            toggle={setSideBarOpen}
            currentRoute={currentRoute}
            setRoute={setCurrentRoute}
          />
          <div id='main-content-wrapper' className='main-content-wrapper'>
            <div id='main-content-id' className='main-content'>
              {children}
            </div>
          </div>
        </Content>
      </div>
      {renderPausedModal()}
      <TermsOfAgreementModal {...props} />
    </>
  );
};

function mapStateToProps({ auth, customers }) {
  return {
    auth,
    customers,
  };
}

export default connect(mapStateToProps, {
  togglePausedModal,
  handledPausedModal,
  getCustomerStatus,
  setUserPermissions,
})(MainLayout);
