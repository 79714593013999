import { createSlice } from '@reduxjs/toolkit';

// Define initial state
const initialState = {
  products: [],
  materials: [],
};

// Create a slice
const productsSlice = createSlice({
  name: 'products',
  initialState,
  reducers: {
    // Reducer function for setting products
    setProducts(state, action) {
      state.products = action.payload;
    },
    // Reducer function for setting case materials
    setCaseMaterials(state, action) {
      state.materials = action.payload;
    },
  },
});

// Export reducers and action creators
export const { setProducts, setCaseMaterials } = productsSlice.actions;

// Export reducer function
export default productsSlice.reducer;
