/**
 * @module actions/error
 */
import axios from 'axios';
import { API_URL } from '../constants';

/**
 * @description Send error log
 * @return {Promise} Axios promise
 */
export function sendErrorLog(data) {
  let url = API_URL + `/error`;
  return dispatch => {
    return axios.post(url, data);
  };
}
