import { createSlice } from '@reduxjs/toolkit';

// Define initial state
const initialState = {
  employees: [],
};

// Create a slice
const employeesSlice = createSlice({
  name: 'employees',
  initialState,
  reducers: {
    // Reducer function for setting employees
    setEmployees(state, action) {
      state.employees = action.payload;
    },
  },
});

// Export reducer and action creator
export const { setEmployees } = employeesSlice.actions;
export default employeesSlice.reducer;
