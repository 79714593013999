import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import { Spinner } from 'reactstrap';
import i18next from '../../i18n';
import { languages } from '../../utils/translation/langauges';

// Actions
import { getLanguages, setLanguage } from '../../redux/actions/language';
import {
  logout,
  loginOutAs,
  getCompanyBindings,
} from '../../redux/actions/auth';
import { getCustomerStatus } from '../../redux/actions/customers';

import { setCurrentUser, setLoginAs } from '../../redux/slices/authSlice';

import { getSystemMessage } from '../../redux/actions/systemInfo';
import { getFeatures } from '../../redux/actions/features';
import { getPlatformBrandingUser } from '../../redux/actions/branding';

// Utils
import {
  setAuthorizationToken,
  setDeviceToken,
} from '../../utils/setAuthorizationToken';

const LoadingBoundary = props => {
  const { setLanguage } = props;

  const [loadingLanguage, setLoadingLanguage] = useState(true);

  useEffect(() => {
    setupLanguage();
    setupAxiosInterceptor();
    // eslint-disable-next-line
  }, []);

  const setupAxiosInterceptor = async () => {
    // Axios response interceptor - Logout on 401
    axios.interceptors.response.use(
      function(response) {
        return response;
      },
      function(error) {
        if (error) {
          if (error.response) {
            if (
              error.response.status === 401 &&
              window.location.pathname !== '/approval' &&
              window.location.pathname !== '/verification'
            ) {
              const isLoginAs = !!props.auth.loginAsUser;

              if (isLoginAs) {
                props.loginOutAs();
              } else {
                // Because of problematic implementation (auth.user)
                setTimeout(() => props.logout(), 0);
              }

              // Because of problematic implementation (auth.user)
              setTimeout(() => {
                if (window.location.pathname !== '/login') {
                  props.history.push('/');
                  window.location.reload(true);
                }
              }, 200);
              // if (window.location.pathname !== '/login') {
              //   history.push('/');
              //   window.location.reload(true);
              // }
            }
          } else if (error.response.status === 503) {
            // Redirect to base url if in maintenance mode
            console.log('redirect', process.env.PUBLIC_URL + '/');
            window.location.href = process.env.PUBLIC_URL + '/';
          }

          return Promise.reject(error);
        }
      },
    );

    try {
      // Pre-check of login-status - Tokens and user data
      const usertoken = localStorage.getItem('user_token');
      const userdata = localStorage.getItem('user_data');
      const loggedInUser = localStorage.getItem('loggedInAs');
      if (usertoken && userdata) {
        if (loggedInUser) {
          const j = JSON.parse(loggedInUser);
          setAuthorizationToken(j.loginData.token);
          setDeviceToken(j.loginData.deviceToken);
          props.setLoginAs(loggedInUser);
        } else {
          setAuthorizationToken(usertoken);
          setDeviceToken(JSON.parse(userdata).deviceToken);
        }
        const data = JSON.parse(userdata);
        props.setCurrentUser(data);
        props.getSystemMessage();
        props.getFeatures();
        props.getCustomerStatus();
        props.getCompanyBindings({ customer: true, user: true });
        if (data.accessLevel !== 1) {
          props.getPlatformBrandingUser();
        }
      }
    } catch (error) {
      console.log('An error occured', error);
      props.logout();
    }
  };

  const setupLanguage = async () => {
    // Get Langauge Index for translation
    await props.getLanguages().then(res => {
      languages.forEach(x => {
        let langObj = {};
        let lang = x.lang;
        res.forEach(element => {
          langObj[element.key] = element[lang];
        });

        i18next.addResourceBundle(x.lang, 'translation', langObj);
      });
    });
    setLoadingLanguage(false);
    const lang = localStorage.getItem('user_lang');
    if (lang) {
      i18next.changeLanguage(lang);
      setLanguage(lang);
    } else {
      localStorage.setItem('user_lang', 'dk');
    }
  };

  if (loadingLanguage) {
    return (
      <div width='100%' height='100%'>
        <Spinner className='preload-loading' />
      </div>
    );
  }

  return props.children;
};

function mapStateToProps({ language, auth }) {
  return {
    language,
    auth,
  };
}

export default connect(mapStateToProps, {
  getLanguages,
  setLanguage,
  setCurrentUser,
  logout,
  loginOutAs,
  setLoginAs,
  getCustomerStatus,
  getCompanyBindings,
  getSystemMessage,
  getFeatures,
  getPlatformBrandingUser,
})(LoadingBoundary);
