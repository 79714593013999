import React from 'react';
import Select, { components } from 'react-select';
import { Input } from 'reactstrap';
import i18next from '../../i18n';
//import Checkbox from './Forms/Checkbox';

/**
 * Main component for controlling the react-select component. Allows us to perform global changes.
 * Works like normal react-select component.
 * Documentation: https://react-select.com/
 * Styles documentation: https://react-select.com/styles#styles
 * @param {*} props - All properties on component are passed to the react-select component.
 */

const ReactSelect = props => {
  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      backgroundColor: state.isDisabled ? '#e9ecef' : provided.backgroundColor,
      borderColor: state.isDisabled ? '#ced4da' : provided.borderColor,
    }),
    singleValue: (provided, state) => ({
      ...provided,
      color: state.isDisabled ? '#495057' : provided.color,
    }),
    menu: (provided, state) => ({
      ...provided,
      zIndex: 999,
    }),
  };

  const placeholder = props.placeholder
    ? props.placeholder
    : i18next.t(1468) + '...';
  const noOptionsMessage = props.noOptionsMessage
    ? props.noOptionsMessage
    : () => 'Tom';

  const formatOptionLabel = (item, index) => (
    <div style={{ display: 'flex' }}>
      <Input
        type='checkbox'
        checked={
          index.selectValue.find(x => x.value === item.value) ? true : false
        }
        onChange={() => {}}
        className='react-select-checkbox margin-right-5px'
      />
      <div>{item.label}</div>
    </div>
  );

  const MoreSelectedBadge = ({ items }) => {
    const title = items.join(', ');
    const length = items.length;
    const label = `${length}`;

    return (
      <div className='react-select-selected-options-count' title={title}>
        {label}
      </div>
    );
  };

  const MultiValue = ({ index, getValue, ...props }) => {
    const maxToShow = 0;
    const overflow = getValue()
      .slice(maxToShow)
      .map(x => x.label);

    return index < maxToShow ? (
      <components.MultiValue {...props} />
    ) : index === maxToShow ? (
      <div>
        {placeholder} <MoreSelectedBadge items={overflow} />
      </div>
    ) : null;
  };

  return (
    <React.Fragment>
      <Select
        {...props}
        placeholder={placeholder}
        noOptionsMessage={noOptionsMessage}
        styles={customStyles}
        menuShouldScrollIntoView={true}
        menuPlacement='auto'
        maxMenuHeight={500}
        isOptionSelected={false}
        hideSelectedOptions={false}
        formatOptionLabel={formatOptionLabel}
        components={{ MultiValue }}
        className={
          'custom-react-select-filter ' +
          (props.className ? props.className : '')
        }
      />
      {props.extra}
      {props.error ? (
        <p style={{ color: 'red' }}>
          {props.error
            ? props.errorText
              ? props.errorText
              : i18next.t(1062)
            : null}
        </p>
      ) : null}
    </React.Fragment>
  );
};

export default ReactSelect;
