import { createBrowserHistory } from 'history';
import { configureStore } from '@reduxjs/toolkit';
import { connectRouter, routerMiddleware } from 'connected-react-router';
//import { listenerMiddleware } from './listenerMiddleware';

// Slices
import auth from './slices/authSlice';
import branding from './slices/brandingSlice';
import invitation from './slices/invitationSlice';
import caseDescriptions from './slices/caseDescriptionSlice';
import controlPlan from './slices/controlPlanSlice';
import common from './slices/commonSlice';
import customers from './slices/customerSlice';
import customerGroups from './slices/customerGroupsSlice';
import employees from './slices/employeeSlice';
import language from './slices/languageSlice';
import insurances from './slices/insuranceSlice';
import systemInfo from './slices/systemInfoSlice';
import features from './slices/featureSlice';
import notifications from './slices/notificationSlice';
import documents from './slices/documentsSlice';
import products from './slices/productSlice';
import cases from './slices/caseSlice';
import markings from './slices/markingsSlice';
import roadmap from './slices/roadmapSlice';
import schemas from './slices/schemasSlice';
import schemabuilder from './slices/schemabuilderSlice';
import team from './slices/teamSlice';
export const history = createBrowserHistory();

export default function setupStore() {
  // Automatically adds the thunk middleware and the Redux DevTools extension
  const store = configureStore({
    // Automatically calls `combineReducers`
    reducer: {
      router: connectRouter(history),
      auth,
      common,
      customers,
      customerGroups,
      employees,
      insurances,
      cases,
      branding,
      systemInfo,
      features,
      documents,
      roadmap,
      invitation,
      notifications,
      schemabuilder,
      products,
      schemas,
      controlPlan,
      language,
      caseDescriptions,
      markings,
      team,
    },
    middleware: getDefaultMiddleware => [
      ...getDefaultMiddleware(),
      routerMiddleware(history),
    ],
  });

  return store;
}
