/**
 * @module actions/markings
 */
import axios from 'axios';
import { API_URL } from '../constants';
import {
  setAllImageReferences,
  setCurrentImageReferences,
  setReferenceInfo,
  setIsSaveAllowed,
} from '../slices/markingsSlice';

/**
 * Fetch the markings for a single schema on a case. Requires KsId or SaId.
 * @param {Object} data
 * @returns
 */
export function getMarkingReferences(data) {
  return dispatch => {
    let url = API_URL + `/markings/fetchMarkings`;

    return axios.post(url, data).then(res => {
      return res.data;
    });
  };
}

/**
 * Fetch the markings for a single schema on a case. Requires KsId or SaId.
 * @param {Object} data
 * @returns
 */
export function getAllMarkingReferences(data) {
  return dispatch => {
    let url = API_URL + `/markings/fetchMarkings/all`;

    return axios.post(url, data).then(res => {
      dispatch(setAllImageReferences(res.data));
      return res.data;
    });
  };
}

/**
 * @description Save img markings
 * @param {Object} data
 * @param {Array} markings
 */
export function saveMarkings(data) {
  return dispatch => {
    let url = API_URL + `/markings/updateMarkings`;

    return axios.post(url, data).then(res => {
      return res.data;
    });
  };
}

export function saveReferences(data, allRefs) {
  return dispatch => {
    let url = API_URL + `/markings/updateReferences/`;

    return axios.post(url, data).then(res => {
      dispatch(setCurrentImageReferences(res.data));

      let updatedRefs = [...allRefs];
      const curIndex = updatedRefs.findIndex(
        x => x.controlId === res.data.controlId,
      );
      if (curIndex > -1) {
        updatedRefs[curIndex] = res.data;
      } else {
        updatedRefs.push(res.data);
      }

      dispatch(setAllImageReferences(updatedRefs));
      return res.data;
    });
  };
}

export function setCurrentReferences(data) {
  return dispatch => {
    dispatch(setCurrentImageReferences(data));
  };
}

export function updateReferenceInfo(data) {
  return dispatch => {
    dispatch(setReferenceInfo(data));
  };
}

export function setSaveAllowedState(val) {
  return dispatch => {
    dispatch(setIsSaveAllowed(val));
  };
}

/**
 * @description Delete area markings
 * @param {Object} data
 * @param {Array} markings
 */
export function deleteAreaImageAndReferences(id) {
  return dispatch => {
    let url = API_URL + `/markings/deleteAreaMarking/${id}`;

    return axios.post(url).then(res => {
      return res.data;
    });
  };
}

/**
 * @description Delete img markings
 * @param {Object} data
 * @param {Array} markings
 */
export function deleteImageAndReferences(id) {
  return dispatch => {
    let url = API_URL + `/markings/deleteImageMarking/${id}`;

    return axios.post(url).then(res => {
      return res.data;
    });
  };
}
