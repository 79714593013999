import React, { useState, useEffect, useRef } from 'react';
import i18next from '../../i18n';
import { Button, Spinner } from 'reactstrap';
import { Layer, Stage } from 'react-konva';
import { v4 as uuidv4 } from 'uuid';
import { connect } from 'react-redux';
import { Circle, Polygon } from './KonvaForms';
import { IconLock, IconCheckmarkAlt } from '../../assets/icons_v2';

// Actions
import {
  updateReferenceInfo,
  setSaveAllowedState,
} from '../../redux/actions/markings';

// Util
import { getMarkingSizeByLadder } from '../../utils/imageMarkingUtils';
import { MARKING_TYPE } from '../../redux/constants';
import {
  createMarkingObjectsFromReferences,
  createUploadMarkingReferenceRequestObj,
} from '../../utils/componentUtils';
import toast from '../../utils/toast';
import { makeWritable } from '../../utils/common';

// Components
import ConfirmModal from '../Custom/ConfirmModal';
import Typography from '../Typography';

const KonvaEditor = props => {
  const { currentFile, isSaving, control, disabled, schema, prefixId } = props;
  const { currentRefs } = props.markings;
  const initialGuid = uuidv4();

  const [imageLoaded, setImageLoaded] = useState(false);
  const [imgDimensions, setImgDimensions] = useState({
    width: 0,
    height: 0,
    naturalWidth: 0,
    naturalHeight: 0,
    r: 0,
  });
  const [markings, setMarkings] = useState([]);
  const [groups, setGroups] = useState([]);
  const [currentMarking, setCurrentMarking] = useState(null);
  const [currentGroupId, setCurrentGroupId] = useState(null);
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);
  const [imageHash] = useState(Date.now());
  const [saveCallback, setSaveCallback] = useState(false);

  const [imgGuids, setImgGuids] = useState({});
  const [guids, setGuids] = useState([]);
  const [currentGuid, setCurrentGuid] = useState(initialGuid);
  const [bindings, setBindings] = useState({
    [initialGuid]: {
      refIds: [],
      index: 1,
      displayId: `${prefixId + 1}.1`,
    },
  });

  const imgRef = useRef(null);

  // markings: [
  //   {
  //     id: 1,
  //     groupId: 1,
  //     x: 40,
  //     y: 40,
  //     width: 10,
  //     height: 10,
  //     shape: MARKING_TYPE.Square,
  //     r: 1 // Radius for circle
  //     filled: false,
  //   }
  // ],

  useEffect(() => {
    window.addEventListener('resize', handleImageResize);

    return () => window.removeEventListener('resize', handleImageResize);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!currentRefs && control.files.length > 0) {
      addRemoveImageRef(control.files[0].id);
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!!currentRefs && imageLoaded && !isSaving) {
      const refs = makeWritable(currentRefs);
      const result = createMarkingObjectsFromReferences(
        props.currentFile,
        imgDimensions,
        refs,
        initialGuid,
        `${prefixId + 1}.`,
      );

      setMarkings(result.markings);
      setGroups(result.groups);
      setImgGuids(result.imgGuids);
      setGuids(result.guids);
      setBindings(result.bindings);
      setCurrentMarking(result.currentMarking);
      setCurrentGroupId(result.currentGroupId);
      setCurrentGuid(result.currentGuid);
      setShowConfirmDelete(false);

      selectFirstUnUsedImage(
        result.currentGuid,
        result.imgGuids,
        result.bindings,
      );
    }
    // eslint-disable-next-line
  }, [currentRefs, imageLoaded, isSaving]);

  useEffect(() => {
    const tmpBindings = createUploadMarkingReferenceRequestObj(
      currentFile.id,
      control.id,
      markings,
      bindings,
    );

    const tmpMarkings = {
      fileId: currentFile.id,
      schemaId: schema.id,
      controlId: control.id,
      markings,
      groups,
    };

    props.updateReferenceInfo({
      bindings: tmpBindings,
      markings: tmpMarkings,
    });

    refreshSaveState();

    if (saveCallback) {
      setSaveCallback(false);
      props.onSaveMarkings({
        bindings: tmpBindings,
        markings: tmpMarkings,
      });
    }

    // eslint-disable-next-line
  }, [bindings, markings]);

  const refreshSaveState = () => {
    let val = false;

    for (let i = 0; i < control.files.length; i++) {
      const img = control.files[i];
      val = !!imgGuids[img.id];
      if (!val) {
        break;
      }
    }

    props.setSaveAllowedState(val);
  };

  const selectFirstUnUsedImage = (guid, guids, binds) => {
    let updatedBindings = { ...binds };
    let updatedGuids = { ...guids };
    let updatedRefs = updatedBindings[guid].refIds;

    for (let i = 0; i < control.files.length; i++) {
      const img = control.files[i];
      const id = img.id;

      if (!guids[id]) {
        updatedRefs.push(id);
        if (!updatedGuids[id]) {
          updatedGuids[id] = guid;
        }
        break;
      }
    }

    updatedBindings[guid].refIds = updatedRefs;
    setBindings(updatedBindings);
    setImgGuids(updatedGuids);
  };

  const toggleConfirmDeleteModal = () => {
    setShowConfirmDelete(!showConfirmDelete);
  };

  const handleOnSave = () => {
    let valid = true;
    // check for missing bindings between markings and image ids
    const keys = Object.keys(bindings);
    for (let i = 0; i < keys.length; i++) {
      const binding = bindings[keys[i]];

      valid = binding.refIds.length > 0;
      if (!valid) break;
    }

    //
    if (valid) {
      setSaveCallback(true);
      connectGroup();
    } else {
      toast.warning(i18next.t(21274));
    }
  };

  //////////////// Marking methods ////////////////

  const isCurrentMarking = marking => {
    if (currentMarking === null) return false;
    return currentMarking === marking.id;
  };

  const getMarkingObj = (shape = MARKING_TYPE.Circle, currentGroupId) => {
    const radius = getMarkingSizeByLadder(props.currentFile);
    return {
      id: '_' + Math.random() * 1000, // Random id for handling
      groupId: currentGroupId ? currentGroupId : '_' + Math.random() * 1000,
      x: 20,
      y: 20,
      width: 20,
      height: 20,
      shape,
      r: radius,
      dimensions: imgDimensions,
      filled: false,
      hidden: false,
      guid: currentGuid,
      index: guids.length + 1,
    };
  };

  const onClickImage = e => {
    if (disabled) return;
    var x = e.evt.layerX; //x position within the element.
    var y = e.evt.layerY; //y position within the element.
    // console.log({ x, y });

    addMarking(MARKING_TYPE.Circle, { x, y });
  };

  const connectGroup = () => {
    if (!currentGroupId) return;

    let updatedGroups = groups.slice();
    const group = markings.filter(x => x.groupId === currentGroupId);

    const updatedMarkings = markings.map(x => {
      return {
        ...x,
        hidden: x.groupId === currentGroupId || x.hidden,
      };
    });

    if (group?.length > 0) {
      updatedGroups.push(group);
    }

    let updateGuid = uuidv4();
    let updatedBindings = {
      ...bindings,
      [updateGuid]: {
        refIds: [],
        index: guids.length + 2,
        isGroup: false,
        displayId: `${prefixId + 1}.${guids.length + 2}`,
      },
    };

    updatedBindings[currentGuid].isGroup = true;

    setCurrentMarking(null);
    setCurrentGroupId(null);
    setGroups(updatedGroups);
    setMarkings(updatedMarkings);
    setGuids([...guids, currentGuid]);
    setCurrentGuid(updateGuid);
    setBindings(updatedBindings);
  };

  const addMarking = (shape = MARKING_TYPE.Circle, pos) => {
    let newMarking = getMarkingObj(shape, currentGroupId);

    newMarking.x = imgDimensions.width / 2;
    newMarking.y = imgDimensions.height / 2;

    if (pos) {
      newMarking.x = pos.x;
      newMarking.y = pos.y;
    }

    if (shape === MARKING_TYPE.Circle) {
      const scaling = imgDimensions.height / imgDimensions.naturalHeight;

      newMarking.x /= scaling;
      newMarking.y /= scaling;
    }

    const tmpMarkings = [...markings, newMarking];
    const tmpCurrentMarking = newMarking.id;

    setMarkings(tmpMarkings);
    setCurrentMarking(tmpCurrentMarking);

    if (!currentGroupId) {
      setCurrentGroupId(newMarking.groupId);
    }
  };

  const removeMarking = () => {
    if (markings.length === 0) return;

    // Create update variables
    let updatedMarkings = makeWritable(markings.slice());
    let updatedGroups = makeWritable(groups.slice());
    let updatedMarking = currentMarking;
    let updatedGuids = [...guids];
    let prevGuid = currentGuid;
    let updatedBindings = makeWritable(bindings);
    let updatedImgGuids = makeWritable(imgGuids);

    // States we can have:
    // 1. Only markings, no polygons (groups)
    // 2. No markings, only polygons (groups)
    // 3. Markings and polygons (groups)

    // console.log('remove-state - ', {
    //   '1': !!updatedMarking && updatedGroups.length === 0,
    //   '2': !updatedMarking && updatedGroups.length > 0,
    //   '3': !!updatedMarking && updatedGroups.length > 0,
    // });

    // 1. Only markings, no polygons (groups)
    if (!!updatedMarking && updatedGroups.length === 0) {
      if (updatedMarkings.length > 0) {
        updatedMarkings = updatedMarkings.slice(0, updatedMarkings.length - 1);
        updatedMarking = updatedMarkings[updatedMarkings.length - 1];
      } else {
        updatedMarking = null;
      }
    }
    // 2. No markings, only polygons (groups)
    else if (!updatedMarking && updatedGroups.length > 0) {
      // Find marking to determine and find inner state
      updatedMarking = updatedMarkings[updatedMarkings.length - 1];

      // update markings
      updatedMarkings = updatedMarkings.map(x => {
        if (x.hidden && x.groupId === updatedMarking.groupId) {
          x.hidden = false;
        }
        return x;
      });

      const activeGroupIndex = updatedGroups.findIndex(
        x => !!x.find(y => y.groupId === updatedMarking.groupId),
      );
      updatedGroups = updatedGroups.filter((x, i) => i !== activeGroupIndex);

      // Update binding information
      prevGuid = guids[guids.length - 1];
      updatedGuids.pop();
      delete updatedBindings[currentGuid];
      updatedBindings[prevGuid].isGroup = false;

      Object.keys(updatedImgGuids).forEach(x => {
        if (updatedImgGuids[x] === currentGuid) {
          delete updatedImgGuids[x];
        }
      });
    }
    // 3. Markings and polygons (groups)
    else if (!!updatedMarking && updatedGroups.length > 0) {
      updatedMarkings = updatedMarkings.slice(0, updatedMarkings.length - 1);
      updatedMarking = updatedMarkings[updatedMarkings.length - 1];

      if (updatedMarking.hidden) {
        updatedMarking = null;
      }
    }

    // Update variable states
    setMarkings(updatedMarkings);
    setGroups(updatedGroups);
    setCurrentMarking(updatedMarking ? updatedMarking.id : null);
    setCurrentGroupId(updatedMarking ? updatedMarking.groupId : null);
    setShowConfirmDelete(false);
    setCurrentGuid(prevGuid);
    setGuids(updatedGuids);
    setBindings(updatedBindings);
    setImgGuids(updatedImgGuids);
  };

  //////////////// Image Processing ////////////////

  const handleImageResize = () => {
    const img = imgRef.current;

    const tmpImgDimensions = {
      width: img.width,
      height: img.height,
      naturalWidth: img.naturalWidth,
      naturalHeight: img.naturalHeight,
      loaded: true,
    };

    setImgDimensions(tmpImgDimensions);
    setImageLoaded(true);
  };

  const renderImage = () => {
    let hash = imageHash;
    if (isSaving) {
      hash += new Date();
    }

    let classNames = 'imgMarker__img';
    if (imgDimensions.loaded) {
      classNames += ' absolute';
    }

    return (
      <img
        className={classNames}
        src={`${currentFile.rawFileUrl}?${hash}`}
        alt={currentFile.fileTitle}
        onLoad={handleImageResize}
        ref={imgRef}
        key={imageHash}
      />
    );
  };

  const addRemoveImageRef = id => {
    // Update bindings
    let updatedBindings = makeWritable(bindings);
    let updatedGuids = makeWritable(imgGuids);

    let updatedRefs = updatedBindings[currentGuid].refIds;

    // console.log(id, currentGuid, updatedBindings, updatedGuids);

    if (updatedRefs.find(x => x === id)) {
      updatedRefs = updatedRefs.filter(x => x !== id);

      if (updatedGuids[id] === currentGuid) {
        delete updatedGuids[id];
      }
    } else {
      updatedRefs.push(id);

      if (!updatedGuids[id]) {
        updatedGuids[id] = currentGuid;
      }
    }

    updatedBindings[currentGuid].refIds = updatedRefs;

    // State methods
    setBindings(updatedBindings);
    setImgGuids(updatedGuids);
  };

  //////////////// Markings Processing ////////////////

  const renderMarking = (marking, key) => {
    if (marking.hidden) return null;
    const isCurrent = isCurrentMarking(marking);
    const displayId = bindings[marking.guid]?.displayId ?? null;

    const calcCoord = (a, b, pos) => {
      return (a / b) * pos;
    };
    // const calcDimen = (imgLength, percent) => {
    //   return imgLength * (percent / 100);
    // };
    const calcDimen2 = (newLength, originalLength) => {
      return (100 * newLength) / originalLength;
    };
    const calRadius = (r, viewHeight) => {
      const scaling = viewHeight / imgDimensions.naturalHeight;
      // console.log(
      //   `r: ${r} - Scaling: ${r *
      //     scaling} - Height: ${viewHeight} - ActualHeight: ${
      //     imgDimensions.naturalHeight
      //   }`,
      // );
      return r * scaling;
    };

    const selectMarking = () => {
      setCurrentMarking(marking.id);
    };

    const updateXY = e => {
      let updatedMarkings = markings.slice();

      const index = updatedMarkings.findIndex(x => x.id === marking.id);
      updatedMarkings[index] = {
        ...updatedMarkings[index],
        x: calcCoord(
          imgDimensions.naturalWidth,
          imgDimensions.width,
          e.target.x(),
        ),
      };

      updatedMarkings[index] = {
        ...updatedMarkings[index],
        y: calcCoord(
          imgDimensions.naturalHeight,
          imgDimensions.height,
          e.target.y(),
        ),
      };

      setMarkings(updatedMarkings);
    };

    const onTransform = e => {
      let updatedMarkings = markings.slice();
      const index = updatedMarkings.findIndex(x => x.id === marking.id);

      updatedMarkings[index] = {
        ...updatedMarkings[index],
        x: calcCoord(imgDimensions.naturalWidth, imgDimensions.width, e.x),
      };

      updatedMarkings[index] = {
        ...updatedMarkings[index],
        y: calcCoord(imgDimensions.naturalHeight, imgDimensions.height, e.y),
      };

      if (e.type === MARKING_TYPE.Square) {
        updatedMarkings[index] = {
          ...updatedMarkings[index],
          width: calcDimen2(e.width, imgDimensions.width),
          height: calcDimen2(e.height, imgDimensions.height),
        };
      } else if (e.type === MARKING_TYPE.Circle) {
        // console.log(e, updatedMarkings[index].r);
        updatedMarkings[index] = {
          ...updatedMarkings[index],
          r: e.r,
        };
      }

      setMarkings(updatedMarkings);
    };

    // let width = calcDimen(imgDimensions.width, marking.width);
    // let height = calcDimen(imgDimensions.height, marking.height);
    let r = calRadius(marking.r, imgDimensions.height);
    const scaling = imgDimensions.height / imgDimensions.naturalHeight;
    let strokeWidth = 6 * scaling;
    if (strokeWidth < 1) strokeWidth = 1;

    return (
      <Circle
        x={marking.x * scaling}
        y={marking.y * scaling}
        radius={r}
        stroke={'rgba(245, 66, 66, 1)'}
        strokeWidth={strokeWidth}
        outerFill={'rgba(245, 66, 66, 0.3)'}
        innerFill={'rgba(245, 66, 66, 1)'}
        onClick={selectMarking}
        key={`${key}_frag`}
        keyIndex={key}
        draggable={!disabled}
        onDragEnd={updateXY}
        isSelected={isCurrent}
        onChange={onTransform}
        text={displayId}
      />
    );
  };

  const renderGroup = (group, index) => {
    if (group.length === 1) {
      return renderMarking(group[0], index);
    }
    if (group.length === 2) {
      return (
        <>
          {renderMarking(group[0], 'a-' + index)}
          {renderMarking(group[1], 'b-' + index)}
        </>
      );
    }

    let displayId;
    if (group.length > 0) {
      displayId = bindings[group[0].guid]?.displayId ?? null;
    }

    const scaling = imgDimensions.height / imgDimensions.naturalHeight;
    let strokeWidth = 6 * scaling;
    if (strokeWidth < 1) strokeWidth = 1;

    const coordinates = [];

    group.forEach(marking => {
      coordinates.push(marking.x * scaling);
      coordinates.push(marking.y * scaling);
    });

    const selectMarking = () => {
      console.log(group);
      //   setCurrentMarking(group[0].id);
    };

    return (
      <Polygon
        key={`g-${index}`}
        points={coordinates}
        fill={'rgba(245, 66, 66, 0.3)'}
        stroke={'rgba(245, 66, 66, 1)'}
        strokeWidth={strokeWidth}
        onClick={selectMarking}
        text={displayId}
        closed
      />
    );
  };

  //////////////// RENDER ////////////////

  const renderContainer = () => {
    return (
      <div className='imgMarker__container'>
        {renderImage()}
        <Stage
          width={imgDimensions.width}
          height={imgDimensions.height}
          onClick={onClickImage}
        >
          <Layer>
            {markings.map(renderMarking)}
            {groups.map(renderGroup)}
          </Layer>
        </Stage>
      </div>
    );
  };

  const renderMiniGallery = () => {
    const renderThumb = (img, index) => {
      const found = imgGuids[img.id];

      let displayId;
      let displayIdClass = '';
      let active = true;
      let icon;
      if (!!found) {
        displayId = bindings[found]?.displayId ?? null;
        active = found === currentGuid && !disabled;

        if (displayId?.trim().length > 0) {
          displayIdClass = ' did';
        }

        if (active) {
          icon = <IconCheckmarkAlt />;
          displayId = null;
        } else if (!active && displayId) {
          icon = <IconLock />;
        }
      }

      const action = active ? () => addRemoveImageRef(img.id) : null;

      return (
        <div
          key={index}
          className={'thumb' + (active ? ' active' : '') + displayIdClass}
          onClick={action}
        >
          <img className='thumb-img' src={img.fileUrl} alt='reference' />
          {!active && displayId && (
            <div className='display-id'>{displayId}</div>
          )}
          {icon && <div className='icon'>{icon}</div>}
        </div>
      );
    };

    return (
      <div className='mini-gallery editor'>
        <Typography>{i18next.t(21261)}</Typography>
        <div className='items'>{control.files.map(renderThumb)}</div>
      </div>
    );
  };

  const renderActions = () => {
    const mlen = markings?.filter(x => !x.hidden);
    return (
      <div className='konva-actions'>
        <Typography>
          <strong>{i18next.t(21262)}</strong>
        </Typography>
        <div className='actions'>
          <Button
            color='btnSecondary'
            onClick={() => addMarking()}
            disabled={isSaving || disabled}
          >
            {i18next.t(6417)}
          </Button>
          <Button
            color='btnSecondary'
            onClick={handleOnSave}
            disabled={mlen === 0 || mlen === 2 || isSaving || disabled}
          >
            {i18next.t(7069)}
          </Button>
          <Button
            disabled={markings?.length === 0 || isSaving || disabled}
            onClick={removeMarking}
          >
            {i18next.t(1243)}
          </Button>
          <Button
            color='success'
            onClick={handleOnSave}
            disabled={mlen === 0 || mlen === 2 || disabled}
          >
            {i18next.t(148)}
          </Button>
          {props.isSaving && <Spinner />}
        </div>
      </div>
    );
  };

  return (
    <div className='konva-container'>
      {renderMiniGallery()}
      {renderActions()}
      {renderContainer()}

      <ConfirmModal
        header={i18next.t(144)}
        show={showConfirmDelete}
        toggle={toggleConfirmDeleteModal}
        confirmFunc={removeMarking}
        cancelFunc={toggleConfirmDeleteModal}
      />
    </div>
  );
};

function mapStateToProps({ markings }) {
  return {
    markings,
  };
}

export default connect(mapStateToProps, {
  updateReferenceInfo,
  setSaveAllowedState,
})(KonvaEditor);
