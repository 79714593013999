import { createSlice } from '@reduxjs/toolkit';

// Define initial state
const initialState = {
  allImageRefs: [],
  currentRefs: null,
  markings: [],
  bindings: {},
  isSaveAllowed: false,
};

// Create a slice
const imageReferencesSlice = createSlice({
  name: 'imageReferences',
  initialState,
  reducers: {
    // Reducer function for getting all image references
    setAllImageReferences(state, action) {
      state.allImageRefs = action.payload;
    },
    // Reducer function for setting current image references
    setCurrentImageReferences(state, action) {
      state.currentRefs = action.payload;
    },
    // Reducer function for updating reference info
    setReferenceInfo(state, action) {
      state.markings = action.payload.markings;
      state.bindings = action.payload.bindings;
    },
    // Reducer function for updating save permission
    setIsSaveAllowed(state, action) {
      state.isSaveAllowed = action.payload;
    },
  },
});

// Export reducers and action creators
export const {
  setAllImageReferences,
  setCurrentImageReferences,
  setReferenceInfo,
  setIsSaveAllowed,
} = imageReferencesSlice.actions;

// Export reducer function
export default imageReferencesSlice.reducer;
