/**
 * @module actions/language
 */
import FileSaver from 'file-saver';
import { API_URL } from '../constants';
import axios from 'axios';
import { setUserLanguage } from '../slices/commonSlice';
import { setLanguages } from '../slices/languageSlice';

/**
 * @description Get the languageIndex
 * @return {Promise} Axios promise
 */
export function getLanguages() {
  let url = API_URL + '/language/getStaticLanguages';
  return dispatch => {
    return axios.get(url).then(res => {
      //console.log(res);
      if (res.data) {
        dispatch(setLanguages(res.data));
      }
      return res.data;
    });
  };
}

/**
 * @description Change language for editing
 * @return {Promise} Promise
 */
export function setLanguage(language) {
  return dispatch => {
    dispatch(setUserLanguage(language));
  };
}

/**
 * @description Download case pdf
 * @return {Promise} Axios promise
 */
export function downloadSqlQuery() {
  let url = API_URL + `/language/query`;
  return dispatch => {
    return axios.get(url, { responseType: 'blob' }).then(res => {
      const regex = /filename=["]?(.*\.sql)["]?;/g;
      const headerContentDisposition = res.headers['content-disposition'];
      const fileNameGroups = regex.exec(headerContentDisposition);
      // console.log('download', fileNameGroups, headerContentDisposition);

      FileSaver.saveAs(res.data, fileNameGroups[1]);
    });
  };
}

/**
 * @description Add new language item
 * @return {Promise} Axios promise
 */
export function addLanguageItem(data) {
  let url = API_URL + '/language';
  return dispatch => {
    return axios.post(url, data);
  };
}

/**
 * @description Update existing language item
 * @return {Promise} Axios promise
 */
export function updateLanguageItem(data) {
  let url = API_URL + '/language';
  return dispatch => {
    return axios.put(url, data);
  };
}
