import { createSlice } from '@reduxjs/toolkit';

// Define initial state
const initialState = {
  caseDescriptions: null,
  customerDescriptions: null,
};

// Create a slice for case descriptions
const caseDescriptionsSlice = createSlice({
  name: 'caseDescriptions',
  initialState,
  reducers: {
    // Reducer function for setting case descriptions
    setCaseDescriptions(state, action) {
      state.caseDescriptions = action.payload;
    },
    // Reducer function for setting customer descriptions
    setCustomerDescriptions(state, action) {
      state.customerDescriptions = action.payload;
    },
  },
});

// Export reducers and action creators
export const {
  setCaseDescriptions,
  setCustomerDescriptions,
} = caseDescriptionsSlice.actions;

// Export reducer functions
export default caseDescriptionsSlice.reducer;
