import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import i18next from '../../i18n';
import { Button } from 'reactstrap';
import InfiniteScroll from 'react-infinite-scroll-component';
import moment from 'moment';
import FileSaver from 'file-saver';
import 'moment/locale/da';

// Actions
import {
  getNotifications,
  markNotification,
  getNotificationSettings,
  updateNotificationSettings,
} from '../../redux/actions/notifications';
import { switchCompany } from '../../redux/actions/auth';

// Utils
import { getUserData } from '../../utils/userUtil';
import {
  NOTIFICATION_ACTION_TYPE,
  NOTIFICATION_SUBJECT_AREA,
  NOTIFICATION_SETTINGS_ACTIONS,
} from '../../redux/constants';
import { getTooltip } from '../../utils/tooltip';
import { IconDownload2, IconLink } from '../../assets/icons_v2';

// Components
import UserAvatar from '../Custom/UserAvatar';
import Typography from '../Typography';
import {
  //IconDownload,
  IconEksterneParter,
  IconInfo,
  IconKvalitetssikring,
  IconPdf,
  IconServiceaftale,
  IconSignatur,
  //IconTermsConditions,
  IconVarmtArbejde,
} from '../../assets/icons_v2';
import CustomModal from '../Custom/CustomModal';

// Set locale
moment.locale('da');

const NotificationsMenu = props => {
  const { settings, active } = props;

  // Fetching props
  const [noNotifications, setNoNotifications] = useState(false);
  const [maxChunk] = useState(75);
  const [chunk, setChunk] = useState(15);
  const [hasMore, setHasMore] = useState(true);

  // behavior
  const [mouseInContainer, setMouseInContainer] = useState(false);
  const [showSwitchCompany, setShowSwitchCompany] = useState(false);
  const [selectedNotis, setSelectedNotis] = useState(null);
  const [switchingCompany, setSwitchingCompany] = useState(false);
  const [tick, setTick] = useState(0);

  // filters
  const [unreadOnly, setUnreadOnly] = useState(false);
  const [notifications, setNotifications] = useState([
    // Data struct.
    // {
    //   id: 0,
    //   read: false,
    //   title: '',
    //   message:'',
    //   submessage: '',
    //   type: 0,
    //   priority: 0,
    //   customerId: 0,
    //   extraInfo: '',
    //   associatedUser: {
    //     id: 0,
    //     name: '',
    //     avatarUrl: '',
    //   },
    //   redirector: {
    //     webPage: '',
    //     appPage: '',
    //   },
    //   subjectArea: 1,
    //   createdDate: new Date(),
    // },
  ]);

  useEffect(() => {
    getNotificationsLoop();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (settings?.hasUnread) {
      props.updateNotificationSettings({
        action: NOTIFICATION_SETTINGS_ACTIONS.OPENED,
      });
    }
    if (active) getNotifications();
    handleScrollToTop();
    // eslint-disable-next-line
  }, [active]);

  useEffect(() => {
    if (chunk > 15) getNotifications();
    // eslint-disable-next-line
  }, [chunk]);

  useEffect(() => {
    if (tick > 0) getNotifications();
    // eslint-disable-next-line
  }, [tick]);

  /////////////////////////////////////////////////////////////////////////

  const getNotificationsLoop = (tick = 0) => {
    const sec = 10;
    let next = tick + 1;
    setTick(next);
    //getNotifications();
    setTimeout(() => {
      getNotificationsLoop(next);
    }, sec * 1000);
  };

  const getNotifications = () => {
    props.getNotificationSettings().catch(err => {
      console.log(err);
    });
    if (props.active || notifications.length === 0) {
      props
        .getNotifications(0, chunk)
        .then(notis => {
          const noNotifications = notis.length === 0;

          let hasMore = true;
          if (notis.length < chunk) {
            hasMore = false;
          }

          setNotifications(notis);
          setNoNotifications(noNotifications);
          setHasMore(hasMore);
        })
        .catch(err => {
          console.log(err);
        });
    }
  };

  const getFilteredNotifications = () => {
    let notis = [];
    if (notifications) {
      notis = notifications.slice();

      if (unreadOnly) {
        notis = notis.filter(x => !x.read);
      }
    }

    return notis;
  };

  const increaseChunk = () => {
    let newChunk = chunk + 15;
    if (newChunk > maxChunk) newChunk = maxChunk;
    setChunk(newChunk);
  };

  /////////////////////////////////////////////////////////////////////////

  const markNotificationAsRead = (notis, index, callback) => {
    if (!notis) return;

    if (notis.read) {
      if (callback) {
        callback(notis);
      }
      return;
    }

    let notif = notifications.slice();
    notif[index].read = true;

    setNoNotifications(notif);
    props.markNotification({ notifcationId: notis.id }).catch(err => {
      console.log(err);
    });

    if (callback) {
      callback(notis);
    }
  };

  const redirectToPage = notis => {
    if (!notis) return;

    if (
      notificationIsFromOtherCompany(notis) &&
      ![
        NOTIFICATION_SUBJECT_AREA.Invitation,
        NOTIFICATION_SUBJECT_AREA.InvitationRequestAccepted,
      ].includes(notis.subjectArea)
    ) {
      this.setState({ showSwitchCompany: true, selectedNotis: notis });
    } else {
      setTimeout(() => {
        if (notis.subjectArea === NOTIFICATION_SUBJECT_AREA.VA) {
          window.location =
            `${process.env.REACT_APP_LOCAL_URL}` + notis.redirector.webPage;
        } else if (notis.subjectArea === NOTIFICATION_SUBJECT_AREA.Invitation) {
          window.location = `${process.env.REACT_APP_LOCAL_URL}/companies`;
        } else if (
          notis.subjectArea ===
          NOTIFICATION_SUBJECT_AREA.InvitationRequestAccess
        ) {
          window.location = `${process.env.REACT_APP_LOCAL_URL}/users`;
        } else if (
          notis.subjectArea ===
          NOTIFICATION_SUBJECT_AREA.InvitationRequestAccepted
        ) {
          window.location = `${process.env.REACT_APP_LOCAL_URL}/companies`;
        } else if (
          notis.subjectArea === NOTIFICATION_SUBJECT_AREA.SchemaSignature
        ) {
          window.location = `${process.env.REACT_APP_LOCAL_URL}${notis.redirector.webPage}`;
        }
      }, 500);
    }
  };

  const onBlur = () => {
    // console.log('onblur', mouseInContainer);
    if (props.onBlur && !mouseInContainer) {
      props.onBlur();
    }
  };

  const onMouseEnter = () => {
    if (!mouseInContainer) {
      setMouseInContainer(true);
    }
  };

  const onMouseLeave = () => {
    if (mouseInContainer) {
      setMouseInContainer(false);
    }
  };

  const notificationIsFromOtherCompany = notis => {
    const { auth } = props;
    const user = getUserData(auth);
    if (notis.customerId < 1) return false;
    return user.customerId !== notis.customerId;
  };

  /////////////////////////////////////////////////////////////////////////

  const markAllAsRead = () => {
    props.updateNotificationSettings({
      action: NOTIFICATION_SETTINGS_ACTIONS.MARK_ALL_AS_READ,
    });
    getNotifications();
  };

  const handleScrollToTop = () => {
    // Scroll to top of the list
    try {
      var parentDiv = document.querySelector('.infinite-scroll-component');
      if (!parentDiv) return;
      // Get the first child div
      var firstChildDiv = parentDiv.querySelector('div');
      if (!firstChildDiv) return;
      // Scroll to the first child div
      firstChildDiv.scrollIntoView({ behavior: 'smooth' });
    } catch (ex) {}
  };

  const renderHeader = () => {
    return (
      <div className='header'>
        <Typography
          className='title'
          type='h5'
          //onClick={() => handleScrollToTop()}
        >
          {i18next.t(42)}
        </Typography>
        <div className='filters'>
          <Button
            size='sm'
            color='btnSecondary'
            outline={unreadOnly}
            onClick={() => setUnreadOnly(false)}
            disabled={noNotifications}
          >
            {i18next.t(1016)}
          </Button>
          <Button
            size='sm'
            color='btnSecondary'
            outline={!unreadOnly}
            onClick={() => setUnreadOnly(true)}
            disabled={noNotifications}
          >
            {i18next.t(8188)}
          </Button>
          {/* <Button
            size='sm'
            color='btnSecondary'
            outline={!unreadOnly}
            onClick={() => handleScrollToTop()}
            disabled={notifications.length === 0}
          >
            {i18next.t(8188)} // SCROLL to TOP
          </Button> */}
          {unreadOnly && (
            <Button
              size='sm'
              color='btnSecondary'
              outline={true}
              onClick={() => markAllAsRead()}
              disabled={
                noNotifications ||
                notifications.slice().filter(x => !x.read).length === 0
              }
            >
              {/* {fetching ? (
                <Spinner style={{ height: 20, width: 20 }} />
              ) : (
                i18next.t(9113)
              )} */}
            </Button>
          )}
        </div>
      </div>
    );
  };

  /////////////////////////////////////////////////////////////////////////

  const getMainIcon = notis => {
    const sharedClass = 'main-icon';

    switch (notis.subjectArea) {
      case NOTIFICATION_SUBJECT_AREA.PDF:
        return <IconPdf className={sharedClass} />;
      default:
        return null;
    }
  };

  const getSubIcon = notis => {
    const sharedClass = 'sub-icon';
    switch (notis.subjectArea) {
      case NOTIFICATION_SUBJECT_AREA.VA:
        return <IconVarmtArbejde className={sharedClass} />;
      case NOTIFICATION_SUBJECT_AREA.KS:
        return <IconKvalitetssikring className={sharedClass} />;
      case NOTIFICATION_SUBJECT_AREA.SA:
        return <IconServiceaftale className={sharedClass} />;
      case NOTIFICATION_SUBJECT_AREA.Invitation:
        return <IconEksterneParter className={sharedClass} />;
      case NOTIFICATION_SUBJECT_AREA.System:
        return <IconInfo className={sharedClass} />;
      case NOTIFICATION_SUBJECT_AREA.SchemaSignature:
        return <IconSignatur className={sharedClass} />;
      default:
        return null;
    }
  };

  const getIcon = notis => {
    let icon = getMainIcon(notis);
    let subjectIcon = getSubIcon(notis);

    let name;
    if (notis.associatedUser) {
      name = notis.associatedUser.name;
    }

    return (
      <div className='icon'>
        {icon}
        {!icon && (
          <UserAvatar
            userId={notis.associatedUser?.id}
            uri={notis.associatedUser?.avatarUrl}
            name={name}
            className='avatar'
            color='#aaa'
          />
        )}
        {subjectIcon && <div className='sub-icon-wrapper'>{subjectIcon}</div>}
      </div>
    );
  };

  /////////////////////////////////////////////////////////////////////////

  const getContent = (notis, index) => {
    return (
      <div className='content'>
        <div className='top'>
          <div className='left'>
            <div className='text'>
              <strong>{notis.title}</strong> {notis.message}
            </div>
          </div>
          <div className='right'>
            {getActions(notis, index)}
            {getIndicator(notis)}
          </div>
        </div>
        <div className='bottom'>
          <div className='subtext'>{notis.extraInfo}</div>
          <div className='age'>{moment(notis.date).fromNow()}</div>
        </div>
      </div>
    );
  };

  /////////////////////////////////////////////////////////////////////////

  const getPdfActions = (notis, index) => {
    if (!notis) return [];
    if (!notis.actionableObject) return [];
    const { file, token } = notis.actionableObject;
    const actions = [];
    let link;

    const action = callback => markNotificationAsRead(notis, index, callback);

    if (file) {
      actions.push({
        type: 'file',
        Icon: IconDownload2,
        action: () =>
          action(() => FileSaver.saveAs(file.fileUrl, file.fileName)),
      });
    }

    if (token?.trim().length > 0) {
      link = `${process.env.REACT_APP_LOCAL_URL}/file?token=${token}`;
    } else if (file) {
      link = file.fileUrl;
    }

    actions.push({
      type: 'link',
      Icon: IconLink,
      action: () => action(() => window.open(link)),
    });

    return actions;
  };

  const getActions = (notis, index) => {
    let actions = [];
    if (notis.actionType === NOTIFICATION_ACTION_TYPE.None) {
      return null;
    }

    if (
      notis.actionType === NOTIFICATION_ACTION_TYPE.Simple &&
      notis.subjectArea === NOTIFICATION_SUBJECT_AREA.PDF
    ) {
      actions = getPdfActions(notis, index);
    }

    const renderAction = ({ action, Icon, type }, index) => {
      let targetId = 'action-tooltip_' + index;
      return (
        <div
          data-tip
          data-for={targetId}
          key={index}
          className='action'
          onClick={action}
        >
          {!!Icon && <Icon color='rgba(0,0,0,0.1)' />}
          {getTooltip(
            targetId,
            type === 'file' ? i18next.t(141) : i18next.t(6025),
          )}
        </div>
      );
    };

    return <div className='actions'>{[actions.map(renderAction)]}</div>;
  };

  /////////////////////////////////////////////////////////////////////////

  const getIndicator = notis => {
    if (notis.actionType === NOTIFICATION_ACTION_TYPE.Simple) return null;
    const indicator = <div className='dot' />;

    return <div className='indicator'>{!notis.read && indicator}</div>;
  };

  /////////////////////////////////////////////////////////////////////////

  const renderNotification = (notis, index) => {
    let onClickAction;
    let clickableClass = '';

    if (
      notis.actionType === NOTIFICATION_ACTION_TYPE.None &&
      !notis.read &&
      ![
        NOTIFICATION_SUBJECT_AREA.Invitation,
        NOTIFICATION_SUBJECT_AREA.InvitationRequestAccess,
        NOTIFICATION_SUBJECT_AREA.InvitationRequestAccepted,
      ].includes(notis.subjectArea)
    ) {
      onClickAction = () => markNotificationAsRead(notis, index);
      clickableClass = ' unhandled';
    } else if (notis.actionType === NOTIFICATION_ACTION_TYPE.Redirect) {
      onClickAction = () =>
        markNotificationAsRead(notis, index, redirectToPage);
      clickableClass = ' unhandled';
    } else if (
      [NOTIFICATION_SUBJECT_AREA.InvitationRequestAccess].includes(
        notis.subjectArea,
      ) &&
      notificationIsFromOtherCompany(notis)
    ) {
      onClickAction = () => {
        setShowSwitchCompany(true);
        setSelectedNotis(notis);
      };
      clickableClass = ' unhandled';
    } else if (
      [NOTIFICATION_SUBJECT_AREA.InvitationRequestAccess].includes(
        notis.subjectArea,
      ) &&
      !notificationIsFromOtherCompany(notis)
    ) {
      onClickAction = () =>
        markNotificationAsRead(notis, index, redirectToPage);
      clickableClass = ' unhandled';
    } else if (
      [
        NOTIFICATION_SUBJECT_AREA.Invitation,
        NOTIFICATION_SUBJECT_AREA.InvitationRequestAccepted,
      ].includes(notis.subjectArea)
    ) {
      onClickAction = () =>
        markNotificationAsRead(notis, index, redirectToPage);
      clickableClass = ' unhandled';
    } else if ([NOTIFICATION_SUBJECT_AREA.PDF].includes(notis.subjectArea)) {
      if (notis.actionableObject) {
        const { file, token } = notis.actionableObject;

        let link = '/';
        if (token?.trim().length > 0) {
          link = `${process.env.REACT_APP_LOCAL_URL}/file?token=${token}`;
        } else if (file) {
          link = file.fileUrl;
        }

        onClickAction = () => {
          window.open(link);
        };

        clickableClass = ' unhandled';
      }
    }

    return (
      <div
        key={index}
        className={'notification' + clickableClass}
        onClick={onClickAction}
        onMouseEnter={onMouseEnter}
      >
        {getIcon(notis)}
        {getContent(notis, index)}
      </div>
    );
  };

  const renderDummyNotification = () => {
    return (
      <div className={'notification dummy'}>
        <div className='icon'>
          <div className='dummy' />
        </div>
        <div className='content'>
          <div className='dummy' />
          <div className='dummy' />
          <div className='dummy small' />
        </div>
      </div>
    );
  };

  const renderNoNotificationsMessage = () => {
    return (
      <div className={'notification empty'}>
        <div className='text'>{i18next.t(8210)}</div>
      </div>
    );
  };

  /////////////////////////////////////////////////////////////////////////

  const handleSwitchCompany = () => {
    setSwitchingCompany(true);
    props
      .switchCompany({
        customerId: selectedNotis.customerId,
        deviceType: 'web',
      })
      .then(() => redirectToPage(selectedNotis))
      .catch(err => {
        console.log(err);
        setSwitchingCompany(false);
      });
  };

  const renderFooter = () => {
    return (
      <div className='footer-btns'>
        <Button
          size='sm'
          color='btnSecondary'
          onClick={handleSwitchCompany}
          disabled={switchingCompany}
        >
          {i18next.t(8211)}
        </Button>
        <Button
          size='sm'
          color='btnSecondary'
          outline
          onClick={() => setShowSwitchCompany(!showSwitchCompany)}
          disabled={switchingCompany}
        >
          {i18next.t(2)}
        </Button>
      </div>
    );
  };

  const renderSwitchCompanyModal = () => {
    return (
      <CustomModal
        show={showSwitchCompany}
        toggle={() => setShowSwitchCompany(!showSwitchCompany)}
        size='lg'
        title={i18next.t(8211)}
        footer={renderFooter()}
      >
        <Typography>
          {i18next.t(8212)} <strong>{selectedNotis?.customerName}</strong>.
        </Typography>
        <Typography>{i18next.t(8213)}</Typography>
      </CustomModal>
    );
  };

  /////////////////////////////////////////////////////////////////////////

  const notis = getFilteredNotifications();

  return (
    <div
      className={
        'notifications-menu-wrapper-overlay' + (props.active ? ' show' : '')
      }
      onClick={onBlur}
    >
      <div
        className={'notifications-menu-wrapper'}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      >
        <div className='notifications-content'>
          {renderHeader()}
          <div className='notifications' id='_notifications'>
            <InfiniteScroll
              dataLength={notifications.length}
              next={increaseChunk}
              hasMore={hasMore}
              loader={
                <>
                  {renderDummyNotification()}
                  {renderDummyNotification()}
                </>
              }
              scrollableTarget='_notifications'
            >
              {notis.map(renderNotification)}
              {(noNotifications || notis.length === 0) &&
                renderNoNotificationsMessage()}
            </InfiniteScroll>
          </div>
        </div>
      </div>
      {renderSwitchCompanyModal()}
    </div>
  );
};

function mapStateToProps({ auth, notifications }) {
  return {
    auth,
    notifications: notifications.data,
    settings: notifications.settings,
  };
}

export default connect(mapStateToProps, {
  getNotifications,
  markNotification,
  switchCompany,
  getNotificationSettings,
  updateNotificationSettings,
})(NotificationsMenu);
