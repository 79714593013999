import { createSlice } from '@reduxjs/toolkit';

// Define initial state
const initialState = {
  currentMasterCase: null,
  masterCases: [],
  warmCases: [],
  qualityCases: [],
  selectedCase: null,
  schemas: [],
  caseImages: [],
  serviceAgreements: [],

  caseComment: null,
  dashboardInfo: null,
  modules: {},
  showAddModule: false,
  selectedModuleCase: null,
  redirectAddModule: false,
};

// Create a slice
const caseSlice = createSlice({
  name: 'case',
  initialState,
  reducers: {
    // Reducer function for setting quality cases
    setQualityCases(state, action) {
      state.qualityCases = action.payload;
    },
    // Reducer function for setting warm cases
    setWarmCases(state, action) {
      state.warmCases = action.payload;
    },
    // Reducer function for setting all cases
    setMasterCases(state, action) {
      state.masterCases = action.payload;
    },
    // Reducer function for setting service agreements
    setServiceAgreements(state, action) {
      state.serviceAgreements = action.payload;
    },
    // Reducer function for setting selected case or service agreement
    setSelectedCase(state, action) {
      state.selectedCase = action.payload;
    },
    // Reducer function for setting selected case
    setCurrentMasterCase(state, action) {
      state.currentMasterCase = action.payload;
    },
    // Reducer function for setting case image gallery
    setCaseImageGallery(state, action) {
      state.caseImages = action.payload;
    },

    setCaseComment(state, action) {
      state.caseComment = action.payload;
    },
    setCaesModuleStatus(state, action) {
      state.caseModuleStatuses = action.payload;
    },
    setDashboard(state, action) {
      state.dashboardInfo = action.payload;
    },
    setModules(state, action) {
      state.modules = {
        ...state.modules,
        ...action.payload,
      };
    },
    clearModules(state, action) {
      state.modules = {};
    },
    toggleAddModule(state, action) {
      state.showAddModule = !state.showAddModule;
    },
    setSelectedModuleCase(state, action) {
      state.selectedModuleCase = action.payload;
    },
    setRedirectAddModule(state, action) {
      state.redirectAddModule = action.payload;
    },
  },
});

// Export reducers and action creators
export const {
  setQualityCases,
  setWarmCases,
  setMasterCases,
  setServiceAgreements,
  setSelectedCase,
  setCurrentMasterCase,
  setCaseImageGallery,
  setCaseComment,
  setCaesModuleStatus,
  setDashboard,
  setModules,
  clearModules,
  toggleAddModule,
  setSelectedModuleCase,
  setRedirectAddModule,
} = caseSlice.actions;

// Export reducer function
export default caseSlice.reducer;
