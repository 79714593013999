import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import i18next from '../i18n';
import { CardBody, Row } from 'reactstrap';
import { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import da from 'date-fns/locale/da';
import moment from 'moment';

// Actions
import {
  getServiceAgreements,
  invoiceServiceAgreements,
  invoiceServiceAgreementsSchemaBuilder,
} from '../redux/actions/serviceAgreement';
import { ClearSelectedCase } from '../redux/actions/cases';
import { filterEmployees } from '../redux/actions/employees';
import { getUserFilters, updateUserFilters } from '../redux/actions/common';

// Utils
import { CASE_STATE, FEATURE, SEARCH_CATEGORY } from '../redux/constants';
import {
  parseDateRFC2822,
  escapeRegex,
  parseDate,
  addToObject,
} from '../utils/common';
import {
  badgeByModuleStatus,
  ColorlessBadge,
  getPlaceholderIfEmpty,
  getAddress,
} from '../utils/componentUtils';
import { getUserData } from '../utils/userUtil';

// Components
import ReactDatePicker from '../components/Custom/ReactDatePicker';
import PageSpinner from '../components/PageSpinner';
import SearchInput from '../components/SearchInput';
import ReactSelectFilter from '../components/Custom/ReactSelectFilter';
import withFeature from '../hocs/withFeature';
import ReactTable from '../components/Custom/ReactTable';

//Misc
registerLocale('da', da);

const ServiceAgreements = props => {
  const {
    auth,
    history,
    cases,
    employees,
    getServiceAgreements,
    //ClearSelectedCase,
    filterEmployees,
    getUserFilters,
    updateUserFilters,
  } = props;
  const [isLoading, setLoading] = useState(true);
  const [userFilter, setUserFilter] = useState({
    // Filters
    filter: '',
    filterCustomer: null,
    filterStartDate: null,
    filterEndDate: null,
    filterUsers: [],
    filterNoUser: false,

    // Filter Objects
    filterObj: {
      category: SEARCH_CATEGORY.All,
    },

    // Filter statuses
    filterSubscription: [],
    filterStatus: [
      CASE_STATE.IncompleteControl,
      CASE_STATE.CompletedControl,
      CASE_STATE.MissingControls,
      CASE_STATE.Invoiced,
    ],
    filterStatusOptions: [],
  });
  const [filterUserOptions, setFilterUserOptions] = useState([
    //{ value: 0, label: i18next.t(6126) }
  ]);
  const user = getUserData(props.auth);
  const hasAP = user.hasAP;
  const timerRef = useRef(null);

  useEffect(() => {
    if (auth?.isAuthenticated) {
      if (hasAP) {
        const id = user.customerId > 0 ? user.customerId : 0;
        getEmployees(id);
      }

      getUserFilters().then(res => {
        if (res.data != null) {
          if (res.data.serviceCasesFilter != null) {
            let json = JSON.parse(res.data.serviceCasesFilter);
            const tmpFilter = json.userFilter;
            setUserFilter(tmpFilter);
            const filterObj = {
              ...tmpFilter.filterObj,
              subscription: SEARCH_CATEGORY.All,
              startDate: tmpFilter.filterStartDate,
              endDate: tmpFilter.filterEndDate,
              filterUsers: tmpFilter.filterUsers,
            };
            filterObj.userIds = tmpFilter.filterUsers.map(x => {
              return x.value;
            });
            getServiceAgreements(filterObj).then(res => {
              setLoading(false);
            });
          } else {
            const filterObj = {
              ...userFilter.filterObj,
              subscription: SEARCH_CATEGORY.All,
              startDate: userFilter.filterStartDate,
              endDate: userFilter.filterEndDate,
              filterUsers: userFilter.filterUsers,
            };

            filterObj.userIds = userFilter.filterUsers.map(x => {
              return x.value;
            });

            getServiceAgreements(filterObj).then(res => {
              setLoading(false);
            });
          }
        }
      });
    } else {
      history.push('/login');
    }
    // eslint-disable-next-line
  }, []);

  const refreshServiceAgreements = tmpData => {
    const tmpFilter = tmpData ? tmpData : userFilter;
    // setRefreshing(true);
    // setErrors([]);

    let filterObj = {
      ...tmpFilter.filterObj,
      subscription: SEARCH_CATEGORY.All,
      startDate: tmpFilter.filterStartDate,
      endDate: tmpFilter.filterEndDate,
      filterUsers: tmpFilter.filterUsers,
      filterCustomer: tmpFilter.filterCustomer,
    };

    filterObj.userIds = tmpFilter.filterUsers.map(x => {
      return x.value;
    });

    getServiceAgreements(filterObj)
      .then(res => {
        setLoading(false);
        // setRefreshing(false);
      })
      .catch(err => {
        console.log(err);
        // setRefreshing(false);
        // setErrors([err]);
      });
  };

  const updateFilters = data => {
    let dataObj = {
      serviceCasesFilter: JSON.stringify(data),
    };

    updateUserFilters(dataObj).then(res => {});
  };

  const getEmployees = id => {
    let filter = { type: 1 };
    filterEmployees(id, filter)
      .then(() => {
        const options = employees
          .filter(x => !x.isSimpleUser)
          .map(x => {
            return { value: x.id, label: x.name };
          });
        options.unshift({ value: 0, label: i18next.t(6126) });
        setFilterUserOptions(options);
      })
      .catch(err => {
        console.log(err);
      });
  };

  const getBuildingsStats = (
    { buildings, caseName, controlSchemaGroupsSimple, status },
    guid,
  ) => {
    const renderItem = (done, total) => {
      return (
        <div className='stats-font'>
          <span className='greenText bolded'>{done}</span>/
          <span className='bolded'>{total}</span>
        </div>
      );
    };

    let doneSchemas = 0;
    let totalSchemas = 0;
    const buildingsTotal = buildings.length === 0 ? 1 : buildings.length;

    if (controlSchemaGroupsSimple) {
      const flattened = controlSchemaGroupsSimple
        .reduce((p, c) => [...p, ...c.schemas], [])
        .filter(x => x.isDone).length;
      doneSchemas = flattened;
      totalSchemas = controlSchemaGroupsSimple.length;
    } else {
      if (buildings.controlSchemas) {
        doneSchemas += buildings.controlSchemas?.filter(
          x => x.groupGuid === guid && x.isDone,
        ).length;
      }
      totalSchemas = buildingsTotal;
    }

    if ([CASE_STATE.Invoiced, CASE_STATE.CompletedControl].includes(status)) {
      return renderItem(buildingsTotal, buildingsTotal);
    } else {
      return renderItem(doneSchemas, totalSchemas);
    }
  };

  const onFilterChange = e => {
    let value = e.target.value;
    const tmpFilter = { ...userFilter, filter: value };
    setUserFilter(tmpFilter);
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }
    timerRef.current = setTimeout(() => {
      updateFilters({
        userFilter: tmpFilter,
      });
    });
  };

  const onFilterStartDateChange = date => {
    const tmpFilter = { ...userFilter, filterStartDate: date };
    setUserFilter(tmpFilter);

    refreshServiceAgreements(tmpFilter);
    updateFilters({
      userFilter: tmpFilter,
    });
  };

  const onFilterEndDateChange = date => {
    const tmpFilter = { ...userFilter, filterEndDate: date };
    setUserFilter(tmpFilter);

    refreshServiceAgreements(tmpFilter);
    updateFilters({
      userFilter: tmpFilter,
    });
  };

  // const onFilterCustomerChange = customer => {
  //   const tmpFilter = { ...userFilter, filterCustomer: customer };
  //   setUserFilter(tmpFilter);

  //   updateFilters({
  //     userFilter: tmpFilter,
  //   });
  // };

  const onFilterUsersChange = groupValues => {
    const tmpFilter = { ...userFilter, filterUsers: groupValues };
    setUserFilter(tmpFilter);

    updateFilters({
      userFilter: tmpFilter,
    });
  };

  const onFilterSubscriptionChange = type => {
    const tmpFilter = {
      ...userFilter,
      filterSubscription: type,
    };
    setUserFilter(tmpFilter);

    refreshServiceAgreements(tmpFilter);
    updateFilters({
      userFilter: tmpFilter,
    });
  };

  const displayFilter = () => {
    if (auth) {
      if (auth.loginAsUser) {
        const user = JSON.parse(auth.loginAsUser);
        return user.loginData.accessLevel <= 2;
      } else {
        return auth.user.accessLevel <= 2;
      }
    } else {
      return false;
    }
  };

  const onFilterStatusChange = groupValues => {
    const status = groupValues.map(x => x.value);
    const tmpFilter = {
      ...userFilter,
      filterStatusOptions: groupValues,
      filterStatus: status,
    };
    setUserFilter(tmpFilter);
    updateFilters({ userFilter: tmpFilter });
  };

  const renderMultiStatusFilter = () => {
    const options = [
      { value: CASE_STATE.IncompleteControl, label: i18next.t(6089) },
      { value: CASE_STATE.CompletedControl, label: i18next.t(6119) },
      { value: CASE_STATE.MissingControls, label: i18next.t(6090) },
      { value: CASE_STATE.Invoiced, label: i18next.t(6074) },
    ];

    const onChangeStatus = value => {
      let groupValues = value;
      if (!groupValues) {
        groupValues = [];
      }
      onFilterStatusChange(groupValues);
    };

    return (
      <div className='user-filter btn-group m-1'>
        <ReactSelectFilter
          value={userFilter.filterStatusOptions}
          options={options}
          onChange={onChangeStatus}
          placeholder={i18next.t(6121)}
          closeMenuOnSelect={false}
          onMenuClose={() => refreshServiceAgreements()}
          isMulti
        />
      </div>
    );
  };

  const renderSubscriptionFilter = () => {
    const options = [
      { value: CASE_STATE.Subscribed, label: i18next.t(6120) },
      { value: CASE_STATE.Unsubscribed, label: i18next.t(72) },
    ];

    return (
      <div className='user-filter btn-group m-1'>
        <ReactSelectFilter
          value={userFilter.filterSubscription}
          options={options}
          onChange={e => onFilterSubscriptionChange(e)}
          placeholder={i18next.t(6095)}
          closeMenuOnSelect={false}
          onMenuClose={() => refreshServiceAgreements()}
          isMulti
        />
      </div>
    );
  };

  // const renderCustomerFilter = () => {
  //   if (!cases) return null;
  //   if (!displayFilter()) return null;

  //   let names = [];

  //   if (cases.length > 0) {
  //     return (
  //       <UncontrolledButtonDropdown key='uncontrolled-customer-dropdown'>
  //         <DropdownToggle caret color='info' className='text-capitalize m-1'>
  //           {i18next.t(30)}:{' '}
  //           {userFilter?.filterCustomer
  //             ? userFilter.filterCustomer
  //             : i18next.t(1016)}
  //         </DropdownToggle>
  //         <DropdownMenu>
  //           <DropdownItem onClick={() => onFilterCustomerChange()}>
  //             {i18next.t(1016)}
  //           </DropdownItem>
  //           {cases.map((item, key) => {
  //             if (!names.includes(item.customerName)) {
  //               names.push(item.customerName);

  //               return (
  //                 <DropdownItem
  //                   onClick={() =>
  //                     onFilterCustomerChange(item.customerName)
  //                   }
  //                   key={key}
  //                 >
  //                   {item.customerName}
  //                 </DropdownItem>
  //               );
  //             } else {
  //               return null;
  //             }
  //           })}
  //         </DropdownMenu>
  //       </UncontrolledButtonDropdown>
  //     );
  //   }
  // };

  const periodFilter = type => {
    let val =
      parseDateRFC2822(userFilter[type]) !== 'Invalid Date'
        ? parseDateRFC2822(userFilter[type])
        : null;

    const defaultProps = {
      className: 'form-control',
      value: val,
      format: 'dd.MM.yyyy',
    };

    if (type === 'filterStartDate') {
      return (
        <ReactDatePicker
          {...defaultProps}
          onChange={v => onFilterStartDateChange(v)}
          placeholder={i18next.t(110)}
        />
      );
    }

    if (type === 'filterEndDate') {
      return (
        <ReactDatePicker
          {...defaultProps}
          onChange={v => onFilterEndDateChange(v)}
          placeholder={i18next.t(111)}
        />
      );
    }
  };

  const renderUserFilter = () => {
    if (!user.hasAP) return null;

    const onChangeUsers = value => {
      let groupValues = value;
      if (!groupValues) {
        groupValues = [];
      }

      onFilterUsersChange(groupValues);
    };

    return (
      <div className='user-filter btn-group m-1'>
        <ReactSelectFilter
          value={userFilter.filterUsers}
          options={filterUserOptions}
          onChange={onChangeUsers}
          placeholder={i18next.t(6035)}
          closeMenuOnSelect={false}
          onMenuClose={() => refreshServiceAgreements()}
          isDisabled={userFilter.filterNoUser}
          isMulti
        />
        {/* {renderNoUserFilter()} */}
      </div>
    );
  };

  // const renderNoUserFilter = () => {
  //   if (!user.hasAP) return null;

  //   const onChangeNouser = () => {
  //     const tmpFilter = {
  //       ...userFilter,
  //       filterNoUser: !userFilter.filterNoUser,
  //     };
  //     setUserFilter(tmpFilter);
  //     updateFilters({
  //       userFilter: tmpFilter,
  //     });
  //   };

  //   return (
  //     <Checkbox
  //       className='m-1 filterCheckbox'
  //       for={i18next.t(6126)}
  //       name='filterNoUser'
  //       onChange={onChangeNouser}
  //       checked={userFilter.filterNoUser}
  //     />
  //   );
  // };

  const gotoCase = (e, _case) => {
    // const archiveQuery =
    //   _case.status === CASE_STATE.Invoiced ? '?archive=1' : '';

    // Middle mouse
    if (e.button === 1) {
      window.open(
        process.env.REACT_APP_LOCAL_URL +
          `/cases/${_case.masterCaseId}?view=0&type=2&module=3&moduleId=${_case.id}`,
        '_blank',
        'noreferrer',
      );
    } else {
      // Regular click
      history.push(
        `/cases/${_case.masterCaseId}?view=0&type=2&module=3&moduleId=${_case.id}`,
      );
    }
  };

  // const getButtonByCaseType = _case => {
  //   return (
  //     <Button
  //       style={{ width: '75px' }}
  //       color='btnSecondary'
  //       onClick={() => gotoCase(_case)}
  //       size='sm'
  //     >
  //       {i18next.t(40)}
  //     </Button>
  //   );
  // };

  const getFilteredResults = () => {
    if (!cases) return null;
    const filterCustomer = cases.filter(item => {
      if (!userFilter.filterCustomer || !displayFilter()) return true;

      try {
        var reg = new RegExp(`^${escapeRegex(userFilter.filterCustomer)}`, 'i');
        return reg.test(item.customerName);
      } catch (error) {
        return true;
      }
    });

    let filteredCases = filterCustomer.filter(item => {
      try {
        var reg = new RegExp(`${escapeRegex(userFilter.filter)}`, 'i');

        if (
          reg.test(item.caseNumber) ||
          reg.test(item.caseName) ||
          reg.test(getAddress(item, true))
        ) {
          return true;
        }
        return false;
      } catch (error) {
        return true;
      }
    });

    // status filtering
    if (userFilter.filterStatus) {
      if (userFilter.filterStatus.length > 0) {
        filteredCases = filteredCases.filter(x =>
          userFilter.filterStatus.find(y => y === x.status),
        );
      }
    }

    //subscription filtering
    if (userFilter.filterSubscription) {
      if (userFilter.filterSubscription.length > 0) {
        filteredCases = filteredCases.filter(x =>
          userFilter.filterSubscription.find(y => y.value === x.subscription),
        );
      }
    }

    // Result
    return filteredCases;
  };

  // const renderErrors = () => {
  //   if (errors.length === 0) return null;

  //   return (
  //     <div>
  //       <p style={{ color: 'red' }}>{i18next.t(212)}</p>
  //     </div>
  //   );
  // };

  const renderSearchFilter = () => {
    return (
      <div className='m-1'>
        <SearchInput
          className='sa_search-input '
          onChange={onFilterChange}
          value={userFilter.filter}
        />
      </div>
    );
  };

  // const renderStatusCount = () => {
  //   const results = getFilteredResults();

  //   const renderItem = (text, color, status) => {
  //     const count = results.filter(x => x.status === status).length;
  //     return (
  //       <Card className='filter__status_card' color={color}>
  //         <Typography className='filter__status_card_text'>
  //           {i18next.t(text)}
  //         </Typography>
  //         <Typography className='filter__status_card_count'>{count}</Typography>
  //       </Card>
  //     );
  //   };
  //   return (
  //     <CardDeck>
  //       {renderItem(6089, 'warning', CASE_STATE.IncompleteControl)}
  //       {renderItem(6119, 'success', CASE_STATE.CompletedControl)}
  //       {renderItem(6074, 'info', CASE_STATE.Invoiced)}
  //       {renderItem(6090, 'secondary', CASE_STATE.MissingControls)}
  //       <Card className='filter__status_card dark' color={'light'}>
  //         <Typography className='filter__status_card_text'>
  //           {i18next.t(1016)}
  //         </Typography>
  //         <Typography className='filter__status_card_count'>
  //           {results.length}
  //         </Typography>
  //       </Card>
  //     </CardDeck>
  //   );
  // };

  const getTableProps = () => {
    let headers = [
      {
        title: i18next.t(1000),
        sortable: true,
        selector: 'caseNumber',
        sortType: 'number',
      },
      {
        title: i18next.t(1151),
        sortable: true,
        selector: 'caseName',
        sortType: 'string',
      },
      { title: i18next.t(1331) },
      { title: i18next.t(1012), tdClass: 'max-200px' },
      {
        title: i18next.t(6109),
        sortable: true,
        selector: 'date',
        sortType: 'date',
      },
      {
        title: i18next.t(21119),
        sortable: true,
        selector: 'lastEdited',
        sortType: 'date',
      },
      { title: i18next.t(6121) },
      { title: i18next.t(6095) },
    ];

    if (hasAP) {
      headers.splice(5, 0, { title: i18next.t(6035) });
    }

    if (displayFilter()) {
      headers.unshift({
        title: i18next.t(30),
        sortable: true,
        selector: 'customerName',
        sortType: 'string',
      });
    }

    return {
      colgroup: ['20%', '15%', '', '', '10%'],
      scrollRender: {
        window: document.getElementById('main-content-wrapper'),
        body: document.getElementById('main-content-id'),
      },
      headers: {
        class: '',
        data: headers,
      },
      body: {
        class: '',
        empty: i18next.t(21400),
        data: getBodyData(),
      },
    };
  };

  const getBodyData = () => {
    const filteredCases = getFilteredResults();

    let bodyData = [];
    filteredCases.forEach((_case, key) => {
      let date = parseDate(_case.controlDate);
      if (date === 'Invalid date') date = '';

      let users = '';
      if (hasAP) {
        const userArray = _case.assignees
          .filter(x => x.asigneeType === 11)
          .map(x => x.user.name);
        if (userFilter.filterNoUser && userArray.length > 0) return null;

        users = userArray.join(', ');
      }

      const caseNumber = getPlaceholderIfEmpty(_case.caseNumber);
      const caseName = getPlaceholderIfEmpty(_case.caseName);

      const buildingStats = getBuildingsStats(_case, _case.activeGroupGuid);

      const address = getAddress(_case);
      const badge = badgeByModuleStatus(_case.status);

      const subscriptionText =
        _case.subscription === CASE_STATE.Subscribed
          ? i18next.t(71)
          : i18next.t(72);

      const lastEdited =
        moment(_case.lastEditDate).format('DD.MM.YYYY') !== '01.01.0001'
          ? moment(_case.lastEditDate).format('DD.MM.YYYY')
          : moment(_case.creationDate).format('DD.MM.YYYY');

      const subscriptionBadge = ColorlessBadge(subscriptionText);
      // const button = getButtonByCaseType(_case);

      let dataObj = {
        caseNumber,
        caseName,
        buildingStats,
        address,
        date,
        lastEdited,
        badge,
        subscriptionBadge,
        // button,
        _onClick: e => gotoCase(e, _case),
      };

      if (hasAP) {
        const temp = getPlaceholderIfEmpty(users);
        dataObj = addToObject(dataObj, 'users', temp, 5);
      }

      // Return column
      if (!displayFilter()) {
        bodyData.push(dataObj);
      } else {
        const customerName = _case.customerName;
        dataObj = addToObject(dataObj, 'customerName', customerName, 0);
        bodyData.push(dataObj);
      }
    });

    //console.log(bodyData);
    return bodyData;
  };

  if (isLoading) {
    return <PageSpinner />;
  }

  return (
    <CardBody>
      <Row
        style={{
          marginLeft: '0px',
          marginBottom: '10px',
        }}
      >
        {/* {renderCustomerFilter()} */}
        {renderSearchFilter()}
        {/* {renderDateFilters()} */}
        {periodFilter('filterStartDate')}
        {periodFilter('filterEndDate')}
        {renderSubscriptionFilter()}
        {renderMultiStatusFilter()}

        {hasAP && renderUserFilter()}
      </Row>
      {/* Rendering of the data */}
      <ReactTable size='sm' striped data={getTableProps()} />

      {/* {refreshing && <PageSpinner />} */}
      {/* {renderErrors()} */}
    </CardBody>
  );
};

function mapStateToProps({ auth, cases, employees }) {
  return {
    auth,
    cases: cases.serviceAgreements,
    employees: employees.employees,
  };
}

export default connect(mapStateToProps, {
  getServiceAgreements,
  ClearSelectedCase,
  invoiceServiceAgreements,
  invoiceServiceAgreementsSchemaBuilder,
  filterEmployees,
  getUserFilters,
  updateUserFilters,
})(withFeature(ServiceAgreements, [FEATURE.ServiceAgreement]));
