import { createSlice } from '@reduxjs/toolkit';

// Define initial state
const initialState = {
  features: [],
};

// Create a slice
const featuresSlice = createSlice({
  name: 'features',
  initialState,
  reducers: {
    // Reducer function for setting features
    setFeatures(state, action) {
      state.features = action.payload;
    },
  },
});

// Export reducer and action creator
export const { setFeatures } = featuresSlice.actions;

// Export reducer function
export default featuresSlice.reducer;
