import { ACTIVE_MODULES, MODULE_STATUS } from '../redux/constants';

// Utils
import i18n from '../i18n';

export const getModuleTypeName = type => {
  switch (type) {
    case ACTIVE_MODULES.Hotwork:
      return i18n.t(35);
    case ACTIVE_MODULES.QualityInsurance:
      return i18n.t(36);
    case ACTIVE_MODULES.ServiceAgreement:
      return i18n.t(3224);
    default:
      break;
  }
};

export const getColorClass = status => {
  switch (status) {
    case MODULE_STATUS.Active:
      return 'yellow';
    case MODULE_STATUS.Approved:
      return 'green';
    case MODULE_STATUS.Done:
      return 'green';
    case MODULE_STATUS.Draft:
      return 'yellow';
    case MODULE_STATUS.InProgress:
      return 'yellow';
    case MODULE_STATUS.Invoiced:
      return 'green';
    case MODULE_STATUS.Created:
      return 'gray';
    case MODULE_STATUS.InActive:
      return 'red';
    default:
      return 'gray';
  }
};

export const getColorClassHex = status => {
  switch (status) {
    case MODULE_STATUS.Active:
      return '#ffd700';
    case MODULE_STATUS.Approved:
      return '#45b649';
    case MODULE_STATUS.Done:
      return '#45b649';
    case MODULE_STATUS.Draft:
      return '#ffd700';
    case MODULE_STATUS.InProgress:
      return '#ffd700';
    case MODULE_STATUS.Invoiced:
      return '#45b649';
    case MODULE_STATUS.Created:
      return 'gray';
    case MODULE_STATUS.InActive:
      return '#f85032';
    default:
      return 'gray';
  }
};

export const getStatusText = status => {
  switch (status) {
    case MODULE_STATUS.Active:
      return i18n.t(71);
    case MODULE_STATUS.Approved:
      return i18n.t(1001);
    case MODULE_STATUS.Done:
      return i18n.t(1003);
    case MODULE_STATUS.Draft:
      return i18n.t(1004);
    case MODULE_STATUS.InProgress:
      return i18n.t(71);
    case MODULE_STATUS.Invoiced:
      return i18n.t(6074);
    case MODULE_STATUS.Created:
      return i18n.t(1187);
    case MODULE_STATUS.InActive:
      return i18n.t(72);
    default:
      return i18n.t(9063);
  }
};

export const MENU_INDEX = {
  Dashboard: 1,
  Information: 2,
  Description: 3,
  Files: 4,
  Materials: 5,
  Notes: 6,
  AddModule: 100,
  QualityInsurance: 101,
  ServiceAgreement: 102,
  Hotwork: 103,
  FinishCase: 201,
  CopyCase: 202,
  RemoveCase: 203,

  // Modules
  ModuleQualityInsurance: 1001,
  ModuleServiceAgreement: 1002,
  ModuleHotwork: 1003,
  ViewModuleQualityInsurance: 1004,
  ViewModuleServiceAgreement: 1005,
};

export const ACTIVE_INDEX = {
  Unset: 0,
  Dashboard: 1,
  Information: 2,
  Description: 3,
  Files: 4,
  Materials: 5,
  Notes: 6,
  QualityInsurance: 7,
  ServiceAgreement: 8,
  Hotwork: 9,
  FinishCase: 10,
  CopyCase: 11,
  RemoveCase: 12,
  ViewQualityInsurance: 13,
  ViewServiceAgreement: 14,
};
