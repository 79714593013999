import React from 'react';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

// Components
import PageSpinner from '../PageSpinner';

const InfoModal = ({
  showActionSpinner,
  toggle,
  show,
  size,
  header,
  children,
  footer,
}) => {
  let toggleAction = !showActionSpinner ? toggle : null;

  return (
    <Modal
      isOpen={show}
      toggle={toggleAction}
      fade={false}
      size={size}
      centered
    >
      {header && <ModalHeader toggle={toggleAction}>{header}</ModalHeader>}
      <ModalBody>
        {showActionSpinner && (
          <PageSpinner color='primary' extraClass='margin-bot-15px' />
        )}
        {children}
      </ModalBody>
      {footer && <ModalFooter>{footer}</ModalFooter>}
    </Modal>
  );
};

export default InfoModal;
