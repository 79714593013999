import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import i18next from '../i18n';
import {
  CardBody,
  Row,
  UncontrolledButtonDropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from 'reactstrap';
import { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import da from 'date-fns/locale/da';
import withFeature from '../hocs/withFeature';
import moment from 'moment';

// Actions
import { getUserFilters, updateUserFilters } from '../redux/actions/common';
import { ClearSelectedCase } from '../redux/actions/cases';
import { getCasesQualityInsurance } from '../redux/actions/qualityInsurance';
import { getEmployees } from '../redux/actions/employees';
import { getCustomer } from '../redux/actions/customers';

// Utils
import {
  FEATURE,
  CASE_STATE,
  ASSIGNEE_TYPE,
  EMPLOYEE_TYPE,
  INDUSTRY_TYPE,
} from '../redux/constants';
import {
  parseDateRFC2822,
  parseDate,
  strComparator,
  escapeRegex,
} from '../utils/common';
import {
  badgeByModuleStatus,
  getPlaceholderIfEmpty,
  getAddress,
} from '../utils/componentUtils';
import { getUserData } from '../utils/userUtil';
import { generateInitials, addToObject } from '../utils/common';
import { getTooltip } from '../utils/tooltip';
import { hasIndustryType } from '../utils/permissions';

// Components
import ReactDatePicker from '../components/Custom/ReactDatePicker';
import ReactSelectFilter from '../components/Custom/ReactSelectFilter';
import PageSpinner from '../components/PageSpinner';
import SearchInput from '../components/SearchInput';
import ReactTable from '../components/Custom/ReactTable';

//Misc
registerLocale('da', da);

const QualityInsurances = props => {
  const {
    auth,
    history,
    getUserFilters,
    updateUserFilters,
    getCasesQualityInsurance,
    //ClearSelectedCase,
    getEmployees,
    getCustomer,
    employees,
    cases,
    customer,
  } = props;
  const [isLoading, setLoading] = useState(true);
  const [employeeOptions, setEmployeeOptions] = useState([]);
  const [userFilter, setUserFilter] = useState({
    filter: '',
    statusFilter: [],
    statusText: 'Alle',
    filterStartDate: null,
    filterEndDate: null,
    customerFilter: null,
    respFilter: [],
  });
  const user = getUserData(auth);
  const timerRef = useRef(null);

  useEffect(() => {
    if (auth.isAuthenticated) {
      getCasesQualityInsurance().then(res => {
        setLoading(false);
      });
    } else {
      history.push('/login');
    }

    getUserFilters().then(res => {
      if (res.data != null) {
        if (res.data.qualityCasesFilter != null) {
          let json = JSON.parse(res.data.qualityCasesFilter);
          setUserFilter(json.userFilter);
        }
      }
    });

    if (!customer) {
      getCustomer(user.groupId, user.customerId);
    }

    getEmployees(user.customerId).then(res => {
      let options = res.data
        .filter(
          x =>
            x.role === 4 &&
            [
              EMPLOYEE_TYPE.ResponsiblePerson,
              EMPLOYEE_TYPE.ExecutivePerson,
              EMPLOYEE_TYPE.CompanyOwner,
              EMPLOYEE_TYPE.Firewatch,
            ].includes(x.employeeType),
        )
        .sort((a, b) => strComparator(a, b, 'name'))
        .map(y => {
          return { label: y.name, value: y.id };
        });
      options.unshift({ label: i18next.t(9003), value: null });
      setEmployeeOptions(options);
    });
    // eslint-disable-next-line
  }, []);

  const onFilterChange = e => {
    const tmpFilter = { ...userFilter, filter: e.target.value };
    setUserFilter(tmpFilter);

    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }
    let value = e.target.value;
    timerRef.current = setTimeout(() => {
      updateFilters({
        userFilter: { ...userFilter, filter: value },
      });
    }, 1000);
  };

  const onFilterStartDateChange = date => {
    if (Date.parse(parseDateRFC2822(date)) || date === null) {
      const tmpFilter = {
        ...userFilter,
        filterStartDate: date,
      };

      setUserFilter(tmpFilter);

      updateFilters({
        userFilter: tmpFilter,
      });
    }
  };

  const onFilterEndDateChange = date => {
    if (Date.parse(parseDateRFC2822(date)) || date === null) {
      const tmpFilter = {
        ...userFilter,
        filterEndDate: date,
      };

      setUserFilter(tmpFilter);

      updateFilters({
        userFilter: tmpFilter,
      });
    }
  };

  const onFilterCustomerChange = customer => {
    const tmpFilter = { ...userFilter, customerFilter: customer };
    setUserFilter(tmpFilter);
    updateFilters({
      userFilter: tmpFilter,
    });
  };

  const getResponsibleIcon = (resp, targetId) => {
    let icon = null;

    if (resp) {
      let initials = generateInitials(resp.name);
      if (resp.initials) {
        initials = resp.initials.toUpperCase();
      }
      if (employees) {
        let employee = employees.find(x => x.id === resp.userId);
        if (employee) {
          if (employee.initials) {
            initials = employee.initials.toUpperCase();
          }
        }
      }

      icon = <div className='inner'>{initials}</div>;
    }

    return (
      <div data-tip data-for={targetId} className='initials-icon'>
        {icon}
        {getTooltip(targetId, resp.name)}
      </div>
    );
  };

  const updateFilters = data => {
    let dataObj = {
      qualityCasesFilter: JSON.stringify(data),
    };

    updateUserFilters(dataObj).then(res => {});
  };

  const displayCompany = () => {
    if (auth) {
      if (auth.loginAsUser) {
        const user = JSON.parse(auth.loginAsUser);
        return user.loginData.accessLevel <= 2;
      } else {
        return auth.user.accessLevel <= 2;
      }
    } else {
      return false;
    }
  };

  const statusFilterDropdownMenu = () => {
    let options = [
      // { label: i18next.t(1016), value: 'all' },
      { label: i18next.t(1187), value: CASE_STATE.Created },
      { label: i18next.t(71), value: CASE_STATE.Active },
      { label: i18next.t(1003), value: CASE_STATE.Done },
    ];
    return (
      <div className='btn-group m-1 react-select'>
        <ReactSelectFilter
          value={userFilter?.statusFilter}
          options={options}
          onChange={setStatusFilter}
          placeholder={i18next.t(70)}
          closeMenuOnSelect={false}
          isMulti
        />
      </div>
    );
  };

  const setStatusFilter = filter => {
    const tmpFilter = {
      ...userFilter,
      statusFilter: filter,
      //statusText: filter.label,
    };
    setUserFilter(tmpFilter);
    updateFilters({
      userFilter: tmpFilter,
    });
  };

  const setRespFilter = filter => {
    const tmpFilter = {
      ...userFilter,
      respFilter: filter,
    };
    setUserFilter(tmpFilter);
    updateFilters({
      userFilter: tmpFilter,
    });
  };

  const customersFilterDropdownMenu = () => {
    if (!cases) return null;
    if (!displayCompany()) return null;

    let names = [];

    if (cases.length > 0) {
      const nameOptions = cases.map(x => x.customerName);
      nameOptions.sort((a, b) => a.localeCompare(b));

      return (
        <UncontrolledButtonDropdown key='uncontrolled-customer-dropdown'>
          <DropdownToggle caret color='info' className='text-capitalize m-1'>
            {i18next.t(30)}:{' '}
            {userFilter?.customerFilter
              ? userFilter.customerFilter
              : i18next.t(1016)}
          </DropdownToggle>
          <DropdownMenu className='menu-scrollable'>
            <DropdownItem onClick={() => onFilterCustomerChange()}>
              {i18next.t(1016)}
            </DropdownItem>
            {nameOptions.map((name, key) => {
              if (!names.includes(name)) {
                names.push(name);

                return (
                  <DropdownItem
                    onClick={() => onFilterCustomerChange(name)}
                    key={key}
                  >
                    {name}
                  </DropdownItem>
                );
              } else {
                return null;
              }
            })}
          </DropdownMenu>
        </UncontrolledButtonDropdown>
      );
    }
  };

  const responsibleFilter = () => {
    let options = employeeOptions;

    return (
      <div className='btn-group m-1 react-select'>
        <ReactSelectFilter
          value={userFilter.respFilter}
          options={options}
          onChange={setRespFilter}
          placeholder={i18next.t(6419)}
          closeMenuOnSelect={false}
          isMulti
        />
      </div>
    );
  };

  const periodFilter = type => {
    let val =
      parseDateRFC2822(userFilter[type]) !== 'Invalid Date'
        ? parseDateRFC2822(userFilter[type])
        : null;

    const defaultProps = {
      className: 'form-control',
      locale: 'da',
      value: val,
      format: 'dd.MM.yyyy',
    };

    if (type === 'filterStartDate') {
      return (
        <ReactDatePicker
          {...defaultProps}
          onChange={v => onFilterStartDateChange(v)}
          placeholder={i18next.t(110)}
        />
      );
    }

    if (type === 'filterEndDate') {
      return (
        <ReactDatePicker
          {...defaultProps}
          onChange={v => onFilterEndDateChange(v)}
          placeholder={i18next.t(111)}
        />
      );
    }
  };

  const gotoViewCase = _case => {
    history.push(
      `/cases/${_case.masterCaseId}?view=0&type=2&module=2&moduleId=${_case.id}`,
    );
  };

  const gotoCase = (e, _case) => {
    if (e.button === 1) {
      window.open(
        process.env.REACT_APP_LOCAL_URL +
          `/cases/${_case.masterCaseId}?view=0&type=2&module=2&moduleId=${_case.id}`,
        '_blank',
        'noreferrer',
      );
    } else {
      history.push(
        `/cases/${_case.masterCaseId}?view=0&type=2&module=2&moduleId=${_case.id}`,
      );
    }
  };

  const getButtonActionType = _case => {
    switch (_case.status) {
      case 4:
        return () => gotoViewCase(_case);
      default:
        return e => gotoCase(e, _case);
    }
  };

  const getBuildingsStats = ({ controlSchemaGroups, status }) => {
    const renderItem = (done, total) => {
      return (
        <div className='stats-font'>
          <span className='greenText bolded'>{done}</span>/
          <span className='bolded'>{total}</span>
        </div>
      );
    };

    let doneSchemas = 0;
    let totalSchemas = 0;

    if (controlSchemaGroups?.length > 0) {
      controlSchemaGroups.forEach(x => {
        doneSchemas += x.schemas.filter(y => y.isDone).length;
        totalSchemas += x.schemas.length;
      });
    }

    if (status === CASE_STATE.Invoiced) {
      return renderItem(totalSchemas, totalSchemas);
    } else {
      return renderItem(doneSchemas, totalSchemas);
    }
  };

  const getFilteredResults = () => {
    if (!cases) return null;

    const customerFilter = cases.filter(item => {
      if (!userFilter.customerFilter || !displayCompany()) return true;

      try {
        var reg = new RegExp(`^${escapeRegex(userFilter.customerFilter)}`, 'i');
        return reg.test(item.customerName);
      } catch (error) {
        return true;
      }
    });

    // Filter on search term
    let filteredCases = customerFilter.filter(item => {
      try {
        var reg = new RegExp(`${escapeRegex(userFilter.filter)}`, 'i');
        let caseAddress =
          item.projectAddress +
          ' ' +
          item.projectPostal +
          ' ' +
          item.projectCity;

        if (
          reg.test(item.caseNumber) ||
          reg.test(item.caseName) ||
          reg.test(caseAddress)
        ) {
          return true;
        }
        return false;
      } catch (error) {
        return true;
      }
    });

    // Status filtering
    if (userFilter.statusFilter) {
      if (userFilter.statusFilter.length > 0) {
        filteredCases = filteredCases.filter(x =>
          userFilter.statusFilter.find(y => y.value === x.status),
        );
      }
    }

    // Responsible filtering
    if (userFilter.respFilter) {
      if (userFilter.respFilter.length > 0) {
        filteredCases = filteredCases.filter(i => {
          if (
            i.assignees.filter(
              x =>
                userFilter.respFilter.find(y => y.value === x.userId) &&
                x.asigneeType === ASSIGNEE_TYPE.ContactForInternalOrganisation,
            ).length === 0 &&
            !(
              i.assignees.filter(
                x =>
                  x.asigneeType ===
                  ASSIGNEE_TYPE.ContactForInternalOrganisation,
              ).length === 0 &&
              userFilter.respFilter.filter(x => x.value === null).length > 0
            )
          ) {
            return false;
          } else {
            return true;
          }
        });
      }
    }

    // Date filtering
    if (userFilter.filterStartDate && userFilter.filterEndDate) {
      filteredCases = filteredCases.filter(x => {
        return (
          moment(x.startDate).isAfter(userFilter.filterStartDate) &&
          moment(x.endDate)
            .add(-1, 'day')
            .isBefore(userFilter.filterEndDate)
        );
      });
    } else if (userFilter.filterStartDate) {
      filteredCases = filteredCases.filter(x => {
        return moment(moment(x.startDate).format('YYYY-MM-DD'))
          .add(1, 'day')
          .isAfter(userFilter.filterStartDate);
      });
    } else if (userFilter.filterEndDate) {
      filteredCases = filteredCases.filter(x => {
        return moment(moment(x.endDate).format('YYYY-MM-DD'))
          .add(-1, 'day')
          .isBefore(userFilter.filterEndDate);
      });
    }

    // Result
    return filteredCases;
  };

  const getTableProps = () => {
    let headers = [
      {
        title: i18next.t(1000),
        sortable: true,
        selector: 'caseNumber',
        sortType: 'number',
      },
      {
        title: i18next.t(1151),
        sortable: true,
        selector: 'caseName',
        sortType: 'string',
      },
      hasIndustryType(customer?.industryType, [INDUSTRY_TYPE.Roofing])
        ? { title: i18next.t(6419) }
        : null,
      {
        title: i18next.t(1174),
      },
      hasIndustryType(customer?.industryType, [INDUSTRY_TYPE.Roofing])
        ? { title: i18next.t(1012) }
        : null,
      { title: i18next.t(1014) },
      {
        title: i18next.t(21119),
        sortable: true,
        selector: 'lastEdited',
        sortType: 'date',
      },
      {
        title: i18next.t(70),
      },
    ].filter(x => !!x);

    if (displayCompany()) {
      headers.unshift({
        title: i18next.t(30),
        sortable: true,
        selector: 'customerName',
        sortType: 'string',
      });
    }

    return {
      colgroup: ['15%'],
      scrollRender: {
        window: document.getElementById('main-content-wrapper'),
        body: document.getElementById('main-content-id'),
      },
      headers: {
        class: '',
        data: headers,
      },
      body: {
        class: '',
        empty: i18next.t(21399),
        data: getBodyData(),
      },
    };
  };

  const getBodyData = () => {
    const filteredCases = getFilteredResults();
    let bodyData = [];
    filteredCases.forEach((_case, key) => {
      let dateStart = parseDate(_case.startDate);
      let dateEnd = parseDate(_case.endDate);
      let dateText = `${dateStart} ${i18next.t(1019)} ${dateEnd}`;
      if (dateStart === 'Invalid date' || dateEnd === 'Invalid date') {
        dateText = '';
      }

      let respTargetId =
        _case.id +
        '__' +
        _case.assignees.find(
          x => x.asigneeType === ASSIGNEE_TYPE.ContactForInternalOrganisation,
        )?.id;

      const caseNumber = getPlaceholderIfEmpty(_case.caseNumber);
      const caseName = getPlaceholderIfEmpty(_case.caseName);
      const assignees = _case.assignees.find(
        x => x.asigneeType === ASSIGNEE_TYPE.ContactForInternalOrganisation,
      )
        ? getResponsibleIcon(
            _case.assignees.find(
              x =>
                x.asigneeType === ASSIGNEE_TYPE.ContactForInternalOrganisation,
            ),
            respTargetId,
          )
        : null;

      const buildingStats = getBuildingsStats(_case);

      const addresss = getAddress(_case);
      const badge = badgeByModuleStatus(_case.status);
      // const button = getButtonByCaseType(_case);

      const lastEdited =
        moment(_case.lastEditDate).format('DD.MM.YYYY') !== '01.01.0001'
          ? moment(_case.lastEditDate).format('DD.MM.YYYY')
          : moment(_case.creationDate).format('DD.MM.YYYY');

      let dataObj = {
        caseNumber,
        caseName,
        assignees,
        buildingStats,
        addresss,
        dateText,
        lastEdited,
        badge,
        // button,
        _onClick: getButtonActionType(_case),
      };

      if (!hasIndustryType(customer?.industryType, [INDUSTRY_TYPE.Roofing])) {
        delete dataObj.assignees;
        delete dataObj.addresss;
      }

      // Return column
      if (!displayCompany()) {
        bodyData.push(dataObj);
      } else {
        const customerName = _case.customerName;
        dataObj = addToObject(dataObj, 'customerName', customerName, 0);
        bodyData.push(dataObj);
      }
    });

    return bodyData;
  };

  if (isLoading) {
    return <PageSpinner />;
  }

  return (
    <CardBody>
      <Row
        style={{
          marginLeft: '0px',
          marginBottom: '10px',
        }}
      >
        {customersFilterDropdownMenu()}
        <SearchInput onChange={onFilterChange} value={userFilter.filter} />
        {periodFilter('filterStartDate')}
        {periodFilter('filterEndDate')}
        {responsibleFilter()}
        {statusFilterDropdownMenu()}
      </Row>
      {/* Rendering of the data */}
      <ReactTable size='sm' striped data={getTableProps()} />
    </CardBody>
  );
};

function mapStateToProps({ auth, cases, employees, customers }) {
  return {
    auth,
    cases: cases.qualityCases,
    employees: employees.employees,
    customer: customers.customer,
    customers,
  };
}

export default connect(mapStateToProps, {
  getCasesQualityInsurance,
  ClearSelectedCase,
  getUserFilters,
  updateUserFilters,
  getEmployees,
  getCustomer,
})(withFeature(QualityInsurances, [FEATURE.QualityInsurance]));
