import { createSlice } from '@reduxjs/toolkit';

// Define initial state
const initialState = {
  folders: [],
  files: [],
  favorites: [],
  currentFolder: null,
};

// Create a slice
const fileSystemSlice = createSlice({
  name: 'fileSystem',
  initialState,
  reducers: {
    // Reducer function for getting folders
    setFolders(state, action) {
      state.folders = action.payload;
    },
    // Reducer function for getting files
    setFiles(state, action) {
      state.files = action.payload;
    },
    // Reducer function for getting the current folder or setting it
    setCurrentFolder(state, action) {
      state.currentFolder = action.payload;
    },
  },
});

// Export reducers and action creators
export const {
  setFolders,
  setFiles,
  setCurrentFolder,
} = fileSystemSlice.actions;

// Export reducer function
export default fileSystemSlice.reducer;
