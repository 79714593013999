/**
 * @module actions/serviceAgreements
 */
import axios from 'axios';
import FileSaver from 'file-saver';
import { API_URL } from '../constants';
import { setServiceAgreements, setSelectedCase } from '../slices/caseSlice';

/**
 * @description Get list of all service agreements
 * @return {Promise} Axios promise
 */
export function getServiceAgreements(data) {
  let url = API_URL + '/serviceAgreements/search';
  return dispatch => {
    return axios.post(url, data).then(res => {
      dispatch(setServiceAgreements(res.data));
      return res.data;
    });
  };
}

/**
 * @description Get service agreement
 * @return {Promise} Axios promise
 */
export function getServiceAgreement(id) {
  let url = API_URL + '/serviceAgreements/' + id;
  return dispatch => {
    return axios.get(url).then(res => {
      dispatch(setSelectedCase(res.data));
      return res.data;
    });
  };
}

/**
 * @description Create service agreement
 * @return {Promise} Axios promise
 */
export function createServiceAgreement(data) {
  let url = API_URL + '/serviceAgreements';
  return dispatch => {
    return axios.post(url, data);
  };
}

/**
 * @description Create service agreement
 * @return {Promise} Axios promise
 */
export function updateServiceAgreement(id, data) {
  let url = API_URL + `/serviceAgreements/update`;
  return dispatch => {
    return axios.post(url, data);
  };
}

/**
 * @description delete service agreement
 * @return {Promise} Axios promise
 */
export function deleteServiceAgreement(id) {
  let url = API_URL + `/serviceAgreements/delete`;
  return dispatch => {
    return axios.post(url, { id });
  };
}

/**
 * @description Set service agreement state
 * @return {Promise} Axios promise
 */
export function setServiceAgreementState(id, status) {
  let url = API_URL + `/serviceAgreements/state`;
  return dispatch => {
    return axios.post(url, {
      id,
      ...status,
    });
  };
}

/**
 * @description Add schema to service agreement
 * @return {Promise} Axios promise
 */
export function addServiceAgreementSchema(id, data) {
  let url = API_URL + `/serviceAgreements/${id}/schema`;
  return dispatch => {
    return axios.post(url, data);
  };
}

/**
 * @description Update schema to service agreement
 * @return {Promise} Axios promise
 */
export function updateServiceAgreementSchema(id, data) {
  let url = API_URL + `/serviceAgreements/schema/update`;
  return dispatch => {
    return axios.post(url, data);
  };
}

/**
 * @description Set schema as done
 * @return {Promise} Axios promise
 */
export function setServiceAgreementSchemaAsDone(id) {
  let url = API_URL + `/serviceAgreements/schema/${id}/done`;
  return dispatch => {
    return axios.post(url);
  };
}

/**
 * @description Update schema to service agreement
 * @return {Promise} Axios promise
 */
export function updateServiceAgreementSchemaControl(data) {
  let url = API_URL + `/serviceAgreements/schema/control`;
  return dispatch => {
    return axios.post(url, data);
  };
}

/**
 * @description Approve answered, but id-less, controls in service agreement
 * @return {Promise} Axios promise
 */
export function approveServiceAgreementSchemaControl(data) {
  let url = API_URL + `/serviceAgreements/schema/control/approve`;
  return dispatch => {
    return axios.post(url, data);
  };
}

/**
 * @description Approve answered, but id-less, controls in service agreement
 * @return {Promise} Axios promise
 */
export function approveServiceAgreementSchemaControlSb(data) {
  let url = API_URL + `/serviceAgreements/schema/control/approveSb`;
  return dispatch => {
    return axios.post(url, data);
  };
}

/**
 * @description Update control schema answering date
 * @return {Promise} Axios promise
 */
export function updateServiceAgreementControlDate(data) {
  let url = API_URL + `/serviceAgreements/schema/control/setmanualdate`;
  return dispatch => {
    return axios.post(url, data).then(res => {
      return res;
    });
  };
}

/**
 * @description Invoice service agreements
 * @return {Promise} Axios promise
 */
export function invoiceServiceAgreements(data) {
  let url = API_URL + `/serviceAgreements/invoice`;
  return dispatch => {
    return axios.post(url, data);
  };
}

/**
 * @description Invoice service agreements
 * @return {Promise} Axios promise
 */
export function invoiceServiceAgreementsSchemaBuilder(data) {
  let url = API_URL + `/serviceAgreements/invoice-sb`;
  return dispatch => {
    return axios.post(url, data);
  };
}

/**
 * @description Invoice schema in service agreement
 * @return {Promise} Axios promise
 */
export function invoiceServiceAgreementSchema(data) {
  let url = API_URL + `/serviceAgreements/schema/invoice-sb`;
  return dispatch => {
    return axios.post(url, data);
  };
}

/**
 * @description Get service agreement pdf filter
 * @param {Number} caseId
 * @return {Promise} Axios promise
 */
export function getServiceAgreementPdfFilter(caseId) {
  let url = API_URL + `/pdf/serviceAgreementFilter/${caseId}`;
  return dispatch => {
    return axios.get(url);
  };
}
export function getServiceAgreementTokenPdfFilter(caseId) {
  let url = API_URL + `/pdf/serviceAgreementFilter/token/${caseId}`;
  return dispatch => {
    return axios.get(url);
  };
}

/**
 * @description Download case pdf
 * @return {Promise} Axios promise
 */
export function downloadSAPdf(caseObj, filter) {
  let url = API_URL + `/pdf/download/sa/${caseObj.id}`;
  return dispatch => {
    return axios.post(url, filter, { responseType: 'blob' }).then(res => {
      let fileName = '';

      if (res.data.type === 'text/plain') {
        //console.log('output');
        return res.data.text();
      }

      if (caseObj.documentName?.length > 0) {
        fileName = caseObj.documentName;
      } else {
        const regex = /filename=["]?(.*\.pdf)["]?;/g;
        const headerContentDisposition = res.headers['content-disposition'];
        const fileNameGroups = regex.exec(headerContentDisposition);
        // console.log('download', fileNameGroups, headerContentDisposition)

        fileName = fileNameGroups[1];
      }

      FileSaver.saveAs(res.data, `${fileName}.pdf`);
    });
  };
}
