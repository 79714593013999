import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Button, Spinner } from 'reactstrap';

// Actions
import {
  createMasterCaseSimple,
  updateCurrentModules,
} from '../../redux/actions/cases';

// Utils
import { ACTIVE_MODULES } from '../../redux/constants';
import i18n from '../../i18n';

// Components
import Typography from '../Typography';
import InfoModal from '../Custom/InfoModal';
import {
  IconKvalitetssikring,
  IconServiceaftale,
  IconVarmtArbejde,
} from '../../assets/icons_v2';
import toast from '../../utils/toast';
import InfoTip from '../Custom/InfoTip';

const AddModulesModal = ({
  isUpdate,
  cases,
  show,
  toggle,
  createMasterCaseSimple,
  updateCurrentModules,
  history,
}) => {
  const { currentMasterCase } = cases;
  const [isLoading, setLoading] = useState(true);
  const [modules, setModules] = useState([]);
  const [selectedModules, setSelectedModules] = useState([]);

  useEffect(() => {
    const tmpModules = [
      {
        type: ACTIVE_MODULES.Hotwork,
        icon: <IconVarmtArbejde />,
        title: i18n.t(35),
        subtitle: i18n.t(21329),
        small: i18n.t(21330),
        info: { title: 'Lorem Ipsum', text: 'Hello world' },
        index: 2,
      },
      {
        type: ACTIVE_MODULES.QualityInsurance,
        icon: <IconKvalitetssikring />,
        title: i18n.t(36),
        subtitle: i18n.t(21331),
        small: i18n.t(21328),
        info: { title: 'Lorem Ipsum', text: 'Hello world' },
        index: 1,
      },
      {
        type: ACTIVE_MODULES.ServiceAgreement,
        icon: <IconServiceaftale />,
        title: i18n.t(6075),
        subtitle: i18n.t(21331),
        small: i18n.t(21332),
        info: { title: 'Lorem Ipsum', text: 'Hello world' },
        index: 3,
      },
    ];

    tmpModules.sort((a, b) => a.index - b.index);

    if (
      isUpdate &&
      !!currentMasterCase?.activeModules &&
      currentMasterCase?.activeModules?.trim().length > 0
    ) {
      const curMods = currentMasterCase.activeModules
        .split(',')
        .filter(x => x.length > 0);

      const filtered = tmpModules.filter(x => !curMods.includes(x.type + ''));
      setModules(filtered);
    } else {
      setModules(tmpModules);
    }
    setLoading(false);
    // eslint-disable-next-line
  }, [currentMasterCase]);

  const handleCreate = () => {
    const dataObj = {
      modules: selectedModules,
    };

    setLoading(true);
    createMasterCaseSimple(dataObj)
      .then(res => {
        history.push('/cases/' + res.data.id + '?view=2');
      })
      .catch(err => {
        toast.error(i18n.t(212));
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleUpdate = () => {
    setLoading(true);

    const curMods = !!currentMasterCase.activeModules
      ? currentMasterCase.activeModules
          .split(',')
          .filter(x => x.length > 0)
          .map(x => parseInt(x))
      : [];

    const tmp = [...new Set([...selectedModules.map(x => x), ...curMods])];
    const tmpStr = tmp.join(',');

    const dataObj = {
      ...currentMasterCase,
      activeModules: tmpStr,
    };

    updateCurrentModules(currentMasterCase.id, dataObj, tmp)
      .then(() => {
        toast.success(i18n.t(21393));
        toggle();
      })
      .catch(err => console.log(err))
      .finally(() => setLoading(false));
  };

  const addRemoveModule = (type, remove) => {
    if (remove) {
      setSelectedModules(selectedModules.filter(x => x !== type));
    } else {
      setSelectedModules([...selectedModules, type]);
    }
  };

  const renderModule = (
    { type, icon, title, subtitle, small, info },
    index,
  ) => {
    const isSelected = selectedModules.includes(type);

    let infoTip;
    if (!!info) {
      infoTip = (
        <InfoTip
          id={'m-' + type}
          placement={'top'}
          title={info.title}
          text={info.text}
        />
      );
    }

    return (
      <div className='module-wrap'>
        <div
          key={index}
          className={'module' + (isSelected ? ' active' : '')}
          onClick={() => addRemoveModule(type, isSelected)}
        >
          <div className='icon-wrap'>
            <div className='icon'>{icon}</div>
          </div>
          <div className='info'>
            <div className='title'>{title}</div>
            <div className='tip-wrap'>
              <div className='subtitle-small-tip'>
                <div className='subtitle'>{subtitle}</div>
                <div className='small'>{small}</div>
              </div>
              <div className='info-tip'>{infoTip}</div>
            </div>
          </div>
        </div>
        <div className={'selected' + (isSelected ? ' active' : '')} />
      </div>
    );
  };

  const renderModules = () => {
    return (
      <div className='modules-modal-content-wrap'>
        <Typography>{i18n.t(21335)}</Typography>

        <Typography>
          <strong>{i18n.t(21336)}</strong>
        </Typography>
        <div className='modules'>{modules.map(renderModule)}</div>
      </div>
    );
  };

  const renderFooter = () => {
    const action = isUpdate ? handleUpdate : handleCreate;
    const actionTxt = isUpdate ? 21334 : 1049;

    return (
      <div>
        <Button
          color='btnPrimary'
          className='float-right'
          onClick={action}
          disabled={isLoading}
        >
          {i18n.t(actionTxt)}
        </Button>
        <Button
          color='btnSecondary'
          outline
          className='float-right margin-right-10px'
          onClick={toggle}
          disabled={isLoading}
        >
          {i18n.t(2)}
        </Button>
        {isLoading && <Spinner className='float-right margin-right-10px' />}
      </div>
    );
  };

  const renderHeader = () => {
    return i18n.t(21333);
  };

  return (
    <InfoModal
      show={show}
      //showActionSpinner={isLoading}
      toggle={toggle}
      header={renderHeader()}
      footer={renderFooter()}
      size='xl'
    >
      {renderModules()}
    </InfoModal>
  );
};

function mapStatToProps({ cases }) {
  return {
    cases,
  };
}

export default connect(mapStatToProps, {
  createMasterCaseSimple,
  updateCurrentModules,
})(AddModulesModal);
