/**
 * @module actions/auth
 */
import axios from 'axios';
import { API_URL, TEAM_UPDATE } from '../constants';
import { setTeams } from '../slices/teamSlice';

/**
 * @description Get teams
 * @return {Promise} Promise
 */
export function getTeams() {
  let url = API_URL + '/team';
  return dispatch => {
    return axios.get(url).then(res => {
      dispatch(setTeams(res.data));
      return res;
    });
  };
}

/**
 * @description Get all teams
 * @return {Promise} Promise
 */
export function getAllTeams() {
  let url = API_URL + '/team/all';
  return dispatch => {
    return axios.get(url);
  };
}

/**
 * @description Create team
 * @return {Promise} Promise
 */
export function createTeam(data) {
  let url = API_URL + '/team';

  data.type = TEAM_UPDATE.CreateTeam;

  return dispatch => {
    return axios.post(url, data);
  };
}

/**
 * @description Update team
 * @return {Promise} Promise
 */
export function updateTeam(data) {
  let url = API_URL + '/team';

  data.type = TEAM_UPDATE.TeamUpdate;

  return dispatch => {
    return axios.post(url, data);
  };
}

/**
 * @description Delete team
 * @return {Promise} Promise
 */
export function deleteTeam(data) {
  let url = API_URL + '/team';

  data.type = TEAM_UPDATE.TeamDelete;

  return dispatch => {
    return axios.post(url, data);
  };
}

/**
 * @description Add team member
 * @return {Promise} Promise
 */
export function addTeamMember(data) {
  let url = API_URL + '/team/members';

  data.type = TEAM_UPDATE.AddMember;

  return dispatch => {
    return axios.post(url, data);
  };
}

/**
 * @description Remove team member
 * @return {Promise} Promise
 */
export function removeTeamMember(data) {
  let url = API_URL + '/team/members';

  data.type = TEAM_UPDATE.RemoveMember;

  return dispatch => {
    return axios.post(url, data);
  };
}
