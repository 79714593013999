/**
 * @module actions/auth
 */
import axios from 'axios';
import {
  setAuthorizationToken,
  setDeviceToken,
} from './../../utils/setAuthorizationToken';
import { API_URL } from './../constants';

// RTK slices
import {
  logOutAs,
  setCurrentUser,
  setHandledPausedModal,
  setLoginAs,
  setPausedModal,
  setPermissionSet,
} from '../slices/authSlice';
import { setCustomerStatus } from '../slices/customerSlice';
import { setBindings } from '../slices/invitationSlice';

/**
 * @description Logs out user by removing localstorage data, removing Axios authorization and dispatching setCurrentUser with empty Object
 */
export function logout() {
  return dispatch => {
    window.localStorage.removeItem('user_token');
    window.localStorage.removeItem('user_data');
    window.localStorage.removeItem('user_branding');
    window.localStorage.removeItem('loggedInAs');
    setAuthorizationToken(false);
    setDeviceToken(false);
    dispatch(setCurrentUser({}));
    dispatch(logOutAs(null));
  };
}

/**
 * @description Sends a request to have the password reset.
 * @param  {Object} data Object that will be posted to url
 */
export function forgotpassword(data) {
  return dispatch => {
    return axios.post(API_URL + '/auth/forgotpassword', data).then(res => {
      return res;
    });
  };
}

/**
 * @description Sends the password reset information
 * @param  {Object} data Object that will be posted to url
 */
export function resetpassword(data) {
  return dispatch => {
    return axios.post(API_URL + '/auth/resetpassword', data).then(res => {
      return res;
    });
  };
}

/**
 * @description Logs in user by adding localstorage data, setting Axios authorization and dispatching setCurrentUser with user object
 * @param  {Object} data Object that will be posted to url
 */
export function login(data) {
  return dispatch => {
    //window.localStorage.clear();
    return axios.post(API_URL + '/auth/login', data).then(res => {
      setLocalAuthenticationData(dispatch, res);
      return res;
    });
  };
}

/**
 * @description Logs in user after they've reset their password.
 * @param  {Object} data Object that will be posted to url
 */
export function loginReset(res) {
  return dispatch => {
    return new Promise((resolve, reject) => {
      setLocalAuthenticationData(dispatch, res);
      resolve(res);
    });
  };
}

/**
 * @description Login as a group admin user
 * @param  {Object} data Object that will be posted to url
 */
export function loginAs(data) {
  return dispatch => {
    return axios.post(API_URL + '/auth/loginas', data).then(res => {
      const token = res.data.loginData.token;
      const _data = JSON.stringify(res.data);
      setAuthorizationToken(token);
      setDeviceToken(res.data.DeviceToken);
      window.localStorage.setItem('loggedInAs', _data);

      dispatch(setLoginAs(_data));
      dispatch(setCustomerStatus(res.data.customerStatus));

      return res;
    });
  };
}

/**
 * @description Log out as a group admin user
 * @param  {Object} data Object that will be posted to url
 */
export function loginOutAs() {
  return dispatch => {
    window.localStorage.removeItem('loggedInAs');
    const token = window.localStorage.getItem('user_token');
    const data = JSON.parse(window.localStorage.getItem('user_data'));

    setAuthorizationToken(token);
    setDeviceToken(data.DeviceToken);
    dispatch(logOutAs());
  };
}

/**
 * @description Changes the password for user
 */
export function changePassword(data) {
  let url = API_URL + '/auth/ChangePassword';
  return dispatch => {
    return axios.post(url, data);
  };
}

export function togglePausedModal(value) {
  return dispatch => {
    dispatch(setPausedModal(value));
  };
}

export function handledPausedModal(value) {
  return dispatch => {
    dispatch(setHandledPausedModal(value));
  };
}

export function setUserPermissions(data) {
  return dispatch => {
    dispatch(setPermissionSet(data));
  };
}

const setLocalAuthenticationData = (dispatch, res) => {
  const token = res.data.token;
  const data = JSON.stringify(res.data);
  window.localStorage.setItem('user_token', token);
  window.localStorage.setItem('user_data', data);

  setAuthorizationToken(token);
  setDeviceToken(res.data.DeviceToken);

  dispatch(setCurrentUser(res.data));
};

/**
 * Get the users bindings
 */
export function getCompanyBindings(options) {
  let url = API_URL + '/user/bindings';

  let customers = false;
  let user = false;

  if (options?.customer) {
    customers = true;
  }
  if (options?.user) {
    user = true;
  }

  url += `?includeCustomer=${customers}&includeUser=${user}`;

  return dispatch => {
    return axios.get(url).then(res => {
      dispatch(setBindings(res.data));
      // Update localstorage token and redux if binding has new permissions
      if (options?.userData) {
        //console.log(options?.userData, res.data);

        let binding = res.data.find(
          x => x.customer.id === options.userData.user.belongsToCustomerId,
        );

        if (binding) {
          let obj = { ...options.userData };

          obj.user.hasAP = binding.details.hasAP;
          obj.user.hasPdf = binding.details.hasPdf;
          obj.user.schemaPackagesStringArray =
            binding.details.schemaPackagesStringArray;
          obj.user.permission = binding.details.permissions;
          dispatch(setCurrentUser(obj));
        }
      }

      return res;
    });
  };
}

/**
 * Switch company
 */
export function switchCompany(data) {
  let url = API_URL + '/auth/switch';
  return dispatch => {
    return axios.post(url, data).then(res => {
      setLocalAuthenticationData(dispatch, res);
      return res;
    });
  };
}

/**
 * Logout of company - Does not POST! Is more of a utility method
 */
export function logoutOfCustomer(data) {
  return dispatch => {
    return new Promise((resolve, reject) => {
      try {
        setLocalAuthenticationData(dispatch, data);
        resolve();
      } catch (error) {
        reject(error);
      }
    });
  };
}

/**
 * Verify the sms code of the user needing sms verification
 */
export function verifySms(data) {
  let url = API_URL + '/auth/verifySms';
  return dispatch => {
    return axios.post(url, data).then(res => {
      setLocalAuthenticationData(dispatch, res);
      return res;
    });
  };
}

/**
 * Resend sms to the user in need of sms verification
 */
export function resendSms(data) {
  let url = API_URL + '/auth/resendSms';
  return dispatch => {
    return axios.post(url, data).then(res => {
      //setLocalAuthenticationData(dispatch, res);
      return res;
    });
  };
}
