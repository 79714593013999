/**
 * @module actions/cases
 */
import axios from 'axios';
import { API_URL } from '../constants';
import { setQualityCases, setSelectedCase } from '../slices/caseSlice';

/**
 * @description Get list of all qualityinsurance cases
 * @return {Promise} Axios promise
 */
export function getCasesQualityInsurance(category = 0) {
  let url = API_URL + '/qualitycases/search';
  return dispatch => {
    return axios.post(url, { Category: category }).then(res => {
      dispatch(setQualityCases(res.data));
      return res.data;
    });
  };
}

/**
 * @description Get qualityinsurance case
 * @return {Promise} Axios promise
 */
export function getQualityInsuranceCase(id) {
  let url = API_URL + '/qualitycases/case/' + id;
  return dispatch => {
    return axios.get(url).then(res => {
      dispatch(setSelectedCase(res.data));
      return res.data;
    });
  };
}

/**
 * @description Copy qualityinsurance case
 * @return {Promise} Axios promise
 */
export function copyQualityInsuranceCase(data) {
  let url = API_URL + `/qualitycases/case/copy`;
  return dispatch => {
    return axios.post(url, data).then(res => {
      dispatch(setSelectedCase(res.data));
      return res;
    });
  };
}

/**
 * @description Create quality insurance case
 * @return {Promise} Axios promise
 */
export function createQualityInsuranceCase(data) {
  let url = API_URL + '/qualitycases/case';
  return dispatch => {
    return axios.post(url, data);
  };
}

/**
 * @description Update quality insurance case
 * @return {Promise} Axios promise
 */
export function updateQualityInsuranceCase(id, data) {
  let url = API_URL + `/qualitycases/case/${id}/update`;
  return dispatch => {
    return axios.post(url, data).then(res => {
      dispatch(setSelectedCase(res.data));
    });
  };
}

/**
 * @description Delete quality insurance case
 * @return {Promise} Axios promise
 */
export function deleteQualityInsuranceCase(id) {
  let url = API_URL + `/qualitycases/case/${id}/delete`;
  return dispatch => {
    return axios.post(url, null);
  };
}

/**
 * @description Update quality insurance case state
 * @return {Promise} Axios promise
 */
export function updateQualityInsuranceCaseState(id, data) {
  let url = API_URL + `/qualitycases/case/${id}/state`;
  return dispatch => {
    return axios.post(url, data);
  };
}
