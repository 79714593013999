import { createSlice } from '@reduxjs/toolkit';

// Define initial state
const initialState = {
  customerBranding: null,
};

// Create a slice
const customerBrandingSlice = createSlice({
  name: 'customerBranding',
  initialState,
  reducers: {
    // Reducer function for setting customer branding
    setCustomerBranding(state, action) {
      state.customerBranding = action.payload;
    },
  },
});

// Export reducer and action creators
export const { setCustomerBranding } = customerBrandingSlice.actions;
export default customerBrandingSlice.reducer;
