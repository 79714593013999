import { createSlice } from '@reduxjs/toolkit';
import { DATE_TYPE } from '../constants';

// Define initial state
const initialState = {
  roadmapData: null,
  roadmapMaterialsData: null,
  dateInterval: DATE_TYPE.MONTHS,
  timelineRendered: false,
  timeIndicatorRendered: false,
  reRenderLocked: false,
  infoScreenData: null,
  showInfoScreen: false,
  sideMenuErrors: [],
  infoBar: null,
  materialsRendered: false,
};

// Create a slice
const roadmapSlice = createSlice({
  name: 'roadmap',
  initialState,
  reducers: {
    // Reducer function for getting roadmap data
    setRoadmapData(state, action) {
      state.roadmapData = action.payload;
    },
    // Reducer function for getting roadmap materials data
    setRoadmapMaterialsData(state, action) {
      state.roadmapMaterialsData = action.payload;
    },
    // Reducer function for setting roadmap date interval
    setRoadmapDateInterval(state, action) {
      state.dateInterval = action.payload;
    },
    // Reducer function for setting timeline rendering status
    setTimelineRendered(state, action) {
      state.timelineRendered = action.payload;
    },
    // Reducer function for setting time indicator rendering status
    setTimeIndicatorRendered(state, action) {
      state.timeIndicatorRendered = action.payload;
    },
    // Reducer function for setting re-render lock state
    setReRenderLocked(state, action) {
      state.reRenderLocked = action.payload;
    },
    // Reducer function for getting info screen data
    setInfoScreen(state, action) {
      state.infoScreenData = action.payload;
    },
    // Reducer function for toggling info screen visibility
    toggleShowInfoScreen(state, action) {
      state.showInfoScreen = action.payload;
    },
    // Reducer function for setting side menu errors
    setSideMenuErrors(state, action) {
      state.sideMenuErrors = action.payload;
    },
    // Reducer function for setting info bar data
    setInfoBar(state, action) {
      state.infoBar = action.payload;
    },
    // Reducer function for setting materials rendering status
    setMaterialsRendered(state, action) {
      state.materialsRendered = action.payload;
    },
  },
});

// Export reducers and action creators
export const {
  setRoadmapData,
  setRoadmapMaterialsData,
  setRoadmapDateInterval,
  setTimelineRendered,
  setTimeIndicatorRendered,
  setReRenderLocked,
  setInfoScreen,
  toggleShowInfoScreen,
  setSideMenuErrors,
  setInfoBar,
  setMaterialsRendered,
} = roadmapSlice.actions;

// Export reducer function
export default roadmapSlice.reducer;
