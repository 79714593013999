/**
 * @module actions/schemas
 */
import axios from 'axios';
import { API_URL } from '../constants';

// RTK
import { setCurrentUser } from '../slices/authSlice';
import { setEmployees } from '../slices/employeeSlice';
import {
  setSchemaGroups,
  setSchemaOptions,
  setMarkedSchemas,
  refreshGroup,
} from '../slices/schemasSlice';

import { parseControlItemsLang } from '../../utils/translation/langauges';
import { getUserData } from '../../utils/userUtil';
import { preprocessControlItemsAndGroups } from '../../utils/schemaBuilderUtils';
import { setAllImageReferences } from '../slices/markingsSlice';

const ROUTE = API_URL + '/controlschemas';

const PATHS = {
  Groups: ROUTE + '/groups',
  Group: ROUTE + '/group',
  Schemas: ROUTE + '/schemas',
  Controls: ROUTE + '/controls',
};

////////////////////////////////////////////////////////////////////////

export function getSchemaOptions(data) {
  const url = ROUTE + '/options';
  return dispatch => {
    return axios.post(url, data).then(res => {
      dispatch(setSchemaOptions(res.data));

      return res;
    });
  };
}

////////////////////////////////////////////////////////////////////////

export function getSchemaGroups(data) {
  const url = PATHS.Group;
  return dispatch => {
    return axios.post(url, data).then(res => {
      // Parse language display
      let out = parseControlItemsLang(res.data);

      // Parse groupings
      out = preprocessControlItemsAndGroups(out);
      // console.log(out);

      dispatch(setSchemaGroups(out));
      return out;
    });
  };
}

export function refreshSchemaGroup(data) {
  const url = ROUTE + '/singular';
  return dispatch => {
    return axios.post(url, data).then(res => {
      // Parse language display
      let out = parseControlItemsLang(res.data);

      // Parse groupings
      out = preprocessControlItemsAndGroups(out);
      // console.log(out);

      dispatch(refreshGroup(out));
      return out;
    });
  };
}

export function addSchemaGroup(data) {
  const url = PATHS.Groups;
  return dispatch => {
    return axios.post(url, data);
  };
}

export function updateSchemaGroup(data) {
  const url = PATHS.Groups;
  return dispatch => {
    return axios.put(url, data);
  };
}

export function removeSchemaGroup(data) {
  const url = PATHS.Groups;
  return dispatch => {
    return axios.delete(url, { data });
  };
}

export function removeSchemaGroups(data) {
  const url = PATHS.Groups + '-list';
  return dispatch => {
    return axios.delete(url, { data });
  };
}

////////////////////////////////////////////////////////////////////////

export function addControlSchema(data) {
  const url = PATHS.Schemas;
  return dispatch => {
    return axios.post(url, data);
  };
}

export function addControlSchemasToServiceAgreement(data) {
  const url = PATHS.Schemas + '-sa';
  return dispatch => {
    return axios.post(url, data);
  };
}

export function updateControlSchema(data) {
  const url = PATHS.Schemas;
  return dispatch => {
    return axios.put(url, data);
  };
}

export function removeControlSchema(data) {
  const url = PATHS.Schemas;
  return dispatch => {
    return axios.delete(url, { data });
  };
}

export function removeControlSchemas(data) {
  const url = PATHS.Schemas + '-list';
  return dispatch => {
    return axios.delete(url, { data });
  };
}

export function moveControlSchema(data) {
  const url = PATHS.Schemas + '/move';
  return dispatch => {
    return axios.post(url, data);
  };
}

export function duplicateControlSchema(data) {
  const url = PATHS.Schemas + '/dupe';
  return dispatch => {
    return axios.post(url, data);
  };
}

export function updateControlSchemaToNewest(data) {
  const url = PATHS.Schemas + '/newest';
  return dispatch => {
    return axios.post(url, data);
  };
}

export function sendControlsSignatureNotifications(data) {
  const url = PATHS.Schemas + '/notis';
  return dispatch => {
    return axios.post(url, data);
  };
}

export function getSigningSchemas(token) {
  const url = PATHS.Schemas + '/signing/' + token;
  return dispatch => {
    return axios.get(url).then(res => {
      // Parse language display
      let out = parseControlItemsLang(res.data.schemas);

      // Parse groupings
      out = preprocessControlItemsAndGroups(out);

      dispatch(setSchemaGroups(out));
      dispatch(setSchemaOptions(res.data.options));
      dispatch(setEmployees(res.data.employees));
      if (!!res.data.imageReferences) {
        dispatch(setAllImageReferences(res.data.imageReferences));
      }

      let tmpUser = { ...getUserData(), id: res.data.userId };
      tmpUser.user = { ...tmpUser };
      dispatch(setCurrentUser(tmpUser));
    });
  };
}

export function checkForExistingSchemaTokens(data) {
  const url = PATHS.Schemas + '/token-check';
  return dispatch => {
    return axios.post(url, data);
  };
}

export function resendControlsSignatureNotification(data) {
  const url = PATHS.Schemas + '/re-notis';
  return dispatch => {
    return axios.post(url, data);
  };
}

////////////////////////////////////////////////////////////////////////

export function updateControlItem(data) {
  const url = PATHS.Controls;
  return dispatch => {
    return axios.put(url, data);
  };
}

export function updateControlItemViaToken(data, token) {
  const url = PATHS.Controls + '/token/' + token;
  return dispatch => {
    return axios.put(url, data);
  };
}

export function setControlItemManualAnswerDate(data) {
  const url = PATHS.Controls + '-date';
  return dispatch => {
    return axios.put(url, data);
  };
}

export function addImageToControlItem(data) {
  const url = PATHS.Controls + '/image';
  return dispatch => {
    return axios.post(url, data);
  };
}

export function moveImagesToNewControlItem(data) {
  const url = PATHS.Controls + '/image';
  return dispatch => {
    return axios.put(url, data);
  };
}

////////////////////////////////////////////////////////////////////////
export function setMarked(data) {
  return dispatch => {
    dispatch(setMarkedSchemas(data));
  };
}
