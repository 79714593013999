import React, { useState, useEffect } from 'react';
import i18next from '../../i18n';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux';
import { Button, FormText } from 'reactstrap';
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';
import { isMobile } from 'react-device-detect';
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from 'react-icons/md';
import toast from '../../utils/toast';

// Actions
import { bulkFileInfoUpdate } from '../../redux/actions/files';

// Utils
import { getImageRotated } from '../../utils/componentUtils';

// Componenets
import ImageMarker from './Forms/ImageMarker';
import ConfirmModal from './ConfirmModal';
import TextInput from './Forms/TextInput';
import CreateModal from './CreateModal';
import PageSpinner from '../PageSpinner';

const ImgViewerModal = props => {
  const {
    //control,
    files,
    //currentFile,
    disabled,
    // updateFileInfo,
    // handleInfo,
    // saveFile,
    marking,
    toggle,
    show,
    saveMarkings,
    //isSaving,
    startIndex,
  } = props;

  const [isLoading, setLoading] = useState(true);
  const [isSaving, setSaving] = useState(false);
  const [imageIndex, setImageIndex] = useState(!!startIndex ? startIndex : 0);
  const [currentImage, setImage] = useState(null);

  const [imageHash, setHash] = useState(Date.now());
  const [rotations, setRotations] = useState({});
  const [fileInfos, setFileInfos] = useState({});

  const [showConfirm, setShowConfirm] = useState(false);
  const maxLength = 50;

  useEffect(() => {
    if (files?.length > 0) {
      let img = files[startIndex];
      setImage(img);
      setLoading(false);
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (files?.length > 0) {
      let img = files[imageIndex];
      if (!!img && !!rotations[img.id]?.img) {
        img.fileUrl = rotations[img.id].img;
      }
      setImage(img);
      setHash(Date.now());
    } else {
      setImage(null);
    }
    // eslint-disable-next-line
  }, [imageIndex]);

  const galleryNavigate = dir => {
    let index = imageIndex;
    const length = files.length;

    if (index === length - 1 && dir === 1) {
      // Go to beginning
      index = 0;
    } else if (index === 0 && dir === -1) {
      // Go to last
      index = length - 1;
    } else {
      index += dir;
    }

    setImageIndex(index);
  };

  const onDelete = fileId => {
    setShowConfirm(false);
    if (props.onDelete) {
      props.onDelete(fileId);
    }
  };

  const onSaveFileInfos = () => {
    setSaving(true);

    const onSaveCallback = () => {
      setSaving(false);
      toast.success(i18next.t(21279));
    };

    const errorCallback = () => {
      toast.error(i18next.t(212));
    };

    onUpdateFileInfos(onSaveCallback, errorCallback);
  };

  const onUpdateFileInfos = (callback, errorCallback) => {
    const items = files.map(file => {
      const id = file.id;

      let tmpFile = {
        ...file,
      };

      if (fileInfos[id]) {
        tmpFile = {
          ...tmpFile,
          ...fileInfos[id],
        };
      }

      return {
        file: tmpFile,
        rotation: rotations[id]?.r ?? 0,
      };
    });

    let dataObj = items.map(x => {
      return { ...x.file, cached: true };
    });

    props
      .bulkFileInfoUpdate({ items })
      .then(() => {
        if (callback) {
          callback();
        }
        props.handleInfoBulk(dataObj);
      })
      .catch(err => {
        console.log(err);

        if (errorCallback) {
          errorCallback();
        }
      });
  };

  const onRotate = () => {
    let updatedRotations = {
      ...rotations,
    };
    if (!updatedRotations[currentImage.id]?.r) {
      updatedRotations[currentImage.id] = {
        r: 90,
      };
    } else {
      let degrees = updatedRotations[currentImage.id].r;
      degrees += 90;
      if (degrees > 270) {
        degrees = 0;
      }

      updatedRotations[currentImage.id].r = degrees;
    }

    const tmpImage = files.find(x => x.id === currentImage.id);
    getImageRotated(
      tmpImage,
      updatedRotations[currentImage.id].r,
      (img, height, width) => {
        updatedRotations[currentImage.id].img = img;
        setRotations(updatedRotations);

        let updatedInfos = { ...fileInfos };
        let info = updatedInfos[currentImage.id];
        if (!info) {
          updatedInfos[currentImage.id] = {
            ...updatedInfos[currentImage.id],
            fileUrl: img,
            height,
            width,
          };
        } else {
          updatedInfos[currentImage.id] = {
            ...currentImage,
            fileUrl: img,
            height,
            width,
          };
        }

        setFileInfos(updatedInfos);
        setImage({ ...currentImage, fileUrl: img, height, width });
      },
    );
  };

  const downloadImageAction = () => {
    fetch(currentImage.fileUrl)
      .then(response => response.blob())
      .then(blob => {
        const imageUrl = URL.createObjectURL(blob);
        const tag = document.createElement('a');

        tag.download = currentImage.fileName;
        tag.href = imageUrl;

        document.body.appendChild(tag);
        tag.click();
        document.body.removeChild(tag);
      });
  };

  const onChangeFileInfo = e => {
    let updatedInfos = { ...fileInfos };

    let info = updatedInfos[currentImage.id];
    if (!info) {
      updatedInfos[currentImage.id] = {
        [e.target.name]: e.target.value,
      };
    } else {
      updatedInfos[currentImage.id][e.target.name] = e.target.value;
    }

    setFileInfos(updatedInfos);
  };

  const toggleConfirmDelete = () => {
    setShowConfirm(!showConfirm);
  };

  const renderImageActions = () => {
    return (
      <div className='functions'>
        {!disabled && (
          //
          <Button className='float-left' color='success' onClick={onRotate}>
            {i18next.t(1361)}
          </Button>
          // </div>
        )}
        {/* <div className='save-menu'> */}
        {props.onDelete && !disabled && (
          <Button
            outline
            color='danger'
            style={{ float: 'right', marginLeft: '5px' }}
            onClick={toggleConfirmDelete}
          >
            {i18next.t(147)}
          </Button>
        )}
        <Button
          outline
          color='btnSecondary'
          style={{ float: 'right', marginLeft: '5px' }}
          onClick={downloadImageAction}
        >
          {i18next.t(3242)}
        </Button>
        {!disabled && (
          <Button
            color='success'
            style={{ float: 'right', marginLeft: '5px' }}
            disabled={isSaving}
            onClick={() => {
              onSaveFileInfos();
            }}
          >
            {i18next.t(1180)}
          </Button>
        )}
      </div>
    );
  };

  const onContentClick = e => {
    e.stopPropagation();
  };

  const renderContent = () => {
    if (isLoading) {
      return <PageSpinner />;
    }

    let tmpFileDescription = currentImage?.fileDescription;
    if (!!currentImage) {
      if (fileInfos[currentImage.id]) {
        tmpFileDescription = fileInfos[currentImage.id].fileDescription;
      }
    }

    return (
      <React.Fragment>
        {/* Description input */}
        {currentImage.documentType !== 3 && (
          <div className='margin-top-10px'>
            {/* <TextInput
                for={i18next.t(7170)}
                placeholder={i18next.t(7170)}
                name='fileTitle'
                onChange={changeTitle}
                value={fileTitle}
                disabled={disabled}
              /> */}
            <TextInput
              for={i18next.t(43)}
              placeholder={i18next.t(43)}
              name='fileDescription'
              onChange={onChangeFileInfo}
              value={tmpFileDescription}
              disabled={disabled}
            />
            {!disabled && (
              <FormText style={{ flex: 1 }}>
                {i18next.t(4002)}:{' '}
                {tmpFileDescription ? tmpFileDescription.length : 0}/{maxLength}
              </FormText>
            )}
          </div>
        )}
        <hr />
        <div className={'imgViewer__container' + (isMobile ? ' mobile' : '')}>
          {!isMobile && (
            <React.Fragment>
              <img
                src={currentImage.fileUrl}
                alt={currentImage.fileTitle}
                className='imgViewer__img'
                key={imageHash}
              />
              <div className='nav-prev' onClick={() => galleryNavigate(-1)}>
                <MdKeyboardArrowLeft size={30} />
              </div>
              <div className='nav-next' onClick={() => galleryNavigate(1)}>
                <MdKeyboardArrowRight size={30} />
              </div>
            </React.Fragment>
          )}
          {isMobile && (
            <TransformWrapper>
              <TransformComponent>
                <img
                  src={currentImage.fileUrl}
                  alt={currentImage.fileTitle}
                  className='imgViewer__img'
                  key={imageHash}
                />
              </TransformComponent>
            </TransformWrapper>
          )}
        </div>
      </React.Fragment>
    );
  };

  const getModalTitle = () => {
    let title = null;

    if (disabled || !marking) {
      title = renderImageActions();
    }

    return title;
  };

  let contentClasses = 'imgViewer__content';
  if (marking) {
    contentClasses += ' marking';
  }

  if (!show) {
    return null;
  }

  if (!disabled && marking) {
    return ReactDOM.createPortal(
      <div className='imgViewer' onClick={toggle}>
        <div className={contentClasses} onClick={onContentClick}>
          <ImageMarker
            currentFile={currentImage}
            saveMarkings={saveMarkings}
            downloadImageAction={downloadImageAction}
            deleteImageAction={toggleConfirmDelete}
            isSaving={isSaving}
            toggleModal={toggle}
          />

          <ConfirmModal
            header={i18next.t(147)}
            show={showConfirm}
            toggle={toggleConfirmDelete}
            confirmFunc={() => onDelete(currentImage.id)}
            cancelFunc={toggleConfirmDelete}
          />
        </div>
      </div>,
      document.getElementById('modals'),
    );
  }

  return (
    <React.Fragment>
      {(disabled || !marking) && (
        <CreateModal
          className={'imgView'}
          show={show}
          toggle={toggle}
          title={getModalTitle()}
          size={'lg'}
        >
          {renderContent()}
        </CreateModal>
      )}
      <ConfirmModal
        header={i18next.t(147)}
        show={showConfirm}
        toggle={toggleConfirmDelete}
        confirmFunc={() => onDelete(currentImage.id)}
        cancelFunc={toggleConfirmDelete}
      />
    </React.Fragment>
  );
};

function mapStateToProps({ cases }) {
  return {
    galleryImages: cases.caseImages,
  };
}

export default connect(mapStateToProps, { bulkFileInfoUpdate })(ImgViewerModal);
