import moment from 'moment';
import React, { useEffect } from 'react';
import { parse } from 'date-fns';
import { hasLetter, hasNumber, hasMin8Chars } from './passwordValidator';
import i18next from '../i18n';
import {
  ACTIVE_MODULES,
  ASSIGNMENT_SCHEMA,
  CUSTOMER_STATUS,
  SB_CONTROL_ITEM_TYPE,
  SB_CONTROL_TYPE,
} from '../redux/constants';

function reverseDate(date) {
  let arr = date.split('-');
  return `${arr[2]}-${arr[1]}-${arr[0]}`;
}

export const parseDate = (date, showTime = false) => {
  if (!showTime) {
    return moment(date).format('DD.MM.YYYY');
  } else {
    return `${moment(date).format('DD.MM.YYYY')}, kl. ${moment(date).format(
      'H:mm',
    )}`;
  }
};

export const parseDateNET = date => {
  return moment(date).format('MM.DD.YYYY');
};

export const parseDateRFC2822 = (date, includeTime = false) => {
  if (!date) return null;
  if (includeTime) {
    const alt = moment(date).format('DD.MM.YYYY HH:mm');
    return parse(alt, 'dd.MM.yyyy H:mm', new Date());
  }
  return parse(parseDate(date), 'dd.MM.yyyy', new Date());
};

export const dateIsBetween = (date, start, end) => {
  //console.log(date, start, end);
  if (start === 'Invalid date' || end === 'Invalid date') return true;
  return moment(reverseDate(date)).isBetween(
    reverseDate(start),
    reverseDate(end),
    null,
    '[]',
  );
};

/**
 * We retrieve the last edit date from either "CreatedDate" or "UpdatedDate"
 * @param {Object} item Item with "CreatedDate" and "UpdatedDate"
 * @returns
 */
export const getLastEditDate = item => {
  let lastEdit = !!item.updatedDate ? item.updatedDate : item.createdDate;
  lastEdit = parseDate(lastEdit);

  if (lastEdit === '01.01.0001') {
    lastEdit = parseDate(item.createdDate);
  }

  return lastEdit;
};

export const splitText = (text, firstPart, secondPart) => {
  if (text.length >= 17) {
    return `${firstPart}-\n${secondPart}`;
  }
  return text;
};

export const splitIntoBreakpoints = text => {
  return text.split('\n').map((item, index) => {
    return (
      <div key={index}>
        {item}
        <hr className='border-0' />
      </div>
    );
  });
};

export const getEmployeeDataById = (data, id) => {
  return data.filter(item => `${item.id}` === id);
};

export const StringIsEmpty = str => {
  if (str === undefined || str === null) return true;
  if (typeof str === 'number') return false;
  return !str || 0 === str.length;
};

export const ValidPhone = str => {
  let valid = false;

  if (!StringIsEmpty(str)) {
    if (!hasLetter.test(str)) {
      if (hasNumber.test(str)) {
        valid = hasMin8Chars.test(str);
      }
    }
  }

  return valid;
};

export function getAssigneeTitleFrom(assigneeType, schemaType = null) {
  let title = '';

  switch (assigneeType) {
    case 1:
      switch (schemaType) {
        case ASSIGNMENT_SCHEMA.QBE_2022:
        case ASSIGNMENT_SCHEMA.TRUST_2023:
        case ASSIGNMENT_SCHEMA.WarmWorkStandardDBI_2021:
        case ASSIGNMENT_SCHEMA.WarmWorkStandard_2021:
          title = i18next.t('7135');
          break;
        default:
          title = i18next.t('1279');
          break;
      }
      break;
    case 2:
      title = i18next.t('55');
      break;
    case 5:
      title = i18next.t('1073');
      break;
    case 3:
    default:
      switch (schemaType) {
        case ASSIGNMENT_SCHEMA.QBE_2022:
        case ASSIGNMENT_SCHEMA.TRUST_2023:
        case ASSIGNMENT_SCHEMA.WarmWorkStandardDBI_2021:
        case ASSIGNMENT_SCHEMA.WarmWorkStandard_2021:
          title = i18next.t('7134');
          break;
        default:
          title = i18next.t('1081');
          break;
      }
      break;
  }

  return title;
}

export function getRelevantDocument(caseData, docType) {
  if (caseData?.caseDocuments) {
    return caseData.caseDocuments.find(doc => doc.documentType === docType);
  }
  return null;
}

// export const ValidEmail = str => {
//   if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(str)) {
//     return true;
//   }
//   return false;
// };

export function tryParseInt(str, defaultValue) {
  var result = defaultValue;
  if (str !== null) {
    if (str.length > 0) {
      if (!isNaN(str)) {
        result = parseInt(str);
      }
    }
  }
  return result;
}

/**
 * Takes an object and compares two properties raw.
 * @param {Object} a - First object
 * @param {Object} b - Second object
 * @param {String} propName - Property to compare
 * @param {boolean} descending - Reverse ascending order?
 */
export function strComparator(a, b, propName, descending = false) {
  if (a[propName] > b[propName]) return descending ? -1 : 1;
  if (b[propName] > a[propName]) return descending ? 1 : -1;
  return 0;
}

/**
 * Retrieve the corresponding label to the customer status
 * @param {Number} status CUSTOMER_STATUS number
 * @returns String label
 */
export function getCustomerStatusLabel(status) {
  switch (status) {
    case CUSTOMER_STATUS.Active:
      return i18next.t(71);
    case CUSTOMER_STATUS.Paused:
      return i18next.t(1478);
    case CUSTOMER_STATUS.Closed:
      return i18next.t(1479);
    case CUSTOMER_STATUS.Trial:
      return i18next.t(34);
    default:
      return '';
  }
}

/**
 * Validate an E-mail based on the RFC 5322 Official Standard
 * @param {String} email E-mail address
 * @returns Boolean value
 */
export function validateEmail(email) {
  // eslint-disable-next-line no-control-regex
  const re = /(?:[a-zæøå0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zæøå0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-zæøå0-9](?:[a-zæøå0-9-]*[a-zæøå0-9])?\.)+[a-zæøå0-9](?:[a-zæøå0-9-]*[a-zæøå0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-zæøå0-9-]*[a-zæøå0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;
  var result = re.test(String(email).toLowerCase());

  // console.log(re, result);

  return !result;
}

/**
 * Generates initials based on first and lastname.
 * @param {String} name Name
 * @returns Two letter initials
 */
export function generateInitials(name) {
  if (!name) return '';
  if (typeof name !== 'string') return '';
  if (name.trim().length === 0) return '';

  try {
    let result = '';
    const splitText = name.split(' ');
    const splitted = splitText
      .filter(x => x.length > 0 || x.trim().length > 0)
      .map(x => x.trim());

    if (splitted.length > 1) {
      const first = splitted[0].charAt(0);
      let last = splitted[splitted.length - 1];
      if (last.length > 2) {
        last = last.substring(0, 2);
      }

      result = first + last;
    } else {
      if (name.length > 2) {
        result = name.substring(0, 3);
      } else {
        result = name;
      }
    }

    return result.toUpperCase();
  } catch (error) {
    console.log('Failed normal initials generation', error);

    return name
      .match(/(^\S?|\b\S\S)?/g)
      .join('')
      .match(/(^\S|\S\S$)?/g)
      .join('')
      .toUpperCase();
  }
}

/**
 * Escapes special characters used in regular expresion.
 * https://developer.mozilla.org/en-US/docs/Web/JavaScript/Guide/Regular_Expressions (search for "escapeRegExp")
 * @param {String} text string to escapce special characters.
 * @returns Escaped version of string
 */
export function escapeRegex(text) {
  try {
    if (!text) return '';
    if (typeof text !== 'string') return text;
    if (text.trim().length === 0) return '';
    return text.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
  } catch (error) {
    return text;
  }
}

export function addToObject(obj, key, value, index) {
  // Create a temp object and index variable
  var temp = {};
  var i = 0;

  // Loop through the original object
  for (var prop in obj) {
    if (obj.hasOwnProperty(prop)) {
      // If the indexes match, add the new item
      if (i === index && key) {
        temp[key] = value;
      }

      // Add the current item in the loop to the temp obj
      temp[prop] = obj[prop];

      // Increase the count
      i++;
    }
  }
  // If no index, add to the end
  if (!index && key && value) {
    temp[key] = value;
  }
  return temp;
}

export function usePrevious(value) {
  const ref = React.useRef();
  useEffect(() => {
    ref.current = value;
  }, [value]);
  return ref.current;
}

export function isValidFolderName(name) {
  if (!name) return false;
  if (typeof name !== 'string') return false;
  // eslint-disable-next-line
  const regexExp = /^[^\s^\x00-\x1f\\?*:\#"";<>|\/.][^\x00-\x1f\\?*:\#"";<>|\/]*[^\s^\x00-\x1f\\?*:\#"";<>|\/.]+$/g;

  try {
    // for some reason, the regex cannot handle single number folders well.
    if (name.length === 1) {
      var num = parseInt(name);
      if (typeof num === 'number') {
        return true;
      }
    }
  } catch (error) {
    // nothing
  }

  const val = regexExp.test(name);
  // const match = regexExp.exec(name);
  // console.log({ name, match, val });

  return val;
}

/**
 * Returns a number tween 1 and 1000
 */
export function getRng() {
  return Math.floor(Math.random() * 1000) + 1;
}

/**
 * Compare two objects by converting them to a stringified version,
 * and then return a boolean value.
 * @param {Object} orig The initial starting state
 * @param {Object} curr The current state
 * @param {Array} ignore List of variables to ignore from both objects.
 * @returns If equal returns true, else false.
 */
export function compareObjects(orig, curr, ignore = []) {
  const temp = { ...orig };

  if (ignore) ignore.forEach(x => delete temp[x]);
  const initial = JSON.stringify(temp);

  const temp2 = { ...curr };
  if (ignore) ignore.forEach(x => delete temp2[x]);

  const current = JSON.stringify(temp2);
  const equivalence = initial === current;

  //console.log(equivalence, temp, temp2);

  return equivalence;
}

export function sortControlSchemas(schemas) {
  const sortFunc = (a, b) => {
    // console.log(a, b);
    // console.log(
    //   { id: a.id, date: a.lastEditDate, controls: a.controls?.length > 0 },
    //   { id: b.id, date: b.lastEditDate, controls: b.controls?.length > 0 },
    // );

    var parsedA = parseDate(a.lastEditDate);
    var parsedB = parseDate(b.lastEditDate);

    var dateA =
      parsedA !== '01.01.0001' ? moment(a.lastEditDate) : moment(a.createdDate);
    var dateB =
      parsedB !== '01.01.0001' ? moment(b.lastEditDate) : moment(b.createdDate);

    if (a.controls?.length > 0) {
      parsedA = parseDate(a.controls[0].manualDate);

      if (parsedA !== '01.01.0001') dateA = moment(a.controls[0].manualDate);
    }

    if (b.controls?.length > 0) {
      parsedB = parseDate(b.controls[0].manualDate);

      if (parsedB !== '01.01.0001') dateB = moment(b.controls[0].manualDate);
    }

    const comp = dateA.valueOf() - dateB.valueOf();
    // console.log({
    //   nameA: a.name,
    //   nameB: b.name,
    //   parsedA,
    //   parsedB,
    //   dateA,
    //   dateB,
    //   comp,
    // });
    // console.log();

    return comp;
  };

  schemas.sort(sortFunc);

  return schemas;
}

export function sortServiceControlSchemas(schemas) {
  const sortFunc = (a, b) => {
    // console.log(
    //   { id: a.id, date: a.actualServiceDate, date2: a.expectedServiceDate },
    //   { id: b.id, date: b.actualServiceDate, date2: b.expectedServiceDate },
    // );
    let dateA = a.actualServiceDate
      ? a.actualServiceDate
      : a.expectedServiceDate;
    let dateB = b.actualServiceDate
      ? b.actualServiceDate
      : b.expectedServiceDate;

    let parsedA = moment(dateA);
    let parsedB = moment(dateB);

    dateA = new Date(parsedA.year(), parsedA.month(), parsedA.date());
    dateB = new Date(parsedB.year(), parsedB.month(), parsedB.date());

    parsedA = moment(dateA);
    parsedB = moment(dateB);

    const comp = parsedA.valueOf() - parsedB.valueOf();

    if (comp === 0) {
      let nameA = a.name;
      let nameB = b.name;

      if (nameA && nameB) {
        return nameA.toLowerCase().localeCompare(nameB.toLowerCase(), 'en', {
          sensitivity: 'base',
          numeric: true,
        });
      } else if (!nameA) {
        return 1;
      } else if (!nameB) {
        return -1;
      } else {
        return 0;
      }
    }

    return comp * -1;
  };

  schemas.sort(sortFunc);

  return schemas;
}

// Schema builder
export function getControlItemTypeName(type) {
  switch (type) {
    case SB_CONTROL_ITEM_TYPE.Standard:
      return i18next.t(20);
    case SB_CONTROL_ITEM_TYPE.Custom:
      return i18next.t(12022);
    default:
      return i18next.t(9063);
  }
}
export function getControlTypeName(type) {
  switch (type) {
    case SB_CONTROL_TYPE.PhotoAreaMarking:
      return i18next.t(12023);
    case SB_CONTROL_TYPE.PhotoDocumentation:
      return i18next.t(1060);
    case SB_CONTROL_TYPE.Signature:
      return i18next.t(12025);
    case SB_CONTROL_TYPE.DateSelector:
      return i18next.t(12026);
    case SB_CONTROL_TYPE.DropdownProducts:
      return i18next.t(12027);
    case SB_CONTROL_TYPE.DropdownUsers:
      return i18next.t(12028);
    case SB_CONTROL_TYPE.DropdownCustom:
      return i18next.t(12029);
    case SB_CONTROL_TYPE.DropdownCommentPhotos:
      return i18next.t(12073);
    case SB_CONTROL_TYPE.Checkboxes:
      return i18next.t(12030);
    case SB_CONTROL_TYPE.Text:
      return i18next.t(12031);
    case SB_CONTROL_TYPE.TextUneditable:
      return i18next.t(12032);
    case SB_CONTROL_TYPE.Numbers:
      return i18next.t(7152);
    case SB_CONTROL_TYPE.TimeTableUsers:
      return i18next.t(12065);
    case SB_CONTROL_TYPE.Checkmark:
      return i18next.t(12069);
    case SB_CONTROL_TYPE.TextPhotos:
      return i18next.t(12078);
    default:
      return i18next.t(9063);
  }
}

export function isJSON(str) {
  try {
    return JSON.parse(str) && !!str;
  } catch (e) {
    return false;
  }
}

export function parsePhoneNumber(phoneNumber) {
  const countryCode = phoneNumber.slice(1, phoneNumber.length);
  const phoneNumberDigits = countryCode.replace(/\D/g, '');

  let countryCodeLength = 2;
  if (phoneNumberDigits.length > 10) {
    countryCodeLength = phoneNumberDigits.length - 10;
  }

  return {
    countryCode: '+' + phoneNumberDigits.substring(0, countryCodeLength),
    phoneNumber: phoneNumberDigits.substring(countryCodeLength),
  };
}

export function truncateString(text, maxLength) {
  if (!text) return '';
  if (typeof text !== 'string') return '';
  if (text.length <= maxLength) {
    return text;
  }

  const truncatedText = text.slice(0, maxLength) + '...';

  return truncatedText;
}

export function splitArrayIntoChunks(array, chunkSize) {
  const result = [];

  for (let i = 0; i < array.length; i += chunkSize) {
    const chunk = array.slice(i, i + chunkSize);
    result.push(chunk);
  }

  return result;
}

export function makeWritable(object) {
  return JSON.parse(JSON.stringify(object));
}

export function getModuleName(type) {
  if (!type) return i18next.t(9063);
  if (typeof type !== 'string' && typeof type !== 'number')
    return i18next.t(9063);

  let tmp = type;
  if (typeof type === 'string') tmp = Number.parseInt(type);

  switch (tmp) {
    case ACTIVE_MODULES.Hotwork:
      return i18next.t(35);
    case ACTIVE_MODULES.QualityInsurance:
      return i18next.t(36);
    case ACTIVE_MODULES.ServiceAgreement:
      return i18next.t(3224);
    default:
      return i18next.t(9063);
  }
}

export function checkAnyValues(values) {
  return values.find(val => {
    if (!val) return false;
    if (typeof val === 'string') {
      if (val?.trim().length === 0) return false;
    }
    return true;
  });
}
