import React from 'react';

// Utils
import { ACTIVE_MODULES } from '../../redux/constants';
import { getColorClass, getColorClassHex } from '../../utils/masterCaseUtil';

// Components
import {
  IconKvalitetssikring,
  IconServiceaftale,
  IconVarmtArbejde,
} from '../../assets/icons_v2';

const ModuleIcon = ({ type, status, className }) => {
  const getModuleIcon = () => {
    switch (type) {
      case ACTIVE_MODULES.Hotwork:
        return <IconVarmtArbejde fill={getColorClassHex(status)} />;
      case ACTIVE_MODULES.QualityInsurance:
        return <IconKvalitetssikring fill={getColorClassHex(status)} />;
      case ACTIVE_MODULES.ServiceAgreement:
        return <IconServiceaftale fill={getColorClassHex(status)} />;
      default:
        return;
    }
  };

  return (
    <div className={'cm-module-icon b-' + getColorClass(status)}>
      {getModuleIcon()}
    </div>
  );
};

export default ModuleIcon;
