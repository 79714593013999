/**
 * @module actions/branding
 */

import Axios from 'axios';
import { API_URL } from '../constants';
import { setCustomerBranding } from '../slices/brandingSlice';

export function getCustomerBranding(customerId) {
  return dispatch => {
    return Axios.get(API_URL + '/branding/' + customerId).then(res => {
      dispatch(setCustomerBranding(res.data || null));
      return res;
    });
  };
}

export function getPlatformBrandingCustomer(customerId) {
  return dispatch => {
    return Axios.get(API_URL + '/branding/platform/' + customerId).then(res => {
      return res;
    });
  };
}

export function getPlatformBrandingUser() {
  return dispatch => {
    return Axios.get(API_URL + '/branding/platform').then(res => {
      return res;
    });
  };
}
