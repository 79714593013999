import { createSlice } from '@reduxjs/toolkit';

// Define initial state
const initialState = {
  insuranceCompanies: null,
};

// Create a slice
const insuranceCompaniesSlice = createSlice({
  name: 'insuranceCompanies',
  initialState,
  reducers: {
    // Reducer function for setting insurance companies
    setInsuranceCompanies(state, action) {
      state.insuranceCompanies = action.payload;
    },
  },
});

// Export reducer and action creator
export const { setInsuranceCompanies } = insuranceCompaniesSlice.actions;

// Export reducer function
export default insuranceCompaniesSlice.reducer;
