import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import i18next from '../../../i18n';
import { Button, Col, FormGroup, FormText, Label } from 'reactstrap';

// Actions
import {
  uploadFiles,
  deleteFile,
  updateFileInfo,
  setGalleryImages,
} from '../../../redux/actions/cases';

// Utils
import { uriToFile } from '../../../utils/fileUtils';
import { validateControl } from '../../../utils/schemaBuilderUtils';

// Components
import Typography from '../../Typography';
import FileInput from '../../Custom/Forms/FileInput';
import Gallery from '../../Custom/Gallery';
import InfoTip from '../../Custom/InfoTip';
import MoveImagesModal from '../../Custom/MoveImagesModal';
import LastEditor from '../../Custom/LastEditor';
import { makeWritable } from '../../../utils/common';

const ControlItemPhoto = props => {
  const {
    schema,
    control,
    disabled,
    onChangeArray,
    setUpdating,
    uploadFiles,
    deleteFile,
    updateFileInfo,
    selectControl,
    refreshGroups,
    _targetControlId,
    imageObjects,
    areControlsValid,
    hasAreaMarking,
  } = props;
  const {
    title,
    alternativeTitle,
    infoTitle,
    infoText,
    showInfoIcon,
    required,
    minFiles,
    maxFiles,
    files,
    errorMessage,
    answeredByUserId,
    updatedDate,
  } = control;
  const { selectedCase } = props.cases;
  const [valid, setValid] = useState(true);
  const [errors, setErrors] = useState([]);
  const [isUploading, setUploading] = useState(false);
  const [showMovingImagesModal, setShowMoveImagesModal] = useState(false);
  const isServiceAgreement = selectedCase?.type === 3;
  const labelSize = 3;
  // const inputSize = 9;

  useEffect(() => {
    setValid(validateControl(control));
    // eslint-disable-next-line
  }, [files]);

  const onChange = val => {
    onChangeArray('files', val);
  };

  const renderInfo = () => {
    if (!showInfoIcon) return null;
    const targetId = 'tt_cid_' + control.id;

    return <InfoTip id={targetId} title={infoTitle} html={infoText} />;
  };

  /////////////////////////////////////////////////////////////////////////////////////////////////
  ////// file handling methods

  const onDropAccepted = (
    images,
    description = null,
    title = null,
    oldFile = null,
  ) => {
    setUpdating(true);
    setUploading(true);

    let obj = {
      controlItemId: control.id,
    };

    if (isServiceAgreement) {
      obj.serviceAgreementId = selectedCase.id;
    } else {
      obj.qualityCaseId = selectedCase.id;
    }

    uploadFiles(images, obj)
      .then(res => {
        if (description) {
          updateFileInfo(res[0].id, { description, title });
          res[0].fileDescription = description;
        }

        let tmp = makeWritable(files);
        if (oldFile) {
          tmp = files.filter(x => x.id !== oldFile.id);
        }
        onChange([...tmp, ...res]);
        setUpdating(false);
        setUploading(false);
      })
      .catch(err => {
        let errData;
        if (err.response) {
          errData = err.response.data;
        } else {
          errData = err;
        }
        if (errData.files?.length > 0) {
          onChange([...files, ...errData.files]);
        }
        console.log('file error', errData);
        setErrors(errData.fileInvalid || [i18next.t(142)]);
        setUpdating(false);
        setUploading(false);
      });
  };

  const saveFile = (oldFile, newFile) => {
    setUpdating(true);
    // Delete the old file,
    deleteFile(oldFile.id).then(() => {
      const newFiles = files.filter(f => f.id !== oldFile.id);
      onChange(newFiles);
    });

    // Save the new file
    let file = uriToFile(newFile, oldFile.fileName);
    //console.log(file);
    onDropAccepted([file], oldFile.fileDescription, oldFile.fileTitle, oldFile);
  };

  const onDelete = fileId => {
    setUpdating(true);
    deleteFile(fileId).then(() => {
      const newFiles = files.filter(f => f.id !== fileId);

      onChange(newFiles);
      setUpdating(false);
    });
  };

  const handleInfo = (fileId, data) => {
    let tempFiles = makeWritable(files);
    let file = tempFiles.find(x => x.id === fileId);


    file = { ...file, ...data };


    let newFiles = tempFiles.filter(x => x.id !== fileId);
    newFiles.push(file);
    newFiles.sort((a, b) => (a.id > b.id ? 1 : -1));
    onChange(newFiles);
  };

  const handleInfoBulk = items => {
    onChange(items);
  };

  /////////////////////////////////////////////////////////////////////////////////////////////////

  const renderErrors = () => {
    return (
      <>
        {errors.map((error, key) => (
          <Typography className='text-danger' key={key}>
            {error}
          </Typography>
        ))}
      </>
    );
  };

  const renderSelectControlButton = () => {
    if (!selectControl) return null;

    const action = () => {
      selectControl(control.id);
    };

    return (
      <Button outline className='float-right' onClick={action}>
        {i18next.t(8322)}
      </Button>
    );
  };

  const renderMoveImages = () => {
    if (schema.isDone || files.length === 0 || !!selectControl) return null;

    const toggle = () => setShowMoveImagesModal(!showMovingImagesModal);

    return (
      <>
        <Button
          color='btnSecondary'
          className='float-right margin-top-10px'
          onClick={toggle}
          disabled={disabled}
        >
          {i18next.t(8321)}
        </Button>
        <MoveImagesModal
          control={control}
          show={showMovingImagesModal}
          toggle={toggle}
          callback={refreshGroups}
          isServiceAgreement={isServiceAgreement}
        />
      </>
    );
  };

  const renderMinMaxFiles = () => {
    if ((minFiles === 0 && maxFiles === 0) || (!minFiles && !maxFiles))
      return null;

    let text;
    if (minFiles > 0 && maxFiles === 0) {
      text =
        i18next.t(12054) +
        ': ' +
        minFiles +
        ' - (' +
        files.length +
        '/' +
        minFiles +
        ')';
    }

    if (minFiles === 0 && maxFiles > 0) {
      text =
        i18next.t(12055) +
        ': ' +
        maxFiles +
        ' - (' +
        files.length +
        '/' +
        maxFiles +
        ')';
    }

    if (minFiles > 0 && maxFiles > 0) {
      text =
        i18next.t(12071) +
        ': ' +
        files.length +
        ' - ' +
        i18next.t(12054) +
        ': ' +
        minFiles +
        ' - ' +
        i18next.t(12055) +
        ': ' +
        maxFiles;
    }

    return <FormText>{text}</FormText>;
  };

  const renderTitle = () => {
    let tmp = title;
    if (alternativeTitle?.trim().length > 0) tmp = alternativeTitle;

    if (required) tmp += '*';

    return tmp;
  };

  const titleValid = () => {
    if (valid || areControlsValid || !required) return null;

    return (
      <div>
        <p style={{ color: 'red' }}>{errorMessage}</p>
      </div>
    );
  };

  let tmpFiles = files;
  if (_targetControlId === control.id && imageObjects) {
    tmpFiles = [...files, ...imageObjects];
  }

  return (
    <FormGroup row>
      <Label sm={labelSize} size='md'>
        <div className='label-wrap'>
          {renderTitle()}
          {renderInfo()}
        </div>
        {titleValid()}
        <LastEditor
          answeredByUserId={answeredByUserId}
          updatedDate={updatedDate}
        />
      </Label>
      <Col>
        {!schema.isDone && (
          <>
            <FileInput
              name={'files'}
              onDropAccepted={onDropAccepted}
              isUploading={isUploading}
              isMovingImages={!!selectControl}
              movingImages={renderSelectControlButton()}
              disabled={disabled}
            />
            <small
              className='text-muted'
              style={{ display: 'block', marginTop: -10, marginBottom: 10 }}
            >
              {i18next.t(9117)}
            </small>
            {renderMinMaxFiles()}
          </>
        )}

        {renderErrors()}

        <Gallery
          files={tmpFiles}
          onDelete={onDelete}
          saveFile={saveFile}
          handleInfo={handleInfo}
          handleInfoBulk={handleInfoBulk}
          disabled={disabled}
          isMovingImages={!!selectControl}
          schema={schema}
          control={control}
          hasAreaMarking={hasAreaMarking}
          editor
        />
        {!isServiceAgreement && renderMoveImages()}
      </Col>
    </FormGroup>
  );
};

function mapStateToProps({ auth, schemas, cases }) {
  return {
    auth,
    schemas,
    cases,
  };
}

export default connect(mapStateToProps, {
  uploadFiles,
  deleteFile,
  updateFileInfo,
  setGalleryImages,
})(ControlItemPhoto);
