import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import i18next from '../../i18n';
import { Card, CardBody, CardHeader, Col, Row, Button } from 'reactstrap';
import moment from 'moment';

// Actions
import { getUserFilters, updateUserFilters } from '../../redux/actions/common';
import { getMasterCases } from '../../redux/actions/cases';
import {
  CUSTOMER_STATUS,
  CASE_STATE,
  EMPLOYEE_TYPE,
  ACTIVE_MODULES,
} from '../../redux/constants';
import { ClearSelectedCase } from '../../redux/actions/cases';
import { getEmployees } from '../../redux/actions/employees';

// Utils
import { getUserData } from '../../utils/userUtil';
import {
  parseDateRFC2822,
  escapeRegex,
  generateInitials,
  strComparator,
} from '../../utils/common';
import { hasMinAccessLevel } from '../../utils/contentByAccessLevel';
import {
  getPlaceholderIfEmpty,
  badgeByCaseStatusNew,
} from '../../utils/componentUtils';
import { getTooltip } from '../../utils/tooltip';

// Components
import PageSpinner from '../../components/PageSpinner';
import SearchInput from '../../components/SearchInput';
import ReactDatePicker from '../../components/Custom/ReactDatePicker';
import ReactTable from '../../components/Custom/ReactTable';
import ReactSelectFilter from '../../components/Custom/ReactSelectFilter';
import ModuleIcon from '../../components/Custom/ModuleIcon';
import QualityInsurancesOverview from '../QualityInsurancesOverview';
import HotworksOverview from '../HotworksOverview';
import ServiceAgreementsOverview from '../ServiceAgreementsOverview';
import Page from '../../components/Page';
import AddModulesModal from '../../components/MasterCase/AddModulesModal';

const CasesOverview = props => {
  const {
    auth,
    history,
    getUserFilters,
    updateUserFilters,
    getMasterCases,
    ClearSelectedCase,
    getEmployees,
    employees,
    cases,
  } = props;

  const [activeView, setActiveView] = useState('1');
  const [isLoading, setLoading] = useState(true);
  const [employeeOptions, setEmployeeOptions] = useState([]);
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [userFilter, setUserFilter] = useState({
    filter: '',
    statusFilter: [],
    moduleFilter: [],
    respFilter: [],
    statusText: 'Alle',
    filterStartDate: null,
    filterEndDate: null,
  });

  const user = getUserData(auth);
  const timerRef = useRef(null);

  useEffect(() => {
    if (auth.isAuthenticated) {
      getMasterCases().catch(err => {
        console.log(err);
      });

      getEmployeeOptions();

      setLoading(false);
    } else {
      history.push('/login');
    }

    getUserFilters().then(res => {
      if (res.data != null) {
        if (res.data.masterCaseFilter != null) {
          let json = JSON.parse(res.data.masterCaseFilter);
          setUserFilter(json.userFilter);
        }
      }
    });
    // eslint-disable-next-line
  }, []);

  const getEmployeeOptions = () => {
    if (employees?.length === 0) {
      getEmployees(user.customerId).then(res => {
        let options = res.data
          .filter(
            x =>
              x.role === 4 &&
              [
                EMPLOYEE_TYPE.ResponsiblePerson,
                EMPLOYEE_TYPE.ExecutivePerson,
                EMPLOYEE_TYPE.CompanyOwner,
                EMPLOYEE_TYPE.Firewatch,
              ].includes(x.employeeType),
          )
          .sort((a, b) => strComparator(a, b, 'name'))
          .map(y => {
            return { label: y.name, value: y.id };
          });
        options.unshift({ label: i18next.t(9003), value: null });
        setEmployeeOptions(options);
      });
    } else {
      let options = employees
        .filter(
          x =>
            x.role === 4 &&
            [
              EMPLOYEE_TYPE.ResponsiblePerson,
              EMPLOYEE_TYPE.ExecutivePerson,
              EMPLOYEE_TYPE.CompanyOwner,
              EMPLOYEE_TYPE.Firewatch,
            ].includes(x.employeeType),
        )
        .sort((a, b) => strComparator(a, b, 'name'))
        .map(y => {
          return { label: y.name, value: y.id };
        });
      options.unshift({ label: i18next.t(9003), value: null });
      setEmployeeOptions(options);
    }
  };

  const onFilterChange = e => {
    const tmpFilter = { ...userFilter, filter: e.target.value };
    setUserFilter(tmpFilter);

    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }
    let value = e.target.value;
    timerRef.current = setTimeout(() => {
      updateFilters({
        userFilter: { ...userFilter, filter: value },
      });
    }, 1000);
  };

  const onFilterStartDateChange = date => {
    if (Date.parse(parseDateRFC2822(date)) || date === null) {
      const tmpFilter = {
        ...userFilter,
        filterStartDate: date,
      };

      setUserFilter(tmpFilter);

      updateFilters({
        userFilter: tmpFilter,
      });
    }
  };

  const onFilterEndDateChange = date => {
    if (Date.parse(parseDateRFC2822(date)) || date === null) {
      const tmpFilter = {
        ...userFilter,
        filterEndDate: date,
      };

      setUserFilter(tmpFilter);

      updateFilters({
        userFilter: tmpFilter,
      });
    }
  };

  const setStatusFilter = filter => {
    const tmpFilter = {
      ...userFilter,
      statusFilter: filter,
      //statusText: filter.label,
    };
    setUserFilter(tmpFilter);
    updateFilters({
      userFilter: tmpFilter,
    });
  };

  const periodFilter = type => {
    let val =
      parseDateRFC2822(userFilter[type]) !== 'Invalid Date'
        ? parseDateRFC2822(userFilter[type])
        : null;

    const defaultProps = {
      className: 'form-control',
      locale: 'da',
      value: val,
      format: 'dd.MM.yyyy',
    };

    if (type === 'filterStartDate') {
      return (
        <ReactDatePicker
          {...defaultProps}
          onChange={v => onFilterStartDateChange(v)}
          placeholder={i18next.t(110)}
        />
      );
    }

    if (type === 'filterEndDate') {
      return (
        <ReactDatePicker
          {...defaultProps}
          onChange={v => onFilterEndDateChange(v)}
          placeholder={i18next.t(111)}
        />
      );
    }
  };

  const statusFilterDropdownMenu = () => {
    let options = [
      // { label: i18next.t(1016), value: 'all' },
      { label: i18next.t(1187), value: CASE_STATE.Created },
      { label: i18next.t(71), value: CASE_STATE.Active },
      { label: i18next.t(1003), value: CASE_STATE.Done },
    ];
    return (
      <div className='btn-group m-1 react-select'>
        <ReactSelectFilter
          value={userFilter?.statusFilter}
          options={options}
          onChange={setStatusFilter}
          placeholder={i18next.t(70)}
          closeMenuOnSelect={false}
          isMulti
        />
      </div>
    );
  };

  const updateFilters = data => {
    let dataObj = {
      masterCaseFilter: JSON.stringify(data),
    };

    updateUserFilters(dataObj).catch(err => console.error(err));
  };

  const getCreateButton = () => {
    let btn = null;
    if (auth) {
      if (
        !user.permission.projectCreate ||
        props.customers.customerStatus === CUSTOMER_STATUS.Paused
      )
        return null;

      if (hasMinAccessLevel(auth, 3, true)) {
        btn = (
          <Button
            color='btnPrimary'
            onClick={() => setShowCreateModal(!showCreateModal)}
            className='float-right'
          >
            {i18next.t(1015)}
          </Button>
        );
      }
    }

    return btn;
  };

  const gotoCase = (e, _case) => {
    if (e.button === 1) {
      window.open(
        process.env.REACT_APP_LOCAL_URL + `/cases/${_case.id}`,
        '_blank',
        'noreferrer',
      );
    } else {
      ClearSelectedCase().then(res => {
        history.push(`/cases/${_case.id}`);
      });
    }
  };

  const getUserIcon = resp => {
    if (!resp) return null;

    const getTooltipTarget = () => {
      return 'u-' + resp.id;
    };

    let initials = generateInitials(resp.name);
    if (resp.initials) {
      initials = resp.initials.toUpperCase();
    }
    let employee = employees.find(x => x.id === resp.id);
    if (employee) {
      if (employee.initials) {
        initials = employee.initials.toUpperCase();
      }
    }

    return (
      <div className='avatar-tool-tip'>
        <div
          data-tip
          data-for={getTooltipTarget()}
          id={getTooltipTarget()}
          className='responsible icon'
        >
          <div className='content'>{initials}</div>
        </div>
        {getTooltip(getTooltipTarget(), resp.name, 'right', 'solid')}
      </div>
    );
  };

  const getActiveModules = _case => {
    return (
      <div className='list-active-modules'>
        {_case.modules.map(item => (
          <ModuleIcon type={item.type} status={item.status} />
        ))}
      </div>
    );
  };

  const getFilteredResults = () => {
    if (!cases) return null;

    // Filter on search term
    let filteredCases = cases.filter(item => {
      try {
        var reg = new RegExp(`${escapeRegex(userFilter.filter)}`, 'i');
        let caseAddress =
          item.projectAddress +
          ' ' +
          item.projectPostal +
          ' ' +
          item.projectCity;

        if (
          reg.test(item.caseNumber) ||
          reg.test(item.caseName) ||
          reg.test(caseAddress)
        ) {
          return true;
        }
        return false;
      } catch (error) {
        return true;
      }
    });

    // Status filtering
    if (userFilter.statusFilter) {
      if (userFilter.statusFilter.length > 0) {
        filteredCases = filteredCases.filter(x =>
          userFilter.statusFilter.find(y => y.value === x.status),
        );
      }
    }

    // Date filtering
    if (userFilter.filterStartDate && userFilter.filterEndDate) {
      filteredCases = filteredCases.filter(x => {
        return (
          moment(x.startDate).isAfter(userFilter.filterStartDate) &&
          moment(x.endDate)
            .add(-1, 'day')
            .isBefore(userFilter.filterEndDate)
        );
      });
    } else if (userFilter.filterStartDate) {
      filteredCases = filteredCases.filter(x => {
        return moment(moment(x.startDate).format('YYYY-MM-DD'))
          .add(1, 'day')
          .isAfter(userFilter.filterStartDate);
      });
    } else if (userFilter.filterEndDate) {
      filteredCases = filteredCases.filter(x => {
        return moment(moment(x.endDate).format('YYYY-MM-DD'))
          .add(-1, 'day')
          .isBefore(userFilter.filterEndDate);
      });
    }

    // Module filter
    if (userFilter.moduleFilter) {
      if (userFilter.moduleFilter.length > 0) {
        const mods = userFilter.moduleFilter.map(x => x.value);
        filteredCases = filteredCases.filter(
          i => i.modules.filter(x => mods.includes(x.type)).length > 0,
        );
      }
    }

    // Responsible filtering
    if (userFilter.respFilter) {
      if (userFilter.respFilter.length > 0) {
        const ids = userFilter.respFilter.map(x => x.value);
        filteredCases = filteredCases.filter(i =>
          ids.includes(i.responsible?.user.id),
        );
      }
    }

    // Result
    return filteredCases;
  };

  const getTableProps = () => {
    let headers = [
      {
        title: i18next.t(1000),
        sortable: true,
        selector: 'caseNumber',
        sortType: 'number',
      },
      {
        title: i18next.t(1151),
        sortable: true,
        selector: 'caseName',
        sortType: 'string',
      },
      { title: i18next.t(76) },
      { title: i18next.t(1012) },
      {
        title: i18next.t(21119),
        sortable: true,
        selector: 'lastEdited',
        sortType: 'date',
      },
      {
        title: i18next.t(7053),
      },
      {
        title: i18next.t(70),
      },
    ].filter(x => !!x);

    return {
      colgroup: ['15%'],
      scrollRender: {
        window: document.getElementById('main-content-wrapper'),
        body: document.getElementById('main-content-id'),
      },
      headers: {
        class: '',
        data: headers,
      },
      body: {
        class: '',
        empty: i18next.t(21397),
        data: getBodyData(),
      },
    };
  };

  const getBodyData = () => {
    const filteredCases = getFilteredResults();
    let bodyData = [];
    filteredCases.forEach((_case, key) => {
      // let dateStart = parseDate(_case.startDate);
      // let dateEnd = parseDate(_case.endDate);
      // let dateText = `${dateStart} ${i18next.t(1019)} ${dateEnd}`;
      // if (dateStart === 'Invalid date' || dateEnd === 'Invalid date') {
      //   dateText = '';
      // }

      const caseNumber = getPlaceholderIfEmpty(
        _case.caseNumber ? _case.caseNumber : '',
      );
      const caseName = getPlaceholderIfEmpty(
        _case.caseName ? _case.caseName : '',
      );

      let resp = getPlaceholderIfEmpty('');
      if (!!_case.responsible) {
        resp = getUserIcon(_case.responsible.user);
      }

      const addresss = getPlaceholderIfEmpty(
        _case.address ? _case.address : '',
      );
      const badge = badgeByCaseStatusNew(_case.status);

      const lastEdited =
        moment(_case.lastEditDate).format('DD.MM.YYYY') !== '01.01.0001'
          ? moment(_case.lastEditDate).format('DD.MM.YYYY')
          : moment(_case.creationDate).format('DD.MM.YYYY');

      const modules = getActiveModules(_case);

      let dataObj = {
        caseNumber,
        caseName,
        resp,
        addresss,
        // dateText,
        lastEdited,
        modules,
        badge,
        // button,
        _onClick: e => gotoCase(e, _case),
      };
      bodyData.push(dataObj);
    });

    return bodyData;
  };

  const renderResponsibleFilter = () => {
    let options = employeeOptions;

    const setRespFilter = filter => {
      const tmpFilter = {
        ...userFilter,
        respFilter: filter,
      };
      setUserFilter(tmpFilter);
      updateFilters({
        userFilter: tmpFilter,
      });
    };

    return (
      <div className='btn-group m-1 react-select'>
        <ReactSelectFilter
          value={userFilter.respFilter}
          options={options}
          onChange={setRespFilter}
          placeholder={i18next.t(6419)}
          closeMenuOnSelect={false}
          isMulti
        />
      </div>
    );
  };

  const renderModuleFilter = () => {
    const options = [
      {
        value: ACTIVE_MODULES.Hotwork,
        label: i18next.t(35),
      },
      {
        value: ACTIVE_MODULES.QualityInsurance,
        label: i18next.t(36),
      },
      {
        value: ACTIVE_MODULES.ServiceAgreement,
        label: i18next.t(3224),
      },
    ];

    const setModFilter = filter => {
      const tmpFilter = {
        ...userFilter,
        moduleFilter: filter,
      };
      setUserFilter(tmpFilter);
      updateFilters({
        userFilter: tmpFilter,
      });
    };

    return (
      <div className='btn-group m-1 react-select'>
        <ReactSelectFilter
          value={userFilter.moduleFilter}
          options={options}
          onChange={setModFilter}
          placeholder={i18next.t(7053)}
          closeMenuOnSelect={false}
          isMulti
        />
      </div>
    );
  };

  const renderTabButtons = () => {
    const tabBtn = (textId, view) => {
      const active = activeView === view + '';

      return (
        <Button
          color={active ? 'primary' : 'grey'}
          onClick={() => setActiveView(view + '')}
          outline={!active}
        >
          {i18next.t(textId)}
        </Button>
      );
    };

    return (
      <div className='mc-tab-buttons'>
        {tabBtn(21327, 1)}
        {tabBtn(36, 2)}
        {tabBtn(35, 3)}
        {tabBtn(6072, 4)}
      </div>
    );
  };

  const renderActiveView = () => {
    if (activeView === '1') {
      return (
        <CardBody>
          <Row
            style={{
              marginLeft: '0px',
              marginBottom: '10px',
            }}
          >
            <SearchInput onChange={onFilterChange} value={userFilter.filter} />
            {periodFilter('filterStartDate')}
            {periodFilter('filterEndDate')}
            {renderResponsibleFilter()}
            {renderModuleFilter()}
            {statusFilterDropdownMenu()}
          </Row>
          {/* Rendering of the data */}
          <ReactTable size='sm' striped data={getTableProps()} />
          <AddModulesModal
            show={showCreateModal}
            toggle={() => setShowCreateModal(!showCreateModal)}
            history={history}
          />
        </CardBody>
      );
    } else if (activeView === '2') {
      return <QualityInsurancesOverview {...props} />;
    } else if (activeView === '3') {
      return <HotworksOverview {...props} />;
    } else if (activeView === '4') {
      return <ServiceAgreementsOverview {...props} />;
    } else {
      return <CardBody>N/A</CardBody>;
    }
  };

  if (isLoading) {
    return <PageSpinner />;
  }

  return (
    <Page title={i18next.t(101)}>
      <Card>
        <CardHeader>
          <Row>
            <Col className='align-self-center margin-bot-0'>
              {renderTabButtons()}
            </Col>
            <Col className='align-self-center margin-bot-0'>
              {getCreateButton()}
            </Col>
          </Row>
        </CardHeader>
        {renderActiveView()}
      </Card>
    </Page>
  );
};

function mapStateToProps({ auth, cases, employees, customers }) {
  return {
    auth,
    cases: cases.masterCases,
    employees: employees.employees,
    customer: customers.customer,
    customers,
  };
}

export default connect(mapStateToProps, {
  //   getCasesQualityInsurance,
  ClearSelectedCase,
  getUserFilters,
  updateUserFilters,
  getEmployees,
  //   getCustomer,
  getMasterCases,
})(CasesOverview);
