import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import i18next from '../i18n';
import {
  CardBody,
  Row,
  //Table,
  //Spinner,
  UncontrolledButtonDropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from 'reactstrap';
import {
  //DatePicker,
  registerLocale,
} from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import da from 'date-fns/locale/da';
import moment from 'moment';
//import { MdClear } from 'react-icons/md';

// Actions
import { ClearSelectedCase } from '../redux/actions/cases';
import { searchHotworks } from '../redux/actions/hotwork';
import { getUserFilters, updateUserFilters } from '../redux/actions/common';

// Utils
import { FEATURE, CASE_STATE } from '../redux/constants';
import withFeature from '../hocs/withFeature';
import { parseDate, parseDateRFC2822 } from '../utils/common';
import {
  badgeByModuleStatus,
  getPlaceholderIfEmpty,
} from '../utils/componentUtils';
import { escapeRegex, addToObject } from '../utils/common';

// Components
import ReactDatePicker from '../components/Custom/ReactDatePicker';
import ReactSelectFilter from '../components/Custom/ReactSelectFilter';
import SearchInput from '../components/SearchInput';
import PageSpinner from '../components/PageSpinner';
import ReactTable from '../components/Custom/ReactTable';

// Misc
registerLocale('da', da);

const Hotworks = props => {
  const {
    auth,
    history,
    searchHotworks,
    //ClearSelectedCase,
    getUserFilters,
    updateUserFilters,
  } = props;
  const [isLoading, setLoading] = useState(true);
  const [gotFilter, setGotFilter] = useState(false);
  const [userFilter, setUserFilter] = useState({
    filter: '',
    statusFilter: null,
    statusText: 'Alle',
    filterStartDate: null,
    filterEndDate: null,
    customerFilter: null,
  });
  const timerRef = useRef(null);

  useEffect(() => {
    if (!auth?.isAuthenticated) {
      history.push('/login');
    } else {
      searchHotworks().then(res => {
        setLoading(false);
      });
      getUserFilters().then(res => {
        if (res.data != null) {
          if (res.data.hotworkCasesFilter != null && !gotFilter) {
            let json = JSON.parse(res.data.hotworkCasesFilter);
            setUserFilter(json.userFilter);
            setGotFilter(true);
          }
        }
      });
    }
    // eslint-disable-next-line
  }, []);

  const onFilterChange = e => {
    const tmpFilter = { ...userFilter, filter: e.target.value };
    setUserFilter(tmpFilter);
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }

    timerRef.current = setTimeout(() => {
      updateFilters({
        userFilter: tmpFilter,
      });
    }, 1000);
  };

  const onFilterStartDateChange = date => {
    if (Date.parse(parseDateRFC2822(date)) || date === null) {
      const tmpFilter = {
        ...userFilter,
        filterStartDate: date,
      };
      setUserFilter(tmpFilter);
      updateFilters({
        userFilter: tmpFilter,
      });
    }
  };

  const onFilterEndDateChange = date => {
    if (Date.parse(parseDateRFC2822(date)) || date === null) {
      const tmpFilter = {
        ...userFilter,
        filterEndDate: date,
      };
      setUserFilter(tmpFilter);
      updateFilters({
        userFilter: tmpFilter,
      });
    }
  };

  const onFilterCustomerChange = customer => {
    const tmpFilter = { ...userFilter, customerFilter: customer };
    setUserFilter(tmpFilter);
    updateFilters({
      userFilter: tmpFilter,
    });
  };

  const updateFilters = data => {
    let dataObj = {
      hotworkCasesFilter: JSON.stringify(data),
    };

    updateUserFilters(dataObj).catch(err => console.error(err));
  };

  const gotoCase = (e, _case) => {
    if (e.button === 1) {
      window.open(
        process.env.REACT_APP_LOCAL_URL +
          `/cases/${_case.masterCaseId}?view=0&type=2&module=1&moduleId=${_case.id}`,
        '_blank',
        'noreferrer',
      );
    } else {
      history.push(
        `/cases/${_case.masterCaseId}?view=0&type=2&module=1&moduleId=${_case.id}`,
      );
    }
  };

  const displayCompany = () => {
    if (auth) {
      if (auth.loginAsUser) {
        const user = JSON.parse(auth.loginAsUser);
        return user.loginData.accessLevel <= 2;
      } else {
        return auth.user.accessLevel <= 2;
      }
    } else {
      return false;
    }
  };

  const getButtonActionType = _case => {
    switch (_case.status) {
      case 99:
        return () => editDraftCase(_case.id);

      default:
        return e => gotoCase(e, _case);
    }
  };

  const getFilteredResults = () => {
    if (!props.cases) return null;
    const { warmCases } = props.cases;
    if (!warmCases) return null;

    // Customer filtering
    const customerFilter = warmCases.filter(item => {
      if (!userFilter.customerFilter) return true;

      try {
        var reg = new RegExp(`^${escapeRegex(userFilter.customerFilter)}`, 'i');
        return reg.test(item.customerName);
      } catch (error) {
        return true;
      }
    });

    // Search filtering
    let filteredCases = [];
    customerFilter.forEach(x => {
      let items = x.cases.filter(entry => {
        try {
          var reg = new RegExp(`${escapeRegex(userFilter.filter)}`, 'i');
          if (
            reg.test(entry.caseNumber) ||
            reg.test(entry.workplaceName) ||
            reg.test(entry.workplaceCity) ||
            reg.test(entry.workplaceAddress) ||
            (reg.test(entry.customerName) && displayCompany())
          ) {
            return true;
          }
          return false;
        } catch (error) {
          return true;
        }
      });
      items = items.map(y => {
        return { ...y, customerName: x.customerName };
      });

      filteredCases = filteredCases.concat(items);
    });

    // Status filtering
    if (userFilter.statusFilter) {
      if (userFilter.statusFilter.length > 0) {
        filteredCases = filteredCases.filter(x =>
          userFilter.statusFilter.find(y => y.value === x.status),
        );
      }
    }

    // Date filtering
    if (userFilter.filterStartDate && userFilter.filterEndDate) {
      filteredCases = filteredCases.filter(x => {
        return (
          moment(x.startDate).isAfter(userFilter.filterStartDate) &&
          moment(x.endDate)
            .add(-1, 'day')
            .isBefore(userFilter.filterEndDate)
        );
      });
    } else if (userFilter.filterStartDate) {
      filteredCases = filteredCases.filter(x => {
        return moment(moment(x.startDate).format('YYYY-MM-DD'))
          .add(1, 'day')
          .isAfter(userFilter.filterStartDate);
      });
    } else if (userFilter.filterEndDate) {
      filteredCases = filteredCases.filter(x => {
        return moment(moment(x.endDate).format('YYYY-MM-DD'))
          .add(-1, 'day')
          .isBefore(userFilter.filterEndDate);
      });
    }

    // Result
    return filteredCases;
  };

  const setStatusFilter = status => {
    const tmpFilter = { ...userFilter, statusFilter: status };
    setUserFilter(tmpFilter);
    updateFilters({
      userFilter: tmpFilter,
    });
  };

  const statusFilterDropdownMenu = () => {
    const options = [
      // { label: i18next.t(1016), value: 'all' },
      { label: i18next.t(1004), value: CASE_STATE.Draft },
      { label: i18next.t(1020), value: CASE_STATE.NotAccepted },
      { label: i18next.t(1021), value: CASE_STATE.Accepted },
      // { label: i18next.t(187), value: CASE_STATE.InProgress },
      { label: i18next.t(1023), value: CASE_STATE.Done },
    ];
    return (
      <div className='btn-group m-1 react-select'>
        <ReactSelectFilter
          value={userFilter.statusFilter}
          options={options}
          onChange={setStatusFilter}
          placeholder={i18next.t(70)}
          closeMenuOnSelect={false}
          isMulti
        />
      </div>
    );
  };

  const customersFilterDropdownMenu = () => {
    if (!props.cases) return null;
    if (!displayCompany()) return null;
    const { cases } = props.cases;

    let names = [];

    const nameOptions = cases.map(x => x.customerName);
    nameOptions.sort((a, b) => a.localeCompare(b));

    return (
      <UncontrolledButtonDropdown key='uncontrolled-customer-dropdown'>
        <DropdownToggle caret color='info' className='text-capitalize m-1'>
          {i18next.t(30)}:{' '}
          {userFilter?.customerFilter
            ? userFilter.customerFilter
            : i18next.t(1016)}
        </DropdownToggle>
        <DropdownMenu className='menu-scrollable'>
          <DropdownItem onClick={() => onFilterCustomerChange()}>
            {i18next.t(1016)}
          </DropdownItem>
          {nameOptions.map((name, key) => {
            if (!names.includes(name)) {
              names.push(name);

              return (
                <DropdownItem
                  onClick={() => onFilterCustomerChange(name)}
                  key={key}
                >
                  {name}
                </DropdownItem>
              );
            } else {
              return null;
            }
          })}
        </DropdownMenu>
      </UncontrolledButtonDropdown>
    );
  };

  const periodFilter = type => {
    const defaultProps = {
      className: 'form-control',
      value: parseDateRFC2822(userFilter[type]),
      format: 'dd.MM.yyyy',
    };

    if (type === 'filterStartDate') {
      return (
        <ReactDatePicker
          {...defaultProps}
          onChange={v => onFilterStartDateChange(v)}
          placeholder={i18next.t(110)}
        />
      );
    }

    if (type === 'filterEndDate') {
      return (
        <ReactDatePicker
          {...defaultProps}
          onChange={v => onFilterEndDateChange(v)}
          placeholder={i18next.t(111)}
        />
      );
    }
  };

  const editDraftCase = id => {
    //TODO MISSING
    history.push(`/hotwork/case/${id}/edit`);
  };

  const getTableProps = () => {
    let headers = [
      {
        title: i18next.t(1000),
        sortable: true,
        selector: 'caseNumber',
        sortType: 'number',
      },
      {
        title: i18next.t(1011),
        sortable: true,
        selector: 'workplaceName',
        sortType: 'string',
      },
      {
        title: i18next.t(1012),
        sortable: true,
        selector: 'workplaceAddress',
        sortType: 'string',
      },
      {
        title: i18next.t(1013),
        sortable: true,
        selector: 'workplaceCity',
        sortType: 'string',
      },
      { title: i18next.t(1014) },
      {
        title: i18next.t(21119),
        sortable: true,
        selector: 'lastEdited',
        sortType: 'date',
      },
      {
        title: i18next.t(70),
        sortable: true,
        selector: 'badge',
        sortType: 'valueString',
      },
    ];

    if (displayCompany()) {
      headers.unshift({
        title: i18next.t(30),
        sortable: true,
        selector: 'customerName',
        sortType: 'string',
      });
    }

    return {
      colgroup: ['15%', '15%'],
      scrollRender: {
        window: document.getElementById('main-content-wrapper'),
        body: document.getElementById('main-content-id'),
      },
      headers: {
        class: '',
        data: headers,
      },
      body: {
        class: '',
        empty: i18next.t(21398),
        data: getBodyData(),
      },
    };
  };

  const getBodyData = () => {
    const filteredCases = getFilteredResults();
    let bodyData = [];
    filteredCases.forEach((_case, key) => {
      const caseNumber = getPlaceholderIfEmpty(_case.caseNumber);
      const workplaceName = getPlaceholderIfEmpty(_case.workplaceName);
      const workplaceAddress = getPlaceholderIfEmpty(_case.workplaceAddress);
      const workplaceCity = getPlaceholderIfEmpty(_case.workplaceCity);

      let dateInterval = '';
      if (!!_case.startDate && !!_case.endDate) {
        dateInterval =
          parseDate(_case.startDate) +
          ' ' +
          i18next.t(1019) +
          ' ' +
          parseDate(_case.endDate);
      }
      const lastEdited =
        moment(_case.lastEdited).format('DD.MM.YYYY') !== '01.01.0001'
          ? moment(_case.lastEdited).format('DD.MM.YYYY')
          : moment(_case.createdDate).format('DD.MM.YYYY');

      const badge = badgeByModuleStatus(_case.status);

      let dataObj = {
        caseNumber,
        workplaceName,
        workplaceAddress,
        workplaceCity,
        dateInterval,
        lastEdited,
        badge,
        // button,
        _onClick: getButtonActionType(_case),
      };

      // Return column
      if (!displayCompany()) {
        bodyData.push(dataObj);
      } else {
        const customerName = _case.customerName;
        dataObj = addToObject(dataObj, 'customerName', customerName, 0);
        bodyData.push(dataObj);
      }
    });

    return bodyData;
  };

  if (isLoading) {
    return <PageSpinner />;
  }

  return (
    <CardBody>
      <Row
        style={{
          marginLeft: '0px',
          marginBottom: '10px',
        }}
      >
        {customersFilterDropdownMenu()}
        <SearchInput onChange={onFilterChange} value={userFilter?.filter} />
        {periodFilter('filterStartDate')}
        {periodFilter('filterEndDate')}
        {statusFilterDropdownMenu()}
      </Row>
      {/* Rendering of the data */}
      <ReactTable size='sm' striped data={getTableProps()} />
    </CardBody>
  );
};

function mapStateToProps(state) {
  return {
    auth: state.auth,
    cases: state.cases,
  };
}

export default connect(mapStateToProps, {
  searchHotworks,
  ClearSelectedCase,
  getUserFilters,
  updateUserFilters,
})(withFeature(Hotworks, [FEATURE.Hotwork]));
