import { createSlice } from '@reduxjs/toolkit';
import { CUSTOMER_STATUS } from '../constants';

// Define initial state
const initialState = {
  customers: [],
  customer: null,

  customerStatus: CUSTOMER_STATUS.Active,
};

// Create a slice
const customersSlice = createSlice({
  name: 'customers',
  initialState,
  reducers: {
    setCustomers(state, action) {
      state.customers = action.payload;
    },
    setCustomer(state, action) {
      state.customer = action.payload;
    },
    setCustomerStatus(state, action) {
      state.customerStatus = action.payload;
    },
  },
});

// Export reducers and action creators
export const {
  setCustomers,
  setCustomer,
  setCustomerStatus,
} = customersSlice.actions;

// Export reducer function
export default customersSlice.reducer;
