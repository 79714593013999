import React, { useState, useEffect } from 'react';
import { Card, CardBody, CardHeader, Col, Row, Input } from 'reactstrap';
import { connect } from 'react-redux';
import i18next from '../i18n';
import { osName } from 'react-device-detect';

// Actions
import { login, logout } from '../redux/actions/auth';
import { GetBranding } from '../redux/actions/common';
import { getSystemMessage } from '../redux/actions/systemInfo';
import { getFeatures } from '../redux/actions/features';
import { getCustomer } from '../redux/actions/customers';

// Utils
import { GetErrorCodeDescription } from '../utils/errorCodes';
import { isDevEnvironment } from '../utils/routeHandling';
import { CUSTOMER_STATUS } from '../redux/constants';

// Components
import Typography from '../components/Typography';
import AuthForm from '../components/AuthForm';
import LanguagePicker from '../components/Custom/LanguagePicker';

const AuthPage = props => {
  const {
    auth,
    history,
    login,
    getCustomer,
    logout,
    getSystemMessage,
    getFeatures,
    GetBranding,
  } = props;
  const [failedLogin, setFailedLogin] = useState(false);
  const [errorText, setErrorText] = useState(null);
  const [showClosedAccountMessage, setShowClosedAccount] = useState(false);
  const [salesManager, setSalesMaanger] = useState(null);
  const [remember, setRemember] = useState(false);

  useEffect(() => {
    const rememberPass = localStorage.getItem('remember_pass');
    if (rememberPass) {
      setRemember(rememberPass === 'true');
    }
  }, []);

  useEffect(() => {
    if (auth?.isAuthenticated) {
      history.push('/');
    }
    // eslint-disable-next-line
  }, []);

  const handleLogoClick = () => {
    window.location.href = 'https://www.nordicwaterproofing.com/';
  };

  const handleLogin = (username, password) => {
    login({
      username: username,
      password: password,
      devicetype: osName,
    })
      .then(res => {
        //console.log(res.data);
        if (res.data.verification !== 0 && res.data.token === null) {
          history.push('/verification');
        } else {
          if (res.data.customerStatus === CUSTOMER_STATUS.Closed) {
            const { user } = res.data;
            getCustomer(user.groupId, user.belongsToCustomerId)
              .then(res => {
                logout();
                setShowClosedAccount(true);
                setSalesMaanger(res.data.salesManager);
              })
              .catch(err => {
                logout();
                setShowClosedAccount(true);
                setSalesMaanger(null);
              });
          } else {
            getSystemMessage();
            getFeatures();
            if (res.data.accessLevel > 1 && res.data.user) {
              const { user } = res.data;

              GetBranding(user.belongsToCustomerId)
                .then(res => {
                  history.push('/');
                })
                .catch(err => {
                  console.log('Failed to get branding.', err);
                  history.push('/');
                });
            } else {
              history.push('/');
            }
          }
        }
      })
      .catch(err => {
        let text = i18next.t(1090);
        if (err.response) {
          if (err.response.data) {
            if (err.response.data.dictionaryKey) {
              text = GetErrorCodeDescription(err.response.data.dictionaryKey);
            }
          }
        }
        setFailedLogin(true);
        setErrorText(text);
      });
  };

  const navigateToForgotPassword = () => {
    history.push('/forgot-password');
  };

  const onChangeRemember = () => {
    setRemember(!remember);
    localStorage.setItem('remember_pass', !remember);
  };

  const renderAuthForm = () => {
    if (showClosedAccountMessage) return null;

    return (
      <Card body>
        <AuthForm
          onLogoClick={handleLogoClick}
          login={handleLogin}
          loginFailed={failedLogin}
          errorText={errorText}
          isDev={isDevEnvironment()}
          remember={remember}
        />
        <Row style={{ marginRight: 0, marginLeft: 0 }}>
          <div className='remember-password'>
            <p>
              <Input
                type='checkbox'
                name='remember'
                defaultChecked={remember}
                onChange={() => onChangeRemember()}
              />
              {i18next.t(151)}
            </p>
          </div>
        </Row>
        <Row style={{ marginRight: 0, marginLeft: 0 }}>
          <div className='forgot-password'>
            <p onClick={navigateToForgotPassword}>{i18next.t(3253)}</p>
          </div>
        </Row>
        <Row style={{ marginRight: 0, marginLeft: 0 }}>
          <div className='sub-box'>
            <p onClick={() => history.push('/create-profile')}>
              {i18next.t(9095)}
            </p>
          </div>
          <div className='login-page-language'>
            <LanguagePicker />
          </div>
        </Row>
      </Card>
    );
  };

  const renderClosedAccountMessage = () => {
    if (!showClosedAccountMessage) return null;
    return (
      <Card>
        <CardHeader>{i18next.t(1495)}</CardHeader>
        <CardBody>
          <Typography type='h4'>
            {i18next.t(1496)} {props.auth.user}
          </Typography>
          {salesManager && (
            <div className='closeAccountContactInfo'>
              <hr />
              <Typography>
                <strong>{i18next.t(7103)}</strong>
              </Typography>
              <Row>
                <Col sm='5'>
                  <Typography>{i18next.t(13)}:</Typography>
                  <Typography>{i18next.t(1040)}:</Typography>
                  <Typography>{i18next.t(27)}:</Typography>
                  <Typography>{i18next.t(58)}:</Typography>
                </Col>
                <Col sm='7'>
                  <Typography>{salesManager.name}</Typography>
                  <Typography>{salesManager.corporation}</Typography>
                  <Typography>
                    {salesManager.countryCode} {salesManager.phone}
                  </Typography>
                  <Typography>
                    <a href={`mailto:${salesManager.mail}`}>
                      {salesManager.mail}
                    </a>
                  </Typography>
                </Col>
              </Row>
            </div>
          )}
        </CardBody>
      </Card>
    );
  };

  return (
    <Row
      style={{
        height: '100vh',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Col md={6} lg={4}>
        {renderAuthForm()}
        {renderClosedAccountMessage()}
      </Col>
    </Row>
  );
};

function mapStateToProps(state) {
  return {
    auth: state.auth,
  };
}

export default connect(mapStateToProps, {
  login,
  logout,
  GetBranding,
  getSystemMessage,
  getFeatures,
  getCustomer,
})(AuthPage);
