import { createSlice } from '@reduxjs/toolkit';

// Define initial state
const initialState = {
  languageIndex: [],
  currentLanguage: 'dk',
};

// Create a slice
const languageSlice = createSlice({
  name: 'language',
  initialState,
  reducers: {
    // Reducer function for setting or getting languages
    setLanguages(state, action) {
      state.languageIndex = action.payload;
    },
    // Reducer function for setting user language
    setUserLanguage(state, action) {
      state.currentLanguage = action.payload;
    },
  },
});

// Export reducers and action creators
export const { setLanguages, setUserLanguage } = languageSlice.actions;

// Export reducer function
export default languageSlice.reducer;
