import React, { useState } from 'react';
import i18next from '../../../../../../i18n';
import {
  Button,
  Container,
  Form,
  ListGroup,
  ListGroupItem,
  ListGroupItemHeading,
} from 'reactstrap';
import { isMobile } from 'react-device-detect';

// Utils
import {
  ASSIGNMENT_SCHEMA,
  ASSIGNEE_TYPE,
} from '../../../../../../redux/constants';
import { GetQuestion } from '../../../../../../utils/translation/schemaQuestions';

// Components
import Checkbox from '../../../../../../components/Custom/Forms/Checkbox';
import TextInput from '../../../../../../components/Custom/Forms/TextInput';
import ConfirmModal from '../../../../../../components/Custom/ConfirmModal';
import InfoModal from '../../../../../../components/Custom/InfoModal';
import Typography from '../../../../../../components/Typography';

const BuildingownerApproval = props => {
  const {
    onChangeComment,
    schema,
    questions,
    hasSigned,
    // allApproved,
    allMarked,
    // declaration,
    handleMarkAll,
    isInfo,
    assigneeType,
    aggreementComment,
    handleSetDeclaration,
    handleApproveAll,
  } = props;
  const [showConfirm, setShowConfirm] = useState(false);
  const [showBuildingOwnerContent, setShowBuildingOwnerContent] = useState(
    false,
  );
  const [showExecutivePersonContent, setShowExecutivePersonContent] = useState(
    false,
  );
  const [showFireWatchContent, setShowFireWatchContent] = useState(false);

  const is2021Version = [
    ASSIGNMENT_SCHEMA.QBE_2022,
    ASSIGNMENT_SCHEMA.TRUST_2023,
    ASSIGNMENT_SCHEMA.WarmWorkStandardDBI_2021,
    ASSIGNMENT_SCHEMA.WarmWorkStandard_2021,
  ].includes(schema);

  const isKoeb2021 = [ASSIGNMENT_SCHEMA.WarmWorkStandard_2021].includes(schema);

  const onChange = e => {
    if (onChangeComment) {
      onChangeComment(e);
    }
  };

  const getQuestionContentByType = (q, question, excludeIndex = false) => {
    switch (schema) {
      // case 101:
      //   const forName = `q-${q.number}`;

      //   const isChecked = this.props.approvals
      //     ? this.props.approvals[`${q.number - 1}`]
      //     : false;

      //   return (
      //     <Checkbox
      //       for={forName}
      //       text={`${q.number}. ${question.text}`}
      //       name={forName}
      //       onChange={() => this.props.handleApproveSingle(null, q.number)}
      //       checked={isChecked}
      //       disabled={this.props.hasSigned}
      //       key={forName}
      //     />
      //   );
      default:
        if (excludeIndex) return question.text;
        return `${q.number}. ${question.text}`;
    }
  };

  const generateCheckboxes = () => {
    questions.sort((a, b) => {
      return a.number < b.number ? -1 : 1;
    });

    let beforeWorkBO = [],
      duringWorkBO = [],
      afterWorkBO = [];

    let beforeWorkEX = [],
      duringWorkEX = [],
      afterWorkEX = [];

    let beforeWorkFW = [],
      duringWorkFW = [],
      afterWorkFW = [];

    questions.map((q, key) => {
      const question = GetQuestion(q.number, q.type, q.schema);

      let listContent = getQuestionContentByType(q, question, is2021Version);
      let content = <ListGroupItem key={key}>{listContent}</ListGroupItem>;

      switch (question.group) {
        case 1:
          switch (question.type) {
            case ASSIGNEE_TYPE.BuildingOwner:
              beforeWorkBO.push(content);
              // beforeWorkEX.push(content);
              break;
            case ASSIGNEE_TYPE.ExecutivePerson:
              beforeWorkEX.push(content);
              break;
            case ASSIGNEE_TYPE.Firewatch:
              beforeWorkFW.push(content);
              break;
            default:
              break;
          }
          break;
        case 2:
          switch (question.type) {
            case ASSIGNEE_TYPE.BuildingOwner:
              duringWorkBO.push(content);
              // duringWorkEX.push(content);
              break;
            case ASSIGNEE_TYPE.ExecutivePerson:
              duringWorkEX.push(content);
              break;
            case ASSIGNEE_TYPE.Firewatch:
              duringWorkFW.push(content);
              break;
            default:
              break;
          }
          break;
        case 3:
          switch (question.type) {
            case ASSIGNEE_TYPE.BuildingOwner:
              afterWorkBO.push(content);
              // afterWorkEX.push(content);
              break;
            case ASSIGNEE_TYPE.ExecutivePerson:
              afterWorkEX.push(content);
              break;
            case ASSIGNEE_TYPE.Firewatch:
              afterWorkFW.push(content);
              break;
            default:
              break;
          }
          break;
        default:
          break;
      }

      return null;
    });

    // BUILDING OWNER QUESTIONS
    let beforeContentBO, duringContentBO, afterContentBO;

    if (beforeWorkBO.length > 0) {
      beforeContentBO = (
        <div className='margin-top-10px'>
          <ListGroup>
            <ListGroupItemHeading>{i18next.t(1084)}</ListGroupItemHeading>
            {beforeWorkBO}
          </ListGroup>
        </div>
      );
    }
    if (duringWorkBO.length > 0) {
      duringContentBO = (
        <div className='margin-top-10px'>
          <ListGroup>
            <ListGroupItemHeading>{i18next.t(1085)}</ListGroupItemHeading>
            {duringWorkBO}
          </ListGroup>
        </div>
      );
    }
    if (afterWorkBO.length > 0) {
      afterContentBO = (
        <div className='margin-top-10px'>
          <ListGroup>
            <ListGroupItemHeading>{i18next.t(1086)}</ListGroupItemHeading>
            <ListGroup>{afterWorkBO}</ListGroup>
          </ListGroup>
        </div>
      );
    }
    // BUILDING OWNER QUESTIONS

    // ROOFING CONTRACTOR QUESTIONS
    let beforeContentEX, duringContentEX, afterContentEX;
    if (beforeWorkEX.length > 0) {
      beforeContentEX = (
        <div className='margin-top-10px'>
          <ListGroup>
            <ListGroupItemHeading>{i18next.t(1084)}</ListGroupItemHeading>
            {beforeWorkEX}
          </ListGroup>
        </div>
      );
    }
    if (duringWorkEX.length > 0) {
      duringContentEX = (
        <div className='margin-top-10px'>
          <ListGroup>
            <ListGroupItemHeading>{i18next.t(1085)}</ListGroupItemHeading>
            {duringWorkEX}
          </ListGroup>
        </div>
      );
    }
    if (afterWorkEX.length > 0) {
      afterContentEX = (
        <div className='margin-top-10px'>
          <ListGroup>
            <ListGroupItemHeading>{i18next.t(1086)}</ListGroupItemHeading>
            <ListGroup>{afterWorkEX}</ListGroup>
          </ListGroup>
        </div>
      );
    }
    // ROOFING CONTRACTOR QUESTIONS

    // FIREWATCH QUESTIONS
    let beforeContentFW, duringContentFW, afterContentFW;
    if (beforeWorkFW.length > 0) {
      beforeContentFW = (
        <div className='margin-top-10px'>
          <ListGroup>
            <ListGroupItemHeading>{i18next.t(1084)}</ListGroupItemHeading>
            {beforeWorkFW}
          </ListGroup>
        </div>
      );
    }
    if (duringWorkFW.length > 0) {
      duringContentFW = (
        <div className='margin-top-10px'>
          <ListGroup>
            <ListGroupItemHeading>{i18next.t(1085)}</ListGroupItemHeading>
            {duringWorkFW}
          </ListGroup>
        </div>
      );
    }
    if (afterWorkFW.length > 0) {
      afterContentFW = (
        <div className='margin-top-10px'>
          <ListGroup>
            <ListGroupItemHeading>{i18next.t(1086)}</ListGroupItemHeading>
            <ListGroup>{afterWorkFW}</ListGroup>
          </ListGroup>
        </div>
      );
    }
    // FIREWATCH QUESTIONS

    let rooferTitle = is2021Version
      ? i18next.t(5011) + (isKoeb2021 ? ` (${i18next.t(1081)})` : '')
      : i18next.t(1081);
    let execTitle = is2021Version ? i18next.t(5012) : i18next.t(53);
    let fireTitle = is2021Version ? i18next.t(5013) : i18next.t(55);
    return (
      <div>
        {!is2021Version && (
          <div>
            <Typography type='h4'>{i18next.t(1080)}</Typography>
          </div>
        )}
        <Button
          block
          outline
          color='btnSecondary'
          size='sm'
          onClick={() => setShowBuildingOwnerContent(!showBuildingOwnerContent)}
        >
          {rooferTitle}
        </Button>
        <InfoModal
          show={showBuildingOwnerContent}
          toggle={() => setShowBuildingOwnerContent(!showBuildingOwnerContent)}
          header={rooferTitle}
          size='lg'
        >
          <div>
            {beforeContentBO}
            {duringContentBO}
            {afterContentBO}
          </div>
        </InfoModal>
        <Button
          block
          outline
          color='btnSecondary'
          size='sm'
          onClick={() =>
            setShowExecutivePersonContent(!showExecutivePersonContent)
          }
        >
          {execTitle}
        </Button>

        <InfoModal
          show={showExecutivePersonContent}
          toggle={() =>
            setShowExecutivePersonContent(!showExecutivePersonContent)
          }
          header={execTitle}
          size='lg'
        >
          <div>
            {beforeContentEX}
            {duringContentEX}
            {afterContentEX}
          </div>
        </InfoModal>

        <Button
          block
          outline
          color='btnSecondary'
          size='sm'
          onClick={() => setShowFireWatchContent(!showFireWatchContent)}
        >
          {fireTitle}
        </Button>

        <InfoModal
          show={showFireWatchContent}
          toggle={() => setShowFireWatchContent(!showFireWatchContent)}
          header={fireTitle}
          size='lg'
        >
          <div>
            {beforeContentFW}
            {duringContentFW}
            {afterContentFW}
          </div>
        </InfoModal>
      </div>
    );
  };

  const renderDeclaration = () => {
    // const isDisabled = hasSigned || (schema === 101 && !allApproved);
    const isDisabled = hasSigned;
    let checked = allMarked;
    let onChange = handleMarkAll;

    // if (schema === 101 && !allApproved) {
    //   checked = declaration;
    //   onChange = this.toggleConfirm;
    // }

    const getDeclarationText = () => {
      switch (schema) {
        case ASSIGNMENT_SCHEMA.WarmWorkStandard_2021:
        case ASSIGNMENT_SCHEMA.WarmWorkStandardDBI_2021:
        case ASSIGNMENT_SCHEMA.QBE_2022:
        case ASSIGNMENT_SCHEMA.TRUST_2023:
          return i18next.t(8115);
        default:
          return i18next.t(125);
      }
    };

    return (
      <div>
        {is2021Version && (
          <div className='declaration'>
            <Typography className='bolded margin-bottom-0'>
              {i18next.t(122)}
            </Typography>
            <div className='maintext'>
              <Typography>
                {isKoeb2021 ? i18next.t(241) : i18next.t(230)}
              </Typography>
              {isKoeb2021 && <Typography>{i18next.t(240)}</Typography>}
            </div>
          </div>
        )}
        <Checkbox
          for={'declaration'}
          text={getDeclarationText()}
          name={'declaration'}
          onChange={onChange}
          checked={checked}
          disabled={isDisabled}
          key={'declaration-key'}
          bold
        />
        <hr />
      </div>
    );
  };

  const renderCommentField = () => {
    if (assigneeType === 5) return null; // Subcontractor cannot leave comments

    return (
      <div>
        <TextInput
          disabled={hasSigned || isInfo}
          for={i18next.t(1181) + ':'}
          name={'aggreementComment'}
          value={aggreementComment}
          onChange={onChange}
          type={'textarea'}
          rows={6}
        />
        <hr />
      </div>
    );
  };

  // const onClickFunc =
  //   schema === 101
  //     ? this.props.handleApproveButton
  //     : this.props.handleApproveAll;
  const onClickFunc = handleApproveAll;

  return (
    <Container className={isMobile ? 'mobile' : ''}>
      <Form>
        {generateCheckboxes()}
        <hr />
        {renderCommentField()}
        {!isInfo && renderDeclaration()}
        {!isInfo && (
          <Button
            color='btnPrimary'
            block
            onClick={onClickFunc}
            disabled={hasSigned || !allMarked}
          >
            {i18next.t(1094)}
          </Button>
        )}
      </Form>
      <ConfirmModal
        header={i18next.t(177)}
        show={showConfirm}
        toggle={() => setShowConfirm(!showConfirm)}
        confirmFunc={() => handleSetDeclaration()}
        cancelFunc={() => setShowConfirm(false)}
      >
        <Typography type='p'>{i18next.t(178)}</Typography>
      </ConfirmModal>
    </Container>
  );
};

export default BuildingownerApproval;
