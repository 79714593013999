import { createSlice } from '@reduxjs/toolkit';

// Define initial state
const initialState = {
  customerGroups: [],
};

// Create a slice
const customerGroupsSlice = createSlice({
  name: 'customerGroups',
  initialState,
  reducers: {
    // Reducer function for setting customer groups
    setCustomerGroups(state, action) {
      state.customerGroups = action.payload;
    },
  },
});

// Export reducer and action creator
export const { setCustomerGroups } = customerGroupsSlice.actions;
export default customerGroupsSlice.reducer;
