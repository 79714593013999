/**
 * @module actions/notifications
 */
import axios from 'axios';
import { API_URL } from '../constants';
import {
  setNotice,
  setNotifications,
  setNotificationSettings,
} from '../slices/notificationSlice';

/**
 * @description Get a chunk of notifications
 * @return {Promise} Axios promise
 */
export function getNotifications(page = 0, chunk = 15) {
  let url = API_URL + '/notification?page=' + page + '&amount=' + chunk + '';
  return dispatch => {
    return axios.get(url).then(res => {
      dispatch(setNotifications(res.data));
      return res.data;
    });
  };
}

/**
 * @description Mark notification as read
 * @return {Promise} Axios promise
 */
export function markNotification(data) {
  let url = API_URL + '/notification/mark';
  return dispatch => {
    return axios.post(url, data).then(res => {
      return res.data;
    });
  };
}

/**
 * @description Get notification settings
 * @return {Promise} Axios promise
 */
export function getNotificationSettings() {
  let url = API_URL + '/UserSettings/notification';
  return dispatch => {
    return axios.get(url).then(res => {
      dispatch(setNotificationSettings(res.data));
      return res.data;
    });
  };
}

/**
 * @description Update notification settings
 * @return {Promise} Axios promise
 */
export function updateNotificationSettings(data) {
  let url = API_URL + '/UserSettings/update-notis';
  return dispatch => {
    return axios.post(url, data).then(res => {
      dispatch(setNotificationSettings(res.data));
      return res.data;
    });
  };
}

/**
 * @description Get notice on case
 * @return {Promise} Axios promise
 */
export function getNoticeOnCase(caseId) {
  let url = API_URL + '/notification/case-notice?mcId=' + caseId;
  return dispatch => {
    return axios.get(url).then(res => {
      dispatch(setNotice(res.data));
    });
  };
}

/**
 * @description Mark notice as read
 * @return {Promise} Axios promise
 */
export function markNoticeAsRead(caseId) {
  let url = API_URL + '/notification/case-notice?mcId=' + caseId;
  return dispatch => {
    return axios.put(url).then(() => {
      dispatch(setNotice(null));
    });
  };
}

/**
 * @description Notify users of new notes
 * @return {Promise} Axios promise
 */
export function notifyUsersOnCaseOfNewNotes(data) {
  let url = API_URL + '/notification/case-notice';
  return dispatch => {
    return axios.post(url, data);
  };
}
