import * as React from 'react';

const SvgIconSag = props => (
  <svg
    data-name='Icon - sag'
    xmlns='http://www.w3.org/2000/svg'
    width='100%'
    height='100%'
    x='0px'
    y='0px'
    viewBox='0 0 24 24'
    {...props}
  >
    <defs>
      <clipPath id='Icon_-_Sag_svg__a'>
        <path data-name='Rectangle 1231' d='M0 0h16.619v21.819H0z' />
      </clipPath>
    </defs>
    <path data-name='Rectangle 1228' fill='none' d='M0 0h24v24H0z' />
    <path data-name='Rectangle 1229' fill='none' d='M0 0h24v24H0z' />
    <g data-name='Group 229'>
      <g
        data-name='Group 226'
        clipPath='url(#Icon_-_Sag_svg__a)'
        transform='translate(3.879 1.042)'
      >
        <path
          data-name='Rectangle 1230'
          fill='none'
          d='M-3.879-1.042h24v24h-24z'
        />
      </g>
      <g
        data-name='Group 227'
        clipPath='url(#Icon_-_Sag_svg__a)'
        transform='translate(3.879 1.042)'
      >
        <path
          data-name='Path 277'
          d='M9.758 16.655H4.643a.642.642 0 1 0 0 1.285h5.115a.642.642 0 1 0 0-1.285m2.388-2.555H4.473a.642.642 0 0 0 0 1.285h7.673a.642.642 0 1 0 0-1.285m0-2.558H4.473a.642.642 0 0 0 0 1.285h7.673a.642.642 0 1 0 0-1.285m0-2.558H4.473a.642.642 0 0 0 0 1.285h7.673a.642.642 0 0 0 0-1.285M14.7 21.819H1.915A1.919 1.919 0 0 1 0 19.9V6.388h4.473a1.919 1.919 0 0 0 1.915-1.915V0H14.7a1.919 1.919 0 0 1 1.915 1.915V19.9a1.919 1.919 0 0 1-1.915 1.919'
        />
      </g>
      <g
        data-name='Group 228'
        clipPath='url(#Icon_-_Sag_svg__a)'
        transform='translate(3.879 1.042)'
      >
        <path
          data-name='Path 278'
          d='M4.476 5.104h-4.1l4.74-4.74v4.1a.639.639 0 0 1-.642.642'
        />
      </g>
    </g>
  </svg>
);

export default SvgIconSag;
