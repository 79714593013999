import { createSlice } from '@reduxjs/toolkit';

// Define initial state
const initialState = {
  bindings: [],
};

// Create a slice
const bindingsSlice = createSlice({
  name: 'bindings',
  initialState,
  reducers: {
    // Reducer function for setting bindings
    setBindings(state, action) {
      state.bindings = action.payload;
    },
  },
});

// Export reducer and action creators
export const { setBindings } = bindingsSlice.actions;
export default bindingsSlice.reducer;
