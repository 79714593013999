/**
 * @module actions/customerGroups
 */
import axios from 'axios';
import { API_URL } from '../constants';
import { setCustomerGroups } from '../slices/customerGroupsSlice';

/**
 * @description Get list of customerGroups from API and dispatching event
 * @return {Promise} Axios promise
 */
export function getCustomerGroups() {
  let url = API_URL + '/cugroup/groups';
  return dispatch => {
    return axios.get(url).then(res => {
      dispatch(setCustomerGroups(res.data));
      return res.data;
    });
  };
}

/**
 * @description Get customer group from API
 * @return {Promise} Axios promise
 */
export function getCustomerGroup(id) {
  let url = API_URL + '/cugroup/' + id;
  return dispatch => {
    return axios.get(url);
  };
}

/**
 * @description Create customer group
 * @return {Promise} Axios promise
 */
export function CreateCustomerGroup(data) {
  let url = API_URL + '/cugroup';
  return dispatch => {
    return axios.post(url, data);
  };
}

/**
 * @description Update customer group
 * @return {Promise} Axios promise
 */
export function UpdateCustomerGroup(id, data) {
  let url = API_URL + `/cugroup/${id}/update`;
  return dispatch => {
    return axios.post(url, data);
  };
}

/**
 * @description "Delete" customer group
 * @return {Promise} Axios promise
 */
export function DeleteCustomerGroup(id, data) {
  let url = API_URL + `/cugroup/${id}/delete`;
  return dispatch => {
    return axios.post(url, data);
  };
}
