import { createSlice } from '@reduxjs/toolkit';

// Define initial state
const initialState = {
  schemaGroups: [],
  schemaOptions: [],
  currentSchemaGroup: null,
  currentSchema: null,
  marked: { show: false, schemas: [], groups: [] },
};

// Create a slice
const schemaSlice = createSlice({
  name: 'schema',
  initialState,
  reducers: {
    // Reducer function for getting schema groups
    setSchemaGroups(state, action) {
      state.schemaGroups = action.payload;
    },
    // Reducer function for refreshing schema groups with new group
    refreshGroup(state, action) {
      const { group } = action.payload;
      const updatedGroups = state.schemaGroups.map(schemaGroup =>
        schemaGroup.id === group.id ? group : schemaGroup,
      );
      state.schemaGroups = updatedGroups;
    },
    // Reducer function for setting current schema group
    setCurrentSchemaGroup(state, action) {
      state.currentSchemaGroup = action.payload;
    },
    // Reducer function for setting current schema
    setCurrentSchema(state, action) {
      state.currentSchema = action.payload;
    },
    // Reducer function for getting schema options
    setSchemaOptions(state, action) {
      state.schemaOptions = action.payload;
    },
    // Reducer function for setting marked schemas
    setMarkedSchemas(state, action) {
      state.marked = action.payload;
    },
  },
});

// Export reducers and action creators
export const {
  setSchemaGroups,
  refreshGroup,
  setCurrentSchemaGroup,
  setCurrentSchema,
  setSchemaOptions,
  setMarkedSchemas,
} = schemaSlice.actions;

// Export reducer function
export default schemaSlice.reducer;
