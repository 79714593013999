import { createSlice } from '@reduxjs/toolkit';

// Define initial state
const initialState = {
  headerInfo: {
    companyName: '',
  },
  userLanguage: 'eng',
  userBranding: null,
  termsOfAgreementHandled: false,
  agreementSchemaSettings: [],
  userFilters: null,
  hideModalSettings: null,
  imageHash: 0,
};

// Create a single slice
const commonSlice = createSlice({
  name: 'common',
  initialState,
  reducers: {
    setHeaderInfo(state, action) {
      state.headerInfo = action.payload;
    },
    setUserLanguage(state, action) {
      state.userLanguage = action.payload;
    },
    setUserBranding(state, action) {
      state.userBranding = action.payload;
    },
    handleTermsOfAgreement(state, action) {
      state.termsOfAgreementHandled = action.payload;
    },
    setAgreementSchemaSettings(state, action) {
      state.agreementSchemaSettings = action.payload;
    },
    setUserFilters(state, action) {
      state.userFilters = action.payload;
    },
    setHideModalSettings(state, action) {
      state.hideModalSettings = action.payload;
    },
    setImageHash(state, action) {
      state.imageHash = action.payload;
    },
  },
});

// Export actions and reducer
export const {
  setHeaderInfo,
  setUserLanguage,
  setUserBranding,
  handleTermsOfAgreement,
  setSAFilter,
  setAgreementSchemaSettings,
  setUserFilters,
  setHideModalSettings,
  setImageHash,
} = commonSlice.actions;

export default commonSlice.reducer;
