import React from 'react';
//import axios from 'axios';
import componentQueries from 'react-component-queries';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import { Provider } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import { ConnectedRouter } from 'connected-react-router';

// Page Imports
import LoginPage from './pages/Login';
import Verification from './pages/Verification';
import ForgotPasswordPage from './pages/ForgotPassword';
import ResetPasswordPage from './pages/ResetPassword';
import LogoutPage from './pages/Logout';
import ApprovalPage from './pages/MasterCase/Modules/Hotwork/View/Approval';
import PdfPage from './pages/PdfPage';
import PdfSchemaPage from './pages/PdfSchemaPage';
import VaInfoPage from './pages/VaInfoPage';
import DocumentsPage from './pages/DocumentsPage';
import CreateProfilePage from './pages/CreateProfilePage';
import SchemaSigning from './pages/SchemaSigning';

// Style
import 'react-toastify/dist/ReactToastify.css';
import './styles/reduction.scss';

// Redux
import setupStore, { history } from './redux/store';
//import { TEMPLATE } from './redux/constants';

// Components
import { EmptyLayout, LayoutRoute, MainLayout } from './components/Layout';
import PageSpinner from './components/PageSpinner';
import ErrorBoundary from './components/Custom/ErrorBoundary';
import LoadingBoundary from './components/Custom/LoadingBoundary';
import CacheBuster from './CacheBuster';
import CasesOverview from './pages/MasterCase/CasesOverview';

// Other
const { detect } = require('detect-browser');
const initialState = {};
const store = setupStore(initialState);

// Page lazyloading (code-splitting --> quicker loading, efficiency)
const DashboardPage = React.lazy(() => import('./pages/DashboardPage'));
const CustomerGroupsPage = React.lazy(() => import('./pages/CustomerGroups'));
const InsuranceCompaniesPage = React.lazy(() =>
  import('./pages/InsuranceCompanies'),
);
const SystemInfo = React.lazy(() => import('./pages/SystemInfo'));
const SystemMessage = React.lazy(() => import('./pages/SystemMessage'));
const SystemInfoView = React.lazy(() => import('./pages/SystemInfoView'));
const ExternalPartnersPage = React.lazy(() =>
  import('./pages/ExternalPartnersPage'),
);
const HotworksOverview = React.lazy(() => import('./pages/HotworksOverview'));
const HotworkPage = React.lazy(() =>
  import('./pages/MasterCase/Modules/Hotwork'),
);
const CreateHotworkPage = React.lazy(() =>
  import('./pages/MasterCase/Modules/Hotwork/View/CreateHotwork'),
);
const DraftHotworkPage = React.lazy(() =>
  import('./pages/MasterCase/Modules/Hotwork/View/Draft'),
);
const QualityInsurances = React.lazy(() =>
  import('./pages/QualityInsurancesOverview'),
);
const QualityInsurance = React.lazy(() => import('./pages/QualityInsurance'));
const CreateQualityInsurancePage = React.lazy(() =>
  import('./pages/CreateQualityInsurancePage'),
);
const ViewQualityInsuranceCase = React.lazy(() =>
  import('./pages/ViewQualityInsuranceCase'),
);
const SettingsPage = React.lazy(() => import('./pages/SettingsPage'));
const BusinessIntelligence = React.lazy(() =>
  import('./pages/BusinessIntelligence'),
);
//const TemplateGlobalMaster = React.lazy(() => import('./pages/TemplatePage'));
const ProductsPage = React.lazy(() => import('./pages/ProductsPage'));
const UserTeams = React.lazy(() => import('./pages/UserTeams'));
const PushMessage = React.lazy(() => import('./pages/PushMessage'));
const ServiceAgreements = React.lazy(() =>
  import('./pages/ServiceAgreementsOverview'),
);
const ServiceAgreement = React.lazy(() => import('./pages/ServiceAgreement'));
const CreateServiceAgreementPage = React.lazy(() =>
  import('./pages/CreateServiceAgreementPage'),
);
// const TermsOfAgreementsAdmin = React.lazy(() =>
//   import('./pages/TermsOfAgreementAdmin'),
// );
const PoliciesPage = React.lazy(() => import('./pages/PoliciesPage'));
const ProfilePage = React.lazy(() => import('./pages/ProfilePage'));
const FeaturePage = React.lazy(() => import('./pages/FeatureConfigurator'));
//const CustomersOverview = React.lazy(() => import('./pages/CustomersOverview'));
const CustomerTabs = React.lazy(() => import('./pages/CustomerTabs'));
const RoadmapPage = React.lazy(() => import('./pages/RoadmapPage'));
const CompanyBindings = React.lazy(() => import('./pages/CompanyBindings'));
const LanguageManager = React.lazy(() => import('./pages/LanguageManager'));
const SchemaBuilder = React.lazy(() => import('./pages/SchemaBuilder'));
const SchemaCategoryPage = React.lazy(() =>
  import('./pages/SchemaBuilder/SchemaCategories/SchemaCategoryPage'),
);
const CreateSchemaCategoryPage = React.lazy(() =>
  import('./pages/SchemaBuilder/SchemaCategories/CreateSchemaCategoryPage'),
);
const SchemaPackagePage = React.lazy(() =>
  import('./pages/SchemaBuilder/SchemaPackages/SchemaPackagePage'),
);
const CreateSchemaPackagePage = React.lazy(() =>
  import('./pages/SchemaBuilder/SchemaPackages/CreateSchemaPackagePage'),
);
const CreateSchema = React.lazy(() =>
  import('./pages/SchemaBuilder/ControlSchemas/CreateSchema'),
);
const ControlSchema = React.lazy(() =>
  import('./pages/SchemaBuilder/ControlSchemas/ControlSchema'),
);
const CreateControlItem = React.lazy(() =>
  import('./pages/SchemaBuilder/ControlItems/CreateControlItem'),
);
const ControlItem = React.lazy(() =>
  import('./pages/SchemaBuilder/ControlItems/ControlItem'),
);
const MasterCase = React.lazy(() => import('./pages/MasterCase'));

class App extends React.Component {
  renderUnsupportedBrowser() {
    return (
      <div className='unsupported-container'>
        <p>
          Vi understøtter ikke Internet Explorer-browseren. Benyt venligst
          <a href='https://www.microsoft.com/da-dk/edge'>Edge</a>,
          <a href='https://www.google.com/chrome/'>Chrome</a>,
          <a href='https://www.apple.com/safari/'>Safari</a>
          {` `}
          Eller
          <a href='https://www.mozilla.org/da/firefox/new/'>Firefox</a>
        </p>
      </div>
    );
  }

  render() {
    const browser = detect();

    if (browser) {
      if (browser.name === 'ie') {
        return this.renderUnsupportedBrowser();
      }
    }

    return (
      <CacheBuster>
        <Provider store={store}>
          <ErrorBoundary simple={false}>
            <LoadingBoundary>
              <ConnectedRouter history={history}>
                <BrowserRouter>
                  <Switch>
                    <LayoutRoute
                      exact
                      path='/login'
                      layout={EmptyLayout}
                      component={props => <LoginPage {...props} />}
                    />
                    <LayoutRoute
                      exact
                      path='/verification'
                      layout={EmptyLayout}
                      component={props => <Verification {...props} />}
                    />
                    <LayoutRoute
                      exact
                      path='/create-profile'
                      layout={EmptyLayout}
                      component={props => <CreateProfilePage {...props} />}
                    />
                    <LayoutRoute
                      exact
                      path='/forgot-password'
                      layout={EmptyLayout}
                      component={props => <ForgotPasswordPage {...props} />}
                    />
                    <LayoutRoute
                      exact
                      path='/reset-password'
                      layout={EmptyLayout}
                      component={props => <ResetPasswordPage {...props} />}
                    />
                    <LayoutRoute
                      exact
                      path='/logout'
                      layout={EmptyLayout}
                      component={props => <LogoutPage {...props} />}
                    />
                    <Route
                      exact
                      path='/approval'
                      component={props => <ApprovalPage {...props} tokenView />}
                    />
                    {/* TODO DEPRECATED ROUTE - NEEDS TO BE REMOVED AFTER A RELEASE */}
                    <Route
                      exact
                      path='/case/va'
                      component={props => <VaInfoPage {...props} />}
                    />
                    <Route
                      exact
                      path='/hotwork/va'
                      component={props => <PdfPage {...props} />}
                    />
                    <Route
                      exact
                      path='/hotwork/:id/approval'
                      component={props => (
                        <ApprovalPage userSignedInApproval {...props} />
                      )}
                    />
                    {/* TODO DEPRECATED ROUTE - NEEDS TO BE REMOVED AFTER A RELEASE */}
                    <Route
                      exact
                      path='/cases/:id/approval'
                      component={props => (
                        <ApprovalPage userSignedInApproval {...props} />
                      )}
                    />
                    <Route
                      exact
                      path='/file'
                      component={props => <PdfPage {...props} />}
                    />
                    <Route
                      exact
                      path='/schema'
                      component={props => <PdfSchemaPage {...props} />}
                    />

                    <Route
                      exact
                      path='/signing/:token?'
                      component={props => <SchemaSigning {...props} />}
                    />
                    <MainLayout
                      breakpoint={this.props.breakpoint}
                      history={history}
                    >
                      <React.Suspense fallback={<PageSpinner />}>
                        <Route
                          exact
                          path='/'
                          component={props => <DashboardPage {...props} />}
                        />
                        <Route
                          exact
                          path='/groups'
                          component={props => <CustomerGroupsPage {...props} />}
                        />
                        <Route
                          exact
                          path='/bi'
                          component={props => (
                            <BusinessIntelligence {...props} />
                          )}
                        />
                        <Route
                          exact
                          path='/systeminfo'
                          component={props => <SystemInfo {...props} />}
                        />
                        <Route
                          exact
                          path='/systempush'
                          component={props => <PushMessage {...props} />}
                        />
                        <Route
                          exact
                          path='/systemMessages'
                          component={props => <SystemMessage {...props} />}
                        />
                        <Route
                          exact
                          path='/systeminfo-view'
                          component={props => <SystemInfoView {...props} />}
                        />
                        <Route
                          exact
                          path='/settings'
                          component={props => <SettingsPage {...props} />}
                        />
                        <Route
                          exact
                          path='/insurances'
                          component={props => (
                            <InsuranceCompaniesPage {...props} />
                          )}
                        />
                        <Route
                          exact
                          path='/customers/:id?'
                          component={props => <CustomerTabs {...props} />}
                        />
                        <Route
                          exact
                          path='/users/:id?'
                          component={props => <UserTeams {...props} />}
                        />
                        <Route
                          exact
                          path='/external-parties/:id?'
                          component={props => (
                            <ExternalPartnersPage {...props} />
                          )}
                        />
                        <Route
                          exact
                          path='/hotwork'
                          component={props => <HotworksOverview {...props} />}
                        />
                        <Route
                          exact
                          path='/hotwork/create'
                          component={props => <CreateHotworkPage {...props} />}
                        />
                        <Route
                          exact
                          path='/hotwork/case/:id/edit'
                          component={props => <DraftHotworkPage {...props} />}
                        />
                        <Route
                          exact
                          path='/hotwork/case/:id'
                          component={props => <HotworkPage {...props} />}
                        />
                        <Route
                          exact
                          path='/quality'
                          component={props => <QualityInsurances {...props} />}
                        />
                        <Route
                          exact
                          path='/serviceAgreements'
                          component={props => <ServiceAgreements {...props} />}
                        />
                        <Route
                          exact
                          path='/quality/create'
                          component={props => (
                            <CreateQualityInsurancePage {...props} />
                          )}
                        />
                        <Route
                          exact
                          path='/quality/case/:id'
                          component={props => <QualityInsurance {...props} />}
                        />
                        <Route
                          exact
                          path='/quality/view/:id'
                          component={props => (
                            <ViewQualityInsuranceCase {...props} />
                          )}
                        />
                        <Route
                          exact
                          path='/serviceAgreements/create'
                          component={props => (
                            <CreateServiceAgreementPage {...props} />
                          )}
                        />
                        <Route
                          exact
                          path='/serviceAgreements/case/:id'
                          component={props => <ServiceAgreement {...props} />}
                        />
                        <Route
                          exact
                          path='/documents'
                          component={props => <DocumentsPage {...props} />}
                        />
                        {/* <Route
                          exact
                          path='/template'
                          component={props => (
                            <TemplateGlobalMaster
                              {...props}
                              type={TEMPLATE.GlobalMaster}
                              templateId={1}
                            />
                          )}
                        /> */}
                        <Route
                          exact
                          path='/products'
                          component={props => <ProductsPage {...props} />}
                        />
                        {/* <Route
                          exact
                          path='/policies'
                          component={props => (
                            <TermsOfAgreementAdmin {...props} />
                          )}
                        /> */}
                        <Route
                          exact
                          path='/policies'
                          component={props => <PoliciesPage {...props} />}
                        />
                        <Route
                          exact
                          path='/profile'
                          component={props => <ProfilePage {...props} />}
                        />
                        <Route
                          exact
                          path='/featureconfig'
                          component={props => <FeaturePage {...props} />}
                        />
                        <Route
                          exact
                          path='/roadmap'
                          component={props => <RoadmapPage {...props} />}
                        />
                        <Route
                          exact
                          path='/companies'
                          component={props => <CompanyBindings {...props} />}
                        />
                        <Route
                          exact
                          path='/language'
                          component={props => <LanguageManager {...props} />}
                        />
                        <Route
                          exact
                          path='/schemabuilder'
                          component={props => <SchemaBuilder {...props} />}
                        />
                        <Route
                          exact
                          path='/schemabuilder/category/create'
                          component={props => (
                            <CreateSchemaCategoryPage {...props} />
                          )}
                        />
                        <Route
                          exact
                          path='/schemabuilder/category/edit/:id?'
                          component={props => <SchemaCategoryPage {...props} />}
                        />
                        <Route
                          exact
                          path='/schemabuilder/schema-package/create'
                          component={props => (
                            <CreateSchemaPackagePage {...props} />
                          )}
                        />
                        <Route
                          exact
                          path='/schemabuilder/schema-package/edit/:id?'
                          component={props => <SchemaPackagePage {...props} />}
                        />
                        <Route
                          exact
                          path='/schemabuilder/create-schema'
                          component={props => <CreateSchema {...props} />}
                        />
                        <Route
                          exact
                          path='/schemabuilder/controlschema/:id?'
                          component={props => <ControlSchema {...props} />}
                        />
                        <Route
                          exact
                          path='/schemabuilder/create-control-item'
                          component={props => <CreateControlItem {...props} />}
                        />
                        <Route
                          exact
                          path='/schemabuilder/control-item/:id?'
                          component={props => <ControlItem {...props} />}
                        />

                        {/* MasterCase pages */}
                        <Route
                          exact
                          path='/cases'
                          component={props => <CasesOverview {...props} />}
                        />
                        <Route
                          exact
                          path='/cases/:id'
                          component={props => <MasterCase {...props} />}
                        />
                      </React.Suspense>
                    </MainLayout>
                    <Redirect to='/' />
                  </Switch>
                </BrowserRouter>
              </ConnectedRouter>
              <ToastContainer />
            </LoadingBoundary>
          </ErrorBoundary>
        </Provider>
      </CacheBuster>
    );
  }
}

const query = ({ width }) => {
  if (width < 575) {
    // console.log('bp xs', width);
    return { breakpoint: 'xs' };
  }

  if (575 <= width && width < 767) {
    // console.log('bp sm', width);
    return { breakpoint: 'sm' };
  }

  if (767 <= width && width < 991) {
    // console.log('bp md', width);
    return { breakpoint: 'md' };
  }

  if (991 <= width && width <= 1200) {
    // console.log('bp lg', width);
    return { breakpoint: 'lg' };
  }

  if (width > 1200) {
    // console.log('bp xl', width);
    return { breakpoint: 'xl' };
  }

  // console.log('bp xs d', width);
  return { breakpoint: 'xs' };
};

export default componentQueries(query)(App);
