import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isAuthenticated: false,
  user: {},
  loginAsUser: null,
  showPausedModal: false,
  handledPausedModal: false,
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setCurrentUser(state, action) {
      state.user = action.payload;
      state.isAuthenticated =
        (!(Object.keys(action.payload).length === 0) &&
          action.payload.verification === 0) ||
        (action.payload.verification !== 0 && action.payload.token);
    },
    setLoginAs(state, action) {
      state.loginAsUser = action.payload;
    },
    logOutAs(state, action) {
      state.loginAsUser = null;
    },
    setPausedModal(state, action) {
      state.showPausedModal = action.payload;
    },
    setHandledPausedModal(state, action) {
      state.handledPausedModal = action.payload;
    },
    setPermissionSet(state, action) {
      state.user.user.permissions = {
        ...state.user.user.permission,
        ...action.payload,
      };
    },
  },
});

// `createSlice` automatically generated action creators with these names.
// export them as named exports from this "slice" file
export const {
  setCurrentUser,
  setLoginAs,
  logOutAs,
  setPausedModal,
  setHandledPausedModal,
  setPermissionSet,
} = authSlice.actions;

// Export the slice reducer as the default export
export default authSlice.reducer;
