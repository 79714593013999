/**
 * @module actions/customers
 */
import axios from 'axios';
import { API_URL, CUSTOMER_STATUS } from '../constants';
import {
  setCustomer,
  setCustomers,
  setCustomerStatus,
} from '../slices/customerSlice';
import { setPausedModal } from '../slices/authSlice';

/**
 * @description Getting customers from API and dispatching event
 * @return {Promise} Axios promise
 */
export function getCustomers(id) {
  let url = API_URL + `/customers/${id}`;
  return dispatch => {
    return axios.get(url).then(res => {
      if (res.status === 200) {
        dispatch(setCustomers(res.data));
      }
      return res;
    });
  };
}

/**
 * @description Getting a single customer from API and dispatching event
 * @return {Promise} Axios promise
 */
export function getCustomer(id, compId) {
  let url = API_URL + `/customers/${id}/${compId}`;
  return dispatch => {
    return axios.get(url).then(res => {
      if (res.status === 200) {
        dispatch(setCustomer(res.data));
      }
      return res;
    });
  };
}

/**
 * @description Creating a customer
 * @return {Promise} Axios promise
 */
export function CreateCustomer(id, data) {
  let url = API_URL + `/customers/${id}`;
  return dispatch => {
    return axios.post(url, data);
  };
}

/**
 * @description Updating a customer
 * @return {Promise} Axios promise
 */
export function UpdateCustomer(id, custId, data) {
  let url = API_URL + `/customers/${id}/${custId}/update`;
  return dispatch => {
    return axios.post(url, data);
  };
}

/**
 * @description "Deleting" a customer
 * @return {Promise} Axios promise
 */
export function DeleteCustomer(custId, data) {
  let url = API_URL + `/customers/delete/${custId}`;
  return dispatch => {
    return axios.post(url, data);
  };
}

/**
 * @description Update default insurance company
 * @return {Promise} Axios promise
 */
export function updateDefaultInsurance(data) {
  let url = API_URL + `/Customers/insurance`;
  return dispatch => {
    return axios.post(url, data);
  };
}

/**
 * @description Update customers base information
 * @return {Promise} Axios promise
 */
export function updateBaseInformation(data) {
  let url = API_URL + `/Customers/update-base`;
  return dispatch => {
    return axios.post(url, data);
  };
}

/**
 * @description Fetch customers company information via CVR
 * @return {Promise} Axios promise
 */
export function getCompanyInformationWithCvr(data) {
  let url = API_URL + `/Customers/cvr`;
  return dispatch => {
    return axios.post(url, data);
  };
}

/**
 * @description Update customers description for PDF's
 * @return {Promise} Axios promise
 */
export function updateCustomerDescription(data) {
  let url = API_URL + `/Customers/description`;
  return dispatch => {
    return axios.post(url, data);
  };
}

/**
 * @description Get case document names from a customer
 * @return {Promise} Axios promise
 */
export function getCaseDocumentNames(data) {
  let url = API_URL + `/Customers/documents/casenames`;
  return dispatch => {
    return axios.post(url, data);
  };
}

/**
 * @description Update case document name
 * @return {Promise} Axios promise
 */
export function updateCaseDocumentNames(data) {
  let url = API_URL + `/Customers/documents/casenames/update`;
  return dispatch => {
    return axios.post(url, data);
  };
}

/**
 * @description Delete case document name
 * @return {Promise} Axios promise
 */
export function deleteCaseDocumentNames(data) {
  let url = API_URL + `/Customers/documents/casenames/delete`;
  return dispatch => {
    return axios.post(url, data);
  };
}

/**
 * @description Get customer information when adding a new binding
 * @return {Promise} Axios promise
 */
export function getCustomerBindingInfo(data) {
  let url = API_URL + `/Customers/get-customer-info`;
  return dispatch => {
    return axios.post(url, data);
  };
}

/**
 * @description Getting customers from API and dispatching event
 * @return {Promise} Axios promise
 */
export function getCurrentCompanyOwner(id) {
  let url = API_URL + `/customers/cco?custId=${id}`;
  return dispatch => {
    return axios.get(url);
  };
}

/**
 * @description Get customer schema packages
 * @return {Promise} Axios promise
 */
export function getCustomerSchemaPackages(id) {
  let url = API_URL + `/Customers/packages/` + id;
  return dispatch => {
    return axios.get(url);
  };
}

/**
 * @description Update customer schema packages
 * @return {Promise} Axios promise
 */
export function updateCustomerSchemaPackages(data) {
  let url = API_URL + `/Customers/packages`;
  return dispatch => {
    return axios.post(url, data);
  };
}

/**
 * @description Update customer schema packages
 * @return {Promise} Axios promise
 */
export function getCustomerEditLogs(data) {
  let url = API_URL + `/Customers/logs`;
  return dispatch => {
    return axios.post(url, data);
  };
}

/**
 * Retrieves the customers status
 * @returns customer status enum - default: PAUSED = 3
 */
export const getCustomerStatus = () => {
  return dispatch => {
    let url = API_URL + '/customers/status';

    return axios.get(url).then(res => {
      if (res.status === 404) {
        dispatch(setCustomerStatus(CUSTOMER_STATUS.Paused));
      } else {
        dispatch(setCustomerStatus(res.data));
      }

      dispatch(setPausedModal(res.data === CUSTOMER_STATUS.Paused));

      return res.data;
    });
  };
};
