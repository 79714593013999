import { createSlice } from '@reduxjs/toolkit';

// Define initial state
const initialState = {
  packages: [],
  schemas: [],
  categories: [],
  controls: [],
  currentSchema: null,
  currentControlItem: null,
  currentPackage: null,
  currentCategory: null,
  relations: [],
  groups: [],
  criterias: [],
  relation: null,
  group: null,
};

// Create a slice
const schemaBuilderSlice = createSlice({
  name: 'schemaBuilder',
  initialState,
  reducers: {
    // Reducer function for getting schemas
    setSBSchemas(state, action) {
      state.schemas = action.payload;
    },
    // Reducer function for setting current schema
    setSBCurrentSchema(state, action) {
      state.currentSchema = action.payload;
    },
    // Reducer function for getting control items
    setSBControlItems(state, action) {
      state.controls = action.payload;
    },
    // Reducer function for setting current control item
    setSBCurrentControlItem(state, action) {
      state.currentControlItem = action.payload;
    },
    // Reducer function for getting schema categories
    setSBSchemaCategories(state, action) {
      state.categories = action.payload;
    },
    // Reducer function for setting current schema category
    setSBCurrentSchemaCategory(state, action) {
      state.currentCategory = action.payload;
    },
    // Reducer function for setting current schema package
    setSBCurrentSchemaPackage(state, action) {
      state.currentPackage = action.payload;
    },
    // Reducer function for getting relation controls
    setSBRelationControls(state, action) {
      state.relations = action.payload;
    },
    // Reducer function for setting relation control
    setSBRelationControl(state, action) {
      state.relation = action.payload;
    },
    // Reducer function for getting relation groups
    setSBRelationGroups(state, action) {
      state.groups = action.payload;
    },
    // Reducer function for setting relation group
    setSBRelationGroup(state, action) {
      state.group = action.payload;
    },
    // Reducer function for getting criterias
    setSBCriterias(state, action) {
      state.criterias = action.payload;
    },
  },
});

// Export reducers and action creators
export const {
  setSBSchemas,
  setSBCurrentSchema,
  setSBControlItems,
  setSBCurrentControlItem,
  setSBSchemaCategories,
  setSBCurrentSchemaCategory,
  setSBCurrentSchemaPackage,
  setSBRelationControls,
  setSBRelationControl,
  setSBRelationGroups,
  setSBRelationGroup,
  setSBCriterias,
} = schemaBuilderSlice.actions;

// Export reducer function
export default schemaBuilderSlice.reducer;
