import { createSlice } from '@reduxjs/toolkit';

// Define initial state
const initialState = {
  teams: [],
};

// Create a slice
const teamSlice = createSlice({
  name: 'team',
  initialState,
  reducers: {
    // Reducer function for setting features
    setTeams(state, action) {
      state.teams = action.payload;
    },
  },
});

// Export reducer and action creator
export const { setTeams } = teamSlice.actions;

// Export reducer function
export default teamSlice.reducer;
