import React from 'react';
import i18next from '../../i18n';
import { Button, Modal, Card, CardBody, CardHeader } from 'reactstrap';
import PageSpinner from '../PageSpinner';

const ConfirmModal = ({
  color,
  cancelColor,
  showActionSpinner,
  confirmText,
  cancelText,
  toggle,
  show,
  header,
  confirmFunc,
  cancelFunc,
  children,
}) => {
  const renderActionSpinner = () => {
    if (showActionSpinner) {
      return <PageSpinner color='primary' extraClass='margin-bot-15px' />;
    }
  };

  let toggleAction = !showActionSpinner ? toggle : null;

  let btnConfirmText = confirmText ? confirmText : i18next.t(1);
  let btnCancelText = cancelText ? cancelText : i18next.t(2);

  return (
    <Modal isOpen={show} toggle={toggleAction} centered backdrop='static'>
      <Card>
        {header && <CardHeader>{header}</CardHeader>}
        <CardBody>
          {renderActionSpinner()}
          {children}
          <Button
            block
            color={color}
            onClick={confirmFunc}
            disabled={showActionSpinner}
          >
            {btnConfirmText}
          </Button>
          <Button
            outline
            block
            color={cancelColor}
            onClick={cancelFunc}
            disabled={showActionSpinner}
          >
            {btnCancelText}
          </Button>
        </CardBody>
      </Card>
    </Modal>
  );
};

ConfirmModal.defaultProps = {
  color: 'danger',
  cancelColor: 'btnSecondary',
};

export default ConfirmModal;
