// API URL
export const API_URL = process.env.REACT_APP_API_URL;

// Third Party API
export const POSTAL_API = 'https://dawa.aws.dk/';

// Icon constants
export const DEFAULT_ICON_COLOR = '#003341';

// Months
export const MONTHS = {
  January: 1,
  February: 2,
  March: 3,
  April: 4,
  May: 5,
  June: 6,
  July: 7,
  August: 8,
  September: 9,
  October: 10,
  November: 11,
  December: 12,
};

// Employee Type constants
export const EMPLOYEE_TYPE = {
  None: 0,
  SuperAdmin: 1,
  LocalAdmin: 2,
  ResponsiblePerson: 3,
  // Landlord: 4, // Unused - but kept in list
  ExecutivePerson: 5,
  BuildingOwner: 6,
  Firewatch: 7,
  Subcontractor: 8,
  RoofingContractor: 9,
  Advisor: 10,
  Architect: 11,
  InternalSubcontractor: 12,
  Supplier: 13,
  CompanyOwner: 14,
};

export const USER_POSITION_TYPE = {
  None: 0,
  RoofingContractor: 1,
  EnterpriseLeader: 2,
  Director: 3,
  DepartmentLead: 4,
  Planner: 5,
  Calculator: 6,
  Advisor: 7,
  Architect: 8,
  Specialist: 9,
  Supplier: 10,
  Customer: 11,
  Student: 12,
  Other: 13,
};

// AccessLevel constants
export const ACCESS_LEVEL = {
  SuperAdmin: 1,
  LocalAdmin: 2,
  CustomerAdmin: 3,
  Employee: 4,
  None: 100,
};

// Assignee Types
export const ASSIGNEE_TYPE = {
  ExecutivePerson: 1,
  Firewatch: 2,
  BuildingOwner: 3,
  ExecutiveResponsible: 4,
  Subcontractor: 5,
  // ContactForCompany: 6,
  // ContactForProjectOrganisation: 7,
  // ContactForSubcontractor: 8,
  ContactForInternalOrganisation: 9,
  // ContactForEmployee: 10,
  Employee: 11,
  RoofingContractor: 12,
  Advisor: 13,
  Architect: 14,
  Supplier: 15,
  None: 100,
};

// Used in conjunction with contaction information
export const SIMPLE_USERS = [
  EMPLOYEE_TYPE.BuildingOwner,
  EMPLOYEE_TYPE.Firewatch,
  EMPLOYEE_TYPE.RoofingContractor,
  EMPLOYEE_TYPE.Subcontractor,
];

// Business Intelligence Search types
export const BI_SEARCH_TYPES = {
  Unknown: 0,
  CurrentCasesVA: 1,
  CurrentCasesKS: 2,
  // Users: 3,
  Companies: 4,
  CompanyUsers: 5,
  CompanyUsersInternal: 6,
  CompanyUsersExternal: 7,
  UsersWithoutBindings: 8,
  CurrentCases: 12,
  CurrentCasesSA: 13,
  ControlSchemas: 14,
};

// Template types
export const TEMPLATE = {
  GlobalMaster: 1,
  // GroupMaster: 2, // not yet implemented
  CustomerMaster: 3,
  Template: 4,
};

// Template section types
export const TEMPLATE_SECTIONS = {
  None: 0,
  RecieveControl: 1,
  ExecutiveControl: 2,
  FinalControl: 3,
  RunnerUpControl: 4,
  ServiceAgreement: 5,
};

// Expire date types
export const EXPIRE_TYPES = {
  Hour: 0,
  Day: 1,
  Week: 2,
  Month: 3,
  Year: 6,
  Permanent: 4,
  Custom: 5,
};

// Description types
export const DESCRIPTION_TYPE = {
  Customer: 0,
  OperationAndMaintenance: 1,
  QualityInsuranceDescription: 2,
};

// Hide Modal types
export const HIDE_MODAL_TYPES = {
  PdfGenerationModalWeb: 0,
  PdfGenerationModalApp: 1,
};

// System Message Types
export const SYSTEM_MESSAGE_TYPE = {
  STANDARD: 0,
  INFORMATIVE: 1,
  WARNING: 2,
  URGENT: 3,
  STANDARD_PRIMARY: 4,
};

// Error types
export const ERROR_TYPE = {
  ApiError: 0,
  WebError: 1,
};
// Team Update Types
export const TEAM_UPDATE = {
  TeamCreate: 0,
  TeamUpdate: 1,
  TeamDelete: 2,
  AddMember: 100,
  RemoveMember: 101,
};

// System Push message types
export const PUSH_TYPE = {
  None: 0,
  Users: 1,
  Teams: 2,
  Companies: 3,
  Groups: 4,
  Global: 5,
};

// Device Types
export const DEVICE_TYPE = {
  iOS: 1,
  iPhoneOS: 2,
  Android: 3,
  None: 100,
};

// Case states
export const CASE_STATE = {
  NotAccepted: 1,
  Accepted: 2,
  InProgress: 3,
  Done: 4,
  Created: 5, // Quality Insurance Case
  Active: 6, // Quality Insurance Case
  Invoiced: 7,
  MissingControls: 8,
  IncompleteControl: 9,
  CompletedControl: 10,
  Subscribed: 11,
  Unsubscribed: 12,
  Draft: 99,
  None: 100,
  NullState: 1000,
};

export const CASE_FILE_TYPE = {
  Unknown: 0,
  Case: 1,
  QualtiyCase: 2,
  CoverImage: 3,
  Contract: 4,
  CompressedImage: 5,
  ControlImage: 6,
  BuildingImage: 7,
  KSGenericDocument: 20000,
  KSFixingPlan: 20001,
};

// Search categories
export const SEARCH_CATEGORY = {
  All: 0,
  New: 1,
  Archive: 2,
};

// Shapes
export const MARKING_TYPE = {
  Square: 1,
  Circle: 2,
};

// Features
export const FEATURE = {
  Hotwork: 0,
  QualityInsurance: 1,
  ServiceAgreement: 2,
  Documents: 3,
  DocumentsBasic: 4,
  Roadmap: 5,
  Materials: 6,
  SBSchemas: 7,
  PdfSchemaPrinting: 8,
};

export const FEATURE_SUB = {
  Standard: 0,
  Basis: 1,
  Plus: 2,
};

export const FEATURE_STATUS = {
  Unset: 0,
  Trial: 1,
  Purchased: 2,
  Disabled: 3,
};

// Customer states
export const CUSTOMER_STATUS = {
  Active: 1,
  Closed: 0,
  Paused: 3,
  // Trial: 2, // Not yet implemented
};

// Insurances
export const CUSTOM_ASSIGNMENT_TYPE_INSURANCES = {
  Koebstaedernes_forsikring: 1,
};

// Insurance type
export const INSURANCE_TYPE = {
  DBI_Forsikring: 1,
  Koebstaedernes_forsikring: 2,
};

// Assignment schemas
export const ASSIGNMENT_SCHEMA = {
  None: 100,
  WarmWorkStandard: 1,
  QualityWorkStandard: 2,
  WarmWorkStandardDBI: 101,
  WarmWorkStandardDBI_2021: 102,
  WarmWorkStandard_2021: 103,
  QBE_2022: 3,
  TRUST_2023: 4,
};

// Assignment types - VA
export const ASSIGNMENT_TYPE = {
  Standard: 0,
  Private: 1,
  ProfessionUnder50000: 2,
  ProfessionAbove50000: 3,
};

// Document Types
export const DOCUMENT_TYPE = {
  Generic: 0,
  Image: 1,
  Text: 2,
  Pdf: 3,
  Excel: 4,
  Word: 5,
  Powerpoint: 6,
  Video: 7,
  Numbers: 8,
  Pages: 9,
  Keynote: 10,
  Email: 11,
};

// Document Folder Types
export const DOCUMENT_FOLDER_TYPE = {
  Standard: 0,
  Global: 1,
  Cases: 2,
};

// Product types
export const PRODUCT_TYPES = {
  Standard: 1,
  Custom: 2,
  All: 3,
};

// Product Statuses
export const PRODUCT_STATUS = {
  // Case statuses
  CaseMissingMaterials: 0,
  CaseAwaitingMaterials: 1,
  CaseHasAllMaterials: 2,

  // Product status
  ProductHasNoSupplier: 3,
  ProductNotOrdered: 4,
  ProductOrdered: 5,
};

// Product sorting type
export const PRODUCT_SORT_BY = {
  ProductCategory: 0,
  SupplierName: 1,
  ProductStatus: 2,
};

// Roadmap date intervals
export const DATE_TYPE = {
  WEEKS: 0,
  MONTHS: 1,
  QUATERS: 2,
};

// Roadmap statuses
export const ROADMAP_STATUS = {
  None: 0,
  Active: 1,
  Complete: 2,
  Created: 3,
};

// Roadmap statuses
export const ROADMAP_PROJECT_STATE = {
  Start_Delayed: 0,
  End_Delayed: 1,
  Start_Moved: 2,
  End_Moved: 3,
};

// Roadmap statuses
export const ROADMAP_INFO_TYPE = {
  Generic: 0,
  QualityInsurance: 1,
  Materials: 2,
};

// Roadmap side menu types
export const ROADMAP_TYPE = {
  Generic: 0,
  QualityInsurance: 1,
  Materials: 2,
};

// Sorting types
export const SORT_TYPE = {
  None: 0,
  AZ: 1,
  ZA: 2,
  LowHigh: 3,
  HighLow: 4,
};

// Invitation Types
export const INVITATION_TYPE = {
  Unknown: 0,
  CustomerToUser: 1,
  UserToCustomer: 2,
  FromSystemToUser: 3,
  CustomerToNonExistingUser: 4,
};

// Invitation statuses
export const INVITATION_STATUS = {
  Unknown: 0,
  AwaitingUserResponse: 1,
  AwaitingCustomerResponse: 101,
  Accepted: 2,
  Rejected: 3,
};

// Notification type
export const NOTIFICATION_TYPE = {
  Message: 0,
};

// Notification priority
export const NOTIFICATION_PRIORITY = {
  Low: 0,
  Normal: 1,
  High: 2,
  Extreme: 3,
};

// Notification action type
export const NOTIFICATION_ACTION_TYPE = {
  None: 0,
  Simple: 1,
  Redirect: 2,
};

// Notification subject area
export const NOTIFICATION_SUBJECT_AREA = {
  None: 0,
  VA: 1,
  KS: 2,
  SA: 3,
  Invitation: 4,
  System: 5,
  ExternalLink: 6,
  PDF: 7,
  InvitationRequestAccess: 8,
  InvitationRequestAccepted: 9,
  SchemaSignature: 10,
};

// Notification settings actonis
export const NOTIFICATION_SETTINGS_ACTIONS = {
  UNKNOWN: 0,
  OPENED: 1,
  MARK_ALL_AS_READ: 2,
};

// Schema builder
export const SB_CONTROL_ITEM_TYPE = {
  Unset: 0,
  Standard: 1,
  Custom: 2,
};

export const SB_CONTROL_TYPE = {
  Unset: 0,
  PhotoAreaMarking: 1,
  PhotoDocumentation: 2,
  Signature: 3,
  DateSelector: 4,
  DropdownProducts: 5,
  DropdownUsers: 6,
  DropdownCustom: 7,
  Checkboxes: 8,
  Text: 9,
  TextUneditable: 10,
  Numbers: 11,
  TimeTableUsers: 12,
  Checkmark: 13,
  DropdownCommentPhotos: 14,
  TextPhotos: 15,
};

export const SB_CRITERIA_BEHAVIOR = {
  Unset: 0,
  AND: 1,
  OR: 2,
};

export const SB_CATEGORY_TYPE = {
  Unset: 0,
  Common: 1,
  RiskEvaluation: 2,
  ServiceAgreement: 3,
  ExtraSchema: 4,
};

// For rendering depending on context
export const CONTROL_PLAN_CONTEXT = {
  Global: 0,
  Local: 1,
  OnCase: 2,
};

// MISC
export const SCHEMA_OPTION_FILTER = {
  Unset: 0,
  Common: 1,
  RiskEvaluation: 2,
  ServiceAgreement: 3,
};

// Language categories
export const LANG_TEXT_CATEGORY = {
  Unknown: 0,
  General: 1,
  Mail: 2,
  Sms: 3,
  Push: 4,
};

export const RISK_ASSESSMENT = {
  NotSelected: 0,
  No: 1,
  Yes: 2,
  External: 3,
};

export const INDUSTRY_TYPE = {
  Unknown: 0,
  Roofing: 1,
  Production_Roofing: 2,
};

export const CUSTOMER_EDIT_EVENTS = {
  Unknown: 0,
  Created: 1,
  Paused: 2,
  Activated: 3,
  Closed: 5,
};

export const PDF_FILTER = {
  None: 0,
  CoverPage: 1,
  TableOfContents: 2,
  CaseInformation: 3,
  DEP_CompanyDescription: 4,
  DEP_QualityDescription: 5,
  Building: 6,
  DEP_OperationsAndMaintenance: 7,
  Datasheets: 8,
  SchemaStarted: 9,
  SchemaDone: 10,
  DEP_CaseDocuments: 11,
  DEP_CaseSchemas: 13,
  SchemaAll: 14,
  HidePrivateAssignees: 15,
  Contracts: 16,
  DateInterval: 17,
  BuildingAll: 18,
  SchemaGroupGuid: 19,
  CaseDescription: 20,
  CaseDescriptionAll: 21,
  CaseDocument: 22,
  CaseDocumentAll: 23,
  ControlPlan: 24,
  ControlPlanAll: 25,
  MaterialsOverview: 26,
};

export const MENU_TYPE = {
  None: 0,
  Information: 1,
  Module: 2,
  Settting: 3,
  Action: 4,
};

export const ACTIVE_MODULES = {
  Unknown: 0,
  Hotwork: 1,
  QualityInsurance: 2,
  ServiceAgreement: 3,
};

export const MODULE_STATUS = {
  Unknown: 0,
  Created: 1,
  Draft: 2,
  Approved: 3,
  Invoiced: 4,
  Active: 5,
  InProgress: 6,
  Done: 7,
  InActive: 8, // Not a real status, based on subscription
};

export const CASE_STATUS = {
  Unknown: 0,
  Created: 1,
  Offer: 2,
  Rejected: 3,
  Active: 4,
  ServiceAgreement: 5,
  Done: 6,
};

export const CASE_TYPE = {
  MasterCase: 0,
  WarmWork: 1,
  QualityInsurance: 2,
  ServiceAgreement: 3,
};
