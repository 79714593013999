import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import i18next from '../../i18n';
import { NavLink } from 'react-router-dom';
import {
  ProSidebar,
  Menu,
  MenuItem,
  // SidebarHeader,
  SidebarFooter,
  SidebarContent,
} from 'react-pro-sidebar';
import 'react-pro-sidebar/dist/css/styles.css';
import { ReactSVG } from 'react-svg';
import { HiChevronDoubleLeft } from 'react-icons/hi';
import { getTooltip } from '../../utils/tooltip';

// Actions
import { logout, loginOutAs } from './../../redux/actions/auth';

// Utils
import { sidebarRoutes } from './../../utils/contentByAccessLevel';
import { ACCESS_LEVEL, INVITATION_TYPE } from '../../redux/constants';

// Components
import { CompanyApprovalIcon, CompanyIcon } from '../Custom/Icons';
import LangaugePicker from '../Custom/LanguagePicker';

const Sidebar = props => {
  useEffect(() => {
    if (!props.auth.isAuthenticated) {
      props.history.push('/login');
      window.location.reload(); // <--- Page does not update on push, forcing reload
    }
    // eslint-disable-next-line
  }, []);

  //////////////////////////////////////////////////////////////////////////////////////////////
  const getMenuOptions = () => {
    if (!props.auth.isAuthenticated) return [];

    try {
      let user_data;
      let isLoginAs = false;
      let isLoginAsName;

      if (props.auth.loginAsUser) {
        user_data = JSON.parse(props.auth.loginAsUser);
        isLoginAs = true;
        isLoginAsName = user_data.userName;
        user_data = user_data.loginData;
      } else {
        user_data = JSON.parse(localStorage.getItem('user_data'));
      }

      const routes = sidebarRoutes(
        user_data.accessLevel,
        isLoginAs,
        isLoginAsName,
        props.features,
        user_data.user,
      );

      if (
        user_data.accessLevel === ACCESS_LEVEL.Employee ||
        user_data.accessLevel === ACCESS_LEVEL.CustomerAdmin
      ) {
        const bindings = props.bindings.filter(item => {
          if (!item.invitation) return false;
          return [
            INVITATION_TYPE.CustomerToUser,
            INVITATION_TYPE.CustomerToNonExistingUser,
          ].includes(item.invitation.type);
        });

        routes.push({
          to: '/companies',
          name: i18next.t(8123),
          exact: true,
          Icon: bindings.length > 0 ? CompanyApprovalIcon : CompanyIcon,
          svg: true,
          isBottom: true,
          origColor: true,
        });
      }

      return routes;
    } catch (error) {
      console.log(error);

      return [];
    }
  };

  const getActive = route => {
    const path = window.location.pathname;

    return route === path;
  };

  //////////////////////////////////////////////////////////////////////////////////////////////

  const renderNavItem = ({ to, name, Icon, svg, isLogoutAs }, index) => {
    // console.log({
    //   to,
    //   path: window.location.pathname,
    //   active: this.getActive(to),
    // });

    const getIcon = () => {
      if (svg) {
        return <ReactSVG className='sidebar-icon' src={Icon} wrapper='svg' />;
      }

      return <Icon className='sidebar-icon' size={25} />;
    };

    const targetId =
      'sidebar-menu-item_' + (isLogoutAs ? 'logoutas' : name) + index;

    const defaultActive =
      window.location.pathname === '/' && name === i18next.t(36);

    return (
      <MenuItem
        data-tip
        data-for={targetId}
        icon={getIcon()}
        key={index}
        active={getActive(to) || defaultActive}
        onClick={() => props.setRoute(to)}
      >
        {name}
        <NavLink to={to} />
        {!props.sideBarOpen && getTooltip(targetId, name)}
      </MenuItem>
    );
  };

  const renderSidebar = () => {
    const { toggle, sideBarOpen } = props;
    const navItems = getMenuOptions();

    const regNavItems = navItems.filter(ni => !ni.isBottom);
    const specialNavItems = navItems.filter(ni => ni.isBottom);

    return (
      <ProSidebar collapsed={!props.sideBarOpen}>
        <SidebarContent>
          <Menu>{regNavItems.map(renderNavItem)}</Menu>
        </SidebarContent>
        <SidebarFooter>
          <Menu>{specialNavItems.map(renderNavItem)}</Menu>
          <div className='langauge-selection'>
            <div className='langauge-selection-text'>{i18next.t(8351)}</div>
            <LangaugePicker />
          </div>
          <div className='app-version-wrapper'>
            <div className='app-version'>{process.env.REACT_APP_VERSION}</div>
            <MenuItem
              className='menu-toggler'
              icon={<HiChevronDoubleLeft className='sidebar-icon' />}
              onClick={() => toggle(!sideBarOpen)}
            />
          </div>
        </SidebarFooter>
      </ProSidebar>
    );
  };

  //////////////////////////////////////////////////////////////////////////////////////////////
  return <div className='custom-sidebar'>{renderSidebar()}</div>;
};

function mapStateToProps(state) {
  return {
    auth: state.auth,
    features: state.features.features,
    bindings: state.invitation.bindings,
    branding: state.branding.customerBranding,
  };
}

export default connect(mapStateToProps, { logout, loginOutAs })(Sidebar);
