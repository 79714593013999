import axios from 'axios';
import FileSaver from 'file-saver';
import { API_URL } from '../constants';
import {
  setFolders,
  setFiles,
  setCurrentFolder,
} from '../slices/documentsSlice';

/**
 * Gets all documents
 * @param {Number} customerId is the customerId used for simple UAC
 * @param {Number} parentId is used where in the hierachy it needs to request documents
 * @return {Promise} Promise
 */
export function getDocuments(customerId, parentId) {
  let url = API_URL + `/Document/get/${customerId}/${parentId}`;
  return dispatch => {
    return axios.get(url).then(res => {
      //console.log('getDocuments', res.data);
      let files = res.data.files;
      let folders = res.data.folders;

      dispatch(setFiles(files));
      dispatch(setFolders(folders));
      return res;
    });
  };
}

/**
 * Searches for documents
 * @param {Number} customerId is the customerId used for simple UAC
 * @return {Promise} Promise
 */
export function searchDocuments(customerId, data) {
  let url = API_URL + `/Document/search/${customerId}`;
  return dispatch => {
    return axios.post(url, data).then(res => {
      let files = res.data.files;
      let folders = res.data.folders;

      dispatch(setFiles(files));
      dispatch(setFolders(folders));
      return res;
    });
  };
}

/**
 * Gets folders
 * @param {Number} customerId is the customerId used for simple UAC
 * @param {Number} parentId is used where in the hierachy it needs to request folders
 * @return {Promise} Promise
 */
export function getFolders(customerId, parentId) {
  let url = API_URL + `/Document/getFolders/${customerId}/${parentId}`;
  return dispatch => {
    return axios.get(url).then(res => {
      let payload = res.data;
      dispatch(setFolders(payload));
      return res;
    });
  };
}

/**
 * Searches for folders
 * @param {Number} customerId is the customerId used for simple UAC
 * @return {Promise} Promise
 */
export function searchFolders(customerId, data) {
  let url = API_URL + `/Document/searchFolders/${customerId}`;
  return dispatch => {
    return axios.post(url, data).then(res => {
      let payload = res.data;
      dispatch(setFolders(payload));
      return res;
    });
  };
}

/**
 * Gets specific folder
 * @param {Number} customerId is the customerId used for simple UAC
 * @param {Number} id is the id of the specific folder requested
 * @return {Promise} Promise
 */
export function getFolder(customerId, id) {
  let url = API_URL + `/Document/getFolder/${customerId}/${id}`;
  return dispatch => {
    return axios.get(url).then(res => {
      let payload = res.data;
      dispatch(setCurrentFolder(payload));
      return res;
    });
  };
}

/**
 * Sets the folder redux
 * @param {data} data is the customerId used for simple UAC
 */
export function setFolderRedux(data) {
  return dispatch => {
    let payload = data;
    dispatch(setCurrentFolder(payload));
  };
}

/**
 * Gets files
 * @param {Number} customerId is the customersId used for simple UAC
 * @param {Number} parentId is used where in the hierachy it needs to request files
 * @return {Promise} Promise
 */
export function getFiles(customerId, parentId) {
  let url = API_URL + `/Document/getFiles/${customerId}/${parentId}`;
  return dispatch => {
    return axios.get(url).then(res => {
      let payload = res.data;
      dispatch(setFiles(payload));
      return res;
    });
  };
}

/**
 * Searches for files
 * @param {Number} customerId is the customerId used for simple UAC
 * @return {Promise} Promise
 */
export function searchFiles(customerId, data) {
  let url = API_URL + `/Document/searchFiles/${customerId}`;
  // console.log(data);
  return dispatch => {
    return axios.post(url, data).then(res => {
      let payload = res.data;
      dispatch(setFiles(payload));
      return res;
    });
  };
}

/**
 * Create folder
 * @param {Object} data a data object used for the creation
 * @return {Promise} Promise
 */
export function createFolder(data) {
  let url = API_URL + `/Document/createFolder`;
  return dispatch => {
    return axios.post(url, data).then(res => {
      return res;
    });
  };
}

/**
 * Update folder
 * @param {Object} data a data object used for the update
 * @return {Promise} Promise
 */
export function updateFolder(data) {
  let url = API_URL + `/Document/updateFolder/${data.id}`;
  return dispatch => {
    return axios.post(url, data).then(res => {
      return res;
    });
  };
}

/**
 * Update global folder visibility at customer level for end users
 * @param {Object} data a data object used for the update
 * @return {Promise} Promise
 */
export function updateGlobalFolderVisibilty(data) {
  let url = API_URL + `/Document/updateFolderVisibility`;
  return dispatch => {
    return axios.post(url, data).then(res => {
      return res;
    });
  };
}

/**
 * Update folder
 * @param {Object} data a data object used for the update
 * @return {Promise} Promise
 */
export function updateFile(data) {
  let url = API_URL + `/Document/updateFile/${data.id}`;
  return dispatch => {
    return axios.post(url, data).then(res => {
      return res;
    });
  };
}

/**
 * Update global file visibility at customer level for end users
 * @param {Object} data a data object used for the update
 * @return {Promise} Promise
 */
export function updateGlobalFileVisibilty(data) {
  let url = API_URL + `/Document/updateFileVisibility`;
  return dispatch => {
    return axios.post(url, data).then(res => {
      return res;
    });
  };
}

/**
 * Delete folder
 * @param {Number} customerId is the customersId used for simple UAC
 * @param {Number} folderId id of the folder to find what to delete
 * @return {Promise} Promise
 */
export function deleteFolder(customerId, folderId) {
  let url = API_URL + `/Document/deleteFolder/${customerId}/${folderId}`;
  return dispatch => {
    return axios.post(url).then(res => {
      return res;
    });
  };
}

/**
 * Delete file
 * @param {Number} customerId is the customersId used for simple UAC
 * @param {Number} fileId id of the folder to find what to delete
 * @return {Promise} Promise
 */
export function deleteFile(customerId, fileId) {
  let url = API_URL + `/Document/deleteFile/${customerId}/${fileId}`;
  return dispatch => {
    return axios.post(url).then(res => {
      return res;
    });
  };
}

/**
 * Upload document files and or folders
 * @param {Number} customerId is the customersId used for simple UAC
 * @param {Number} parentId used for placement of the folder/files
 * @return {Promise} Promise
 */
export function uploadDocumentFiles(files, customerId, parentId) {
  let url = API_URL + `/Document/uploadFiles/${customerId}/${parentId}`;
  return dispatch => {
    let data = new window.FormData();
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      data.append(file.name, file);
      //console.log(file.name, file);
    }

    return axios.post(url, data).then(res => {
      return res.data;
    });
  };
}

/**
 * Download document files
 * @param {Number} customerId is the customersId used for simple UAC
 * @param {Number} id id of the files
 * @return {Promise} Promise
 */
export function downloadFile(customerId, id) {
  let url = API_URL + `/Document/downloadFile/${customerId}/${id}`;
  return axios.get(url, { responseType: 'blob' }).then(res => {
    let fileName = '';
    const regex = /filename=["]?(.*\..*)["]?;/g;
    const headerContentDisposition = res.headers['content-disposition'];
    const fileNameGroups = regex.exec(headerContentDisposition);
    // console.log('download', fileNameGroups, headerContentDisposition);
    fileName = fileNameGroups[1].replace('"', '');

    FileSaver.saveAs(res.data, fileName);
  });
}

/**
 * Move File
 * @param {Number} customerId is the customersId used for simple UAC
 * @param {Number} fileId id of the file to find what to move
 * @param {Number} parentId parentId that the file will be moved to
 * @return {Promise} Promise
 */
export function moveFile(customerId, fileId, parentId) {
  let url = API_URL + `/Document/moveFile/${customerId}/${fileId}/${parentId}`;
  return dispatch => {
    return axios.post(url).then(res => {
      return res;
    });
  };
}

/**
 * Move Folder
 * @param {Number} customerId is the customersId used for simple UAC
 * @param {Number} folderId id of the folder to find what to move
 * @param {Number} parentId parentId that the folder will be moved to
 * @return {Promise} Promise
 */
export function moveFolder(customerId, folderId, parentId) {
  let url =
    API_URL + `/Document/moveFolder/${customerId}/${folderId}/${parentId}`;
  return dispatch => {
    return axios.post(url).then(res => {
      return res;
    });
  };
}

/**
 * Copy File
 * @param {Number} customerId is the customersId used for simple UAC
 * @param {Number} fileId id of the file to find what to copy
 * @param {Number} parentId parentId that the file will be copied to
 * @return {Promise} Promise
 */
export function copyFile(customerId, fileId, parentId) {
  let url = API_URL + `/Document/copyFile/${customerId}/${fileId}/${parentId}`;
  return dispatch => {
    return axios.post(url).then(res => {
      return res;
    });
  };
}

/**
 * Copy Folder
 * @param {Number} customerId is the customersId used for simple UAC
 * @param {Number} folderId id of the folder to find what to move
 * @param {Number} parentId parentId that the folder will be moved to
 * @return {Promise} Promise
 */
export function copyFolder(customerId, folderId, parentId) {
  let url =
    API_URL + `/Document/copyFolder/${customerId}/${folderId}/${parentId}`;
  return dispatch => {
    return axios.post(url).then(res => {
      return res;
    });
  };
}

/**
 * Get customer capacity
 * @param {Number} customerId is the customersId used for simple UAC
 * @return {Promise} Promise
 */
export function getCapacity(customerId) {
  let url = API_URL + `/Document/getCapacity/${customerId}`;
  return dispatch => {
    return axios.get(url).then(res => {
      return res;
    });
  };
}

/**
 * Get capacities of customerIds
 * @return {Promise} Promise
 */
export function getCapacities(customerIds) {
  let url = API_URL + `/Document/getCapacities`;
  return dispatch => {
    return axios.post(url, { CustomerIds: customerIds }).then(res => {
      return res;
    });
  };
}
