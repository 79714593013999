import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import i18next from '../../../i18n';
import { Col, FormGroup, Input, Label } from 'reactstrap';

// Actions

// Utils
import { validateControl } from '../../../utils/schemaBuilderUtils';

// Components
import InfoTip from '../../Custom/InfoTip';
import ReactSelect from '../../Custom/ReactSelect';
import LastEditor from '../../Custom/LastEditor';

const ControlItemTimeTableUsers = props => {
  const { control, disabled, employees, areControlsValid } = props;
  const {
    title,
    alternativeTitle,
    userOptions,
    timeTableUsers,
    infoTitle,
    infoText,
    showInfoIcon,
    required,
    placeholder,
    errorMessage,
    answeredByUserId,
    updatedDate,
  } = control;
  const [valid, setValid] = useState(true);
  const allowedTypes = userOptions?.map(x => x.type) ?? [];
  const selectedIds = timeTableUsers?.map(x => x.userId) ?? [];
  const labelSize = 3;
  const inputSize = 9;

  useEffect(() => {
    setValid(validateControl(control));
    // eslint-disable-next-line
  }, [timeTableUsers]);

  const onChange = (name, value) => {
    props.onChangeArray(name, value);
  };

  const onChangeSelection = (name, value) => {
    let val = !!value ? value : [];

    val = val.map(x => {
      const existing = timeTableUsers?.find(y => y.userId === x.value);
      return {
        ...(existing ? existing : { hours: 0 }),
        userId: x.value,
      };
    });

    // console.log(name, val, value);
    onChange(name, val);
  };

  const onChangeHours = (index, value) => {
    let updated = [...timeTableUsers];
    let val = updated[index].hours;

    if (
      (updated[index].hours === 0 && value > 0 && !isNaN(value)) ||
      (value > 0 && !isNaN(value))
    ) {
      val = parseFloat((value + '').replace(/^0+/, ''));
    } else if (value === 0) {
      val = 0;
    } else {
      val = 0;
    }

    updated[index] = { ...updated[index], hours: val };

    // console.log({ value, val });
    onChange('timeTableUsers', updated);
  };

  const renderInfo = () => {
    if (!showInfoIcon) return null;
    const targetId = 'tt_cid_' + control.id;

    return <InfoTip id={targetId} title={infoTitle} html={infoText} />;
  };

  const sharedProps = {
    placeholder: placeholder,
    closeMenuOnSelect: false,
    isDisabled: disabled,
    isMulti: true,
  };

  const renderDropdown = () => {
    let options = [];
    let value;

    if (allowedTypes?.length > 0) {
      options = employees
        .filter(x => allowedTypes.includes(x.employeeType))
        .map(x => {
          return {
            label: x.name,
            value: x.id,
          };
        });
    }

    if (timeTableUsers?.length > 0) {
      value = options.filter(x => selectedIds.includes(x.value));
    }

    return (
      <ReactSelect
        name='timeTableUsers'
        options={options}
        value={value}
        onChange={v => onChangeSelection('timeTableUsers', v)}
        {...sharedProps}
      />
    );
  };

  const renderUsers = () => {
    if (!timeTableUsers || timeTableUsers?.length === 0) return null;

    let totalHours = 0;
    const renderUserItem = (item, index) => {
      const user = employees.find(x => x.id === item.userId);
      totalHours += item.hours ? item.hours : 0;
      const itemHours = parseFloat((item.hours + '').replace(/^0+/, ''));

      return (
        <div className='item' key={index}>
          <div className='name'>{user?.name}</div>
          <Input
            type='number'
            step='.25'
            data-decimal='2'
            disabled={disabled}
            value={itemHours}
            onChange={e =>
              onChangeHours(
                index,
                Math.round(parseFloat(e.target.value) * 1e2) / 1e2,
              )
            }
          />
        </div>
      );
    };

    return (
      <div className='control-item-timetable-users'>
        {timeTableUsers.map(renderUserItem)}
        <div className='total'>
          <div className='title'>{i18next.t(7193)}:</div>
          <div className='sum'>{totalHours}</div>
        </div>
      </div>
    );
  };

  const renderTitle = () => {
    let tmp = title;
    if (alternativeTitle?.trim().length > 0) tmp = alternativeTitle;

    if (required) tmp += '*';

    return tmp;
  };

  const titleValid = () => {
    if (valid || areControlsValid || !required) return null;

    return (
      <div>
        <p style={{ color: 'red' }}>{errorMessage}</p>
      </div>
    );
  };

  return (
    <FormGroup row>
      <Label sm={labelSize}>
        <div className='label-wrap'>
          {renderTitle()}
          {renderInfo()}
        </div>
        {titleValid()}
        <LastEditor
          answeredByUserId={answeredByUserId}
          updatedDate={updatedDate}
        />
      </Label>
      <Col sm={inputSize}>
        {renderDropdown()}
        {renderUsers()}
      </Col>
    </FormGroup>
  );
};

function mapStateToProps({ auth, schemas, cases, employees }) {
  return {
    auth,
    schemas,
    cases,
    employees: employees.employees,
  };
}

export default connect(mapStateToProps, {})(ControlItemTimeTableUsers);
